import React from 'react';
import Logo from '../components/Logo'
import Banner from '../components/Banner'
import LoginScreen from '../components/LoginScreen'
import UserAccess from '../components/UserAccess'
import SavedVideos from '../components/SavedVideos'
import { connect } from "react-redux"
import { userActions } from "../actions/userActions"
import { saveItem } from "../actions/itemActions"
import Background from '../components/Background'
import { saveRememberSpotBatch, getAllRememberSpot } from "../actions/rememberSpotActions"
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router'
import LoginFlow from '../components/LoginFlow';
import { checkCorrectPlatform, isSmartTv } from '../helperfunctions/common'
import { PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_XBOX } from '../constants/common';
import { addUserCookie } from '../helperfunctions/storage';
import { getLogoFromLoginOrFeed } from '../helperfunctions';

const divStyle = {
    height: '100%'
}

const cookies = new Cookies();

class AccountLogin extends React.Component {
    constructor(props) {
        super(props);
        this.selectEmail = this.selectEmail.bind(this);
        this.selectBack = this.selectBack.bind(this);
        this.state = {
            emailSelected: false, 
            email: '', 
            password: '', 
            show_banners: false, 
            logo: {}
        };
    }

    componentWillMount(){
      this.setState({logo: this.props.section.logo || this.props.feeds.logo})
      var bannerId = this.props.section.bannerId
      if(bannerId !== undefined && this.props.feeds.bannerGroups !== undefined && this.props.feeds.bannerGroups[bannerId] !== undefined){
          this.setState({show_banners: true})
          this.setState({banners: this.props.feeds.bannerGroups[bannerId]})
      }
    }

    componentDidUpdate(prevProps, prevState){
      if (this.props.logged_in){
          // set cookies
        //   console.log("set cookie")
        addUserCookie(this.props.user);
      }
    }

    selectEmail(ev){
        if (isSmartTv()) {
            // It is handle in modal.js
            window.emailSelected = true;
        }
        this.setState({emailSelected: true})
        ev && ev.preventDefault()
    }
    selectBack(){
        this.setState({emailSelected: false})
    }

    // logo can be move inside user access to avoid js display none for logo
    render(){
        const firstSaveLogo = getLogoFromLoginOrFeed(this.props.feeds, "firstSave");
        const registerWallLogo = getLogoFromLoginOrFeed(this.props.feeds, "registrationWall");
        const headerLogo = this.props.isSavedSection ? firstSaveLogo : registerWallLogo;
        if (this.props.logged_in){
          if ((this.props.feeds.hasSavedSection || this.props.haveSavedMazIDFeed)){
                return (
                    <div style={divStyle}>
                        <Background background={this.props.background} />
                        { (this.state.logo && this.props.section.regularLayout !== "detail") && <Logo logo={this.state.logo} />}
                        <SavedVideos section={this.props.section} 
                            user={this.props.user} 
                            feeds={this.props.feeds}
                        />
                    </div>
                )}else{
                  return <Redirect to={"/apps/" + this.props.app_id+  "/settings"} />;
                }

        } else {
            return (
                <LoginFlow
                    emailSelected={this.state.emailSelected}
                    headerLogo={headerLogo}
                    divStyle={divStyle}
                    allProps={this.props}
                    selectBack={this.selectBack}
                    selectEmail={this.selectEmail}
                    runningOnMobile={this.props.runningOnMobile}
                />
            )
        }
    }
}


const mapStateToProps = (state) => ({
    user: state.user.user,
    logged_in: state.user.logged_in,
    remember: state.remember.remember,
    remember_fetched: state.remember.fetched_from_server,
    haveSavedMazIDFeed: state.items.haveSavedMazIDFeed
})

const mapDispatchToProps = {
    userActions: userActions,
    saveItem: saveItem,
    rememberSpotBatch: saveRememberSpotBatch,
    getAllRememberSpot: getAllRememberSpot,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLogin))
