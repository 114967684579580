import React from 'react';
import Content from '../components/Content';
import Swiper from 'react-id-swiper';
import Preview from '../components/Preview';
import { setPreviewVideo, setLockedContent } from "../actions/modalActions"
import { connect } from "react-redux";
import isAvailable from '../helperfunctions/is_available';
import { getFontStyleFromFeed } from "../helperfunctions";
import { isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';


const params = {
  direction: "vertical",
  spaceBetween: 20,
  slidesPerView: 'auto',
  freeMode: true,
  mousewheel: {
    invert: true,
    forceToAxis: true,
  },
  slidesOffsetAfter: 100,
  breakpoints: {
	  1280: {
		spaceBetween: 15
	  }
	}
	// keyboard: {
  //   enabled: true,
  //   onlyInViewport: false,
	// }
}

function checkSingleMenu(contents){
  var singleMenu = true
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" || contents[i].type === "live"){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

class SpotlightSection extends React.Component {
	constructor(props){
		super(props);
		this.swiper=null;
	}

	componentWillMount(props){
		this.getPreviewData();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.section.contents !== this.props.section.contents) {
			this.getPreviewData();
		}
	}
	
	getPreviewData() {
		if(!this.props.section.contents) return;
		var _content = {...this.props.section.contents[0]};
		if(_content.contents && _content.type === "menu" && _content.contents[0]){
			_content = {..._content.contents[0]};
		}
		_content["locked"] = this.props.section.contents[0].locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall)
		this.props.dispatch(setPreviewVideo(_content))
		this.props.dispatch(setLockedContent(_content.locked && !isAvailable(this.props.section.identifier, this.props.section.special,this.props.section.registerWall)))
	}

	componentDidMount() {
		const _self = this;
		this.swiper = document.querySelector('#spotlight-grid > .swiper-container') && document.querySelector('#spotlight-grid > .swiper-container').swiper;
		if (isSmartTv()) {
			document.addEventListener('keyup',this.handleRemoteClick);
		}
		if (this.swiper) {
			this.updateSwiperAfterSetTime(100);
		}
	}

	updateSwiperAfterSetTime = (timeInms) => {
		const _self = this;
		setTimeout(function() {
			_self.swiper.update();
		}, timeInms)
	}

	componentWillUnmount() {
		document.removeEventListener('keyup',this.handleRemoteClick);
	}

	handleRemoteClick = (e) => {
		let spotlightParent = document.querySelector('#spotlight-grid');
		if (!configuration.enableCtvVirtualSlides && (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40)) {
			if (spotlightParent && spotlightParent.contains(document.activeElement)) {
				const nearestContainer = document.activeElement.closest(".swiper-container");
				let currIndex;
				nearestContainer && nearestContainer.querySelectorAll(".swiper-wrapper > div").forEach((ele, index) => {
					if (ele.contains(document.activeElement)) {
						currIndex = index;
						nearestContainer.swiper && nearestContainer.swiper.slideTo(currIndex)
					}
				})
			}
		}
	}

	contentComponent = (content, i) => {
		return (<span key= {i}>
							<Content app_id={this.props.app_id}
								feeds={this.props.feeds}
								layout={this.props.layout}
								content={content}
								key={i}
								pathLocation={this.props.pathLocation}
								callbackParent = {this.props.callbackParent}
								sourceClass = {"image-video-"+this.props.section.contents[0].identifier}
								hover = {true}
								background={this.props.background}
							>
							</Content>
						</span>)

	}

	render(){
		const fontStyle = getFontStyleFromFeed(this.props.feeds);
		const contents = (this.props.section.contents && this.props.section.contents.map(this.contentComponent)) || []

		if (this.props.section.contents && checkSingleMenu(this.props.section.contents)){
			return(
			<div id="spotlight-section" className="h-screen">
				<Preview muted = {!this.props.section.previewAudio}
					previewType = {this.props.section.previewType}
					section = {this.props.section}
					previewInfo={this.props.section.previewInfo}
					fontStyle = {fontStyle}
				/>
				<div id="spotlight-grid">
					<Content
					app_id={this.props.app_id}
					feeds={this.props.feeds}
					content={this.props.section}
					callbackParent = {this.props.callbackParent}
					layout={this.props.layout}
					pathLocation={this.props.pathLocation}
	 				/>
	 			</div>
			</div>
			)
		}
		return (
			<div id="spotlight-section" style = {{height: "100%"}}>
				<Preview muted = {!this.props.section.previewAudio}
					previewType = {this.props.section.previewType}
					section = {this.props.section}
					previewInfo={this.props.section.previewInfo}
					fontStyle = {fontStyle}
				/>
				<div id="spotlight-grid">
					<Swiper {...params}>
						{contents}
					</Swiper>
				</div>
			</div>
			)
	}
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
})


export default connect(mapStateToProps)(SpotlightSection)
