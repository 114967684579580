import React from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import store from "../store"
import { getTvodApiHeaders } from '../constants/initializer';
import * as APIS from "../constants/api";
import { openModal, closeModal } from '../actions/modalActions';
import { canPlayVideoCheckFailed, resetTvodMaxStream } from '../actions/streamActions';
import { addTvodAPIRegionalisation, checkCorrectPlatform, checkLoginUIMetaData, formatItemDuration, getCurrentPlatform, getDateTimeForTvodInterstitial, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { backButtonObj, checkIfSectionLevelContent, getLogoFromLoginOrFeed, getTvodInterstitalMetaData } from '../helperfunctions';
import FocusLock from 'react-focus-lock';
import { logoutUserActions } from '../actions/userActions';
import configuration from '../constants/config';
import OverlayVideo from './OverlayVideo';
import FakeLive from './FakeLive';
import LiveSetup from './LiveSetup';
import ContentSectionVideo from './ContentSectionVideo';
import { DEEPLINK, EPG_THEME, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_WEB_PREVIEW, PLATFORM_XBOX } from '../constants/common';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import SaveIcon from "./SaveIcon"
import backMobile from "../images/icons/back-mobile.png";
import LoginFlow from '../components/LoginFlow';
import { startPurchase } from '../actions/subscriptionAction';
import { simulatedTvodPurchase } from '../actions/userActions';
import ErrorDisplay from './ErrorDisplay';
import PreviewVideo from './PreviewVideo';

const videojs = require('video.js');

const COUNTER = 10;
const PAGES = {
  main: "main",
  morePurchase: "morePurchase",
  moreWays: "moreWays",
  details: "details",
  login: "login",
  message: "message",
  ctvPolicy: "ctvPolicy"
}

class TvodWatchman extends React.Component {
  constructor(props) {
    super(props);
    this.getAllInterstitialData();
    const prevData = this.getPrevPurchaseData();
    this.state = {
      canPlayVideo: this.props.modal.head === EPG_THEME ? true : false, // For EPG start video
      streamCounterUpdate: null,
      emailSelected: false,
      saveAfterLogin: false,
      playAfterLogin: false,
      forceUpdateKey: new Date().getTime(),
      playAfterStreamFetched: false,
      pages: prevData ? prevData.prev_pages : [PAGES.main],
      detailsSub: prevData && prevData.subDetails ? prevData.subDetails : null
    }
    this.isRunningEPG = this.props.modal.head === EPG_THEME;
    this.primaryColor = props.feeds.primaryColor ? props.feeds.primaryColor.replace('#FF', '#') : "#000";
    this.secondaryColor = props.feeds.secondaryColor ? props.feeds.secondaryColor.replace('#FF', '#') : "#fff";
    this.streamCounter = 0;
    this.streamInterval = null;
    this.handleRemoteClick = this.handleRemoteClick.bind(this)
  }

  componentDidMount() {
    if(isSmartTv() && configuration.use_light_sliders) {
      this.sn_id = addRemoveAllNavigation({ selector: '.tvod-interstitial .focusable' });
      JsSpatialNavigation.focus(this.sn_id);
    }
    if (isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
    }
    if(this.isRunningEPG) {
      // video started so put counter here intead of componentDidUpdate
      this.streamInterval = setInterval(() => this.watchStreamCounter(), 1000);
    }
    this.handleCasesOnMount()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.logged_in !== this.props.logged_in && !this.props.logged_in) {
      this.addNewView(PAGES.login);
      this.setState({canPlayVideo: false})
    }
    if(prevProps.logged_in !== this.props.logged_in && this.props.logged_in) {
      // Logged in successfully
      if(this.state.playAfterLogin) {
        if(!this.props.fetchingStreams) {
          this.checkIfCanPlay();
        } else {
          this.setState({playAfterStreamFetched: true})
        }
      } else {
        this.goBack({afterLogin: true});
      }
    }
    if(this.state.playAfterStreamFetched && prevProps.fetchingStreams !== this.props.fetchingStreams && !this.props.fetchingStreams) {
      this.checkIfCanPlay();
      this.setState({playAfterStreamFetched: false})
    }
    if(prevProps.streamingAllowed !== this.props.streamingAllowed && !this.props.streamingAllowed) {
      if(this.streamInterval) {
        clearInterval(this.streamInterval);
        this.streamInterval = null;
      }
      window.history.replaceState("new video", {}, `${window.location.pathname}?mode=details`)
    }
    if(prevState.canPlayVideo !== this.state.canPlayVideo && this.state.canPlayVideo) {
      this.streamInterval = setInterval(() => this.watchStreamCounter(), 1000);
      if(this.ownLockedContent && this.ownLockedContent.endsAt) {
        // Any type of purchase : Check if user doesn't play beyond end date
        this.playerChecktimer = setInterval(() => {
          this.handlePlayerChecktimer(this.ownLockedContent.endsAt);
        }, 5000) // every 5 seconds
      }
    }
    if(prevProps.tvod_purchased && this.props.tvod_purchased && Object.keys(prevProps.tvod_purchased).length !== Object.keys(this.props.tvod_purchased).length) {
      this.getAllInterstitialData(true);
    }
  }

  componentWillUnmount() {
    if(this.streamInterval) {
      clearInterval(this.streamInterval);
      this.streamInterval = null;
    }
    if(isSmartTv() && configuration.use_light_sliders) {
      addRemoveAllNavigation({ id: this.sn_id });
    }
    if (isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);
    }
    if(this.playerChecktimer) {
      clearInterval(this.playerChecktimer);
      this.playerChecktimer = null;
    }
    if(this.eventWatchTimer) {
      clearInterval(this.eventWatchTimer);
      this.eventWatchTimer = null;
    }
  }

  handleCasesOnMount() {
    const startPlayer = (window.location.search === "?mode=watch");
    this.changeWindowUrl(startPlayer);
    if(this.ownLockedContent) {
      const isEvent = this.ownLockedContent.type === "events";
      let startTimer = isEvent && new Date(this.ownLockedContent.startsAt) > new Date();
      if(startTimer) {
        this.eventWatchTimer = setInterval(() => {this.handleEventWatchTimer()}, 1000); // every seconds
      }
      const isEventEnded = isEvent && new Date(this.ownLockedContent.endsAt) < new Date();
      //play if mode=watch 
      if(startPlayer && !startTimer && !isEventEnded) {
        this.checkIfCanPlay();
      }
    }
  }

  getPrevPurchaseData() {
    // in case of purchase fails/back button show exact page from where purchase triggered
    try {
      const query = queryString.parse(this.props.location.search);
      if(query.prev_purchase && this.lockedContentData) {
        const prev_pages = query.prev_purchase.split(",");
        let subDetails = null
        if(query.sub_id && this.lockedContentData.subscriptions && this.lockedContentData.subscriptions.length > 0) {
          //find sub to show details
          for(let i=0; i < this.lockedContentData.subscriptions.length; i++) {
            if(this.lockedContentData.subscriptions[i].identifier === query.sub_id) {
              subDetails = this.lockedContentData.subscriptions[i];
              break;
            }
          }
        }
        return { prev_pages, subDetails}
      }
      return null;
    } catch {
      return null;
    }
  }

  getAllInterstitialData(reset) {
    const { lockedContentData, ownLockedContent, moreWays } = getTvodInterstitalMetaData(this.props, this.getPlatform())
    this.moreWays = moreWays;
    this.ownLockedContent = ownLockedContent;
    this.lockedContentData = lockedContentData;
    if(reset) {
      this.setState({forceUpdateKey: new Date().getTime()})
      if(this.ownLockedContent) {
        this.addNewView(PAGES.main, true)
      }
    }
  }

  handlePlayerChecktimer(endDate) {
    if(new Date() > new Date(endDate)) {
      // get intertital data again
      this.getAllInterstitialData();
      if(!this.ownLockedContent || (
        this.ownLockedContent.type === "events" && new Date(this.ownLockedContent.endsAt) < new Date()
      )) {
        // Not free now or event has ended
        this.setState({canPlayVideo: false})
        if(this.playerChecktimer) {
          clearInterval(this.playerChecktimer);
          this.playerChecktimer = null;
        }
      }
    }
  }

  handleEventWatchTimer() {
    const start = this.ownLockedContent.startsAt;
    if(new Date() >= new Date(start)) {
      this.checkIfCanPlay();
      if(this.eventWatchTimer) {
        clearInterval(this.eventWatchTimer);
        this.eventWatchTimer = null;
      }
    }
  }

  validateNextVideo(newSection) {
    const props = {...this.props, section: newSection};
    const { lockedContentData, ownLockedContent, moreWays } = getTvodInterstitalMetaData(props, this.getPlatform())
    if(!ownLockedContent) {
      this.setState({canPlayVideo: false});
      this.dissmissAll();
    }
  }

  handleRemoteClick(e) {
    const back_key_codes = {
      '10009': true, // lg
      '461': true,    // samsung
      '8': checkCorrectPlatform([PLATFORM_VIZIO]) // vizio
    };
    if(checkCorrectPlatform([PLATFORM_XBOX])) {
      back_key_codes['198'] = true;
      back_key_codes['196'] = true;
    }
    if (back_key_codes[e.keyCode]) {
      this.goBack();
    }
  }

  getPlatform() {
    let platform = getCurrentPlatform();
    if(platform === PLATFORM_WEB) platform = "web";
    if(platform === PLATFORM_XBOX) platform = "microsoft";
    return platform;
  }

  getDurationText(duration) {
    if(duration == 31) return "Month";
    else if(duration == 93) return "3 Months";
    else if(duration == 186) return "6 Months";
    else if(duration == 366) return "Year";
    else return null
  }

  getFreeDurationText(duration) {
    if(duration == 7) return "1 week";
    else if(duration == 31 || duration == 30) return "1 month";
    else if(duration == 62 || duration == 60) return "2 month";
    else if(duration == 93 || duration == 90) return "3 month";
    else return null
  }

  showCTVPolicyModal = (type, url) => {
    this.setState({ctvPolicy: {url, type}});
    this.addNewView(PAGES.ctvPolicy);
  }

  isLiveFakeOnSectionLevel = () => {
    if ((this.props.section.type === 'live' || this.props.section.type === 'fake') && checkIfSectionLevelContent()) {
      return true;
    }
    return false;
  }

  updateEmailSelected() {
    this.setState({emailSelected: true})
  }

  watchStreamCounter() {
    this.streamCounter = this.streamCounter + 1;
    if(this.streamCounter === COUNTER) {
      this.setState({streamCounterUpdate: new Date().getTime()})
      this.streamCounter = 0;
    }
  }

  changeWindowUrl(startPlayer) {
    if(this.props.modal.deepLink === DEEPLINK || this.props.type === "fake" || this.props.type === "live") {
      if(!startPlayer || !this.ownLockedContent) {
        window.history.replaceState("new video", {}, `${window.location.pathname}?mode=details`)
      }
    } else {
      let url = null;
      const path = window.location.pathname.split("/");
      const {head, identifier} = this.props.modal;
      if(path[path.length - 1] == head ) {
        url = `${window.location.pathname}/${identifier}`
      } else {
        url = `${window.location.pathname}/${head}/${identifier}`
      }
      if(!startPlayer || url !== window.location.pathname) {
        window.history.pushState("new video", {}, `${url}?mode=details`)
      }
    }
  }

  dissmissAll() {
    this.props.resetTvodMaxStream();
    this.props.closeModal();

    if (this.props.modal.deepLink === DEEPLINK || this.props.type !== "video") {
      const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
      if(this.props.type === "video") {
        this.props.modal.callbackParent(this.props.section.identifier);
      }
      if(this.props.type === "fake" && isSmartTv() && document.getElementById("nav-bar")) {
        document.getElementById("nav-bar").style.display = '';
      }
    } else {
      window.history.replaceState("view", {}, this.props.modal.pathname)
    }
  }

  checkIfCanPlay() {
    if(!this.props.logged_in) {
      this.addNewView(PAGES.login);
      this.setState({playAfterLogin: true})
      return;
    }
    this.setState({canPlayVideoChecking: true})
    const { section, allStreamObject } = this.props;
    const progress = (this.props.type === "live") ? 100 : ((allStreamObject[section.cid] && allStreamObject[section.cid].progress) || 0)
    const data = {"cid": section.cid, "progress": progress}
    axios.post(APIS.TVOD_STREAMS_API, addTvodAPIRegionalisation(data, null, this.props.policyData), getTvodApiHeaders())
    .then(result => {
      this.setState({canPlayVideo: true, canPlayVideoChecking: false})
    }).catch(err => {
      const code = err.response && err.response.status;
      if(code == 401) {
        this.props.logoutUserActions(configuration.app_id)
      } else if(code === 403) {
        const max = err.response.data && err.response.data.max;
        this.props.canPlayVideoCheckFailed(max)
      }
      this.setState({canPlayVideo: false, canPlayVideoChecking: false})
    })
  }

  passToTvodWatchman() {
    this.addNewView(PAGES.login);
    this.setState({saveAfterLogin: true})
  }

  addNewView(view, reset) {
    let pages = [...this.state.pages];
    if(reset) {
      pages = [view];
    } else {
      pages.push(view);
    }
    this.setState({pages: pages})
  }

  goBack(extra) {
    if(this.state.pages.length === 1) { // last page
      if(!this.isLiveFakeOnSectionLevel()) { // NOT for section level content
        this.dissmissAll();
      }
    } else {
      let pages = [...this.state.pages];
      pages.pop();
      this.setState({pages: pages, emailSelected: false, playAfterLogin: false, ctvPolicy: null})
      if(!extra || !extra.afterLogin) {
        this.setState({saveAfterLogin: false})
      }
    }
  }

  manageClick(key, tier, selectedSub) {
    if(key === "subscriptions" && !selectedSub) {
      this.setState({detailsSub: tier})
      this.addNewView(PAGES.details);
    } else {
      if(!this.props.logged_in) {
        this.addNewView(PAGES.login);
        return;
      }
      // do purchase
      const platform = this.getPlatform()
      let detail;
      if(key === "subscriptions") {
        detail = {...tier, ...selectedSub}
      } else {
        detail = tier
      }
      if(checkCorrectPlatform([PLATFORM_HTML_SIMULATOR, PLATFORM_WEB_PREVIEW])) {
        // simulated purchase
        let nDate = new Date();
        this.props.simulatedTvodPurchase({
          bundleToken: detail.bundleToken,
          endsAt: new Date(nDate.setDate(nDate.getDate() + 2)), // 48 hours
          identifier: detail.identifier,
          isActive: true,
          platform: (platform === "microsoft") ? PLATFORM_XBOX : platform,
          productId: detail.iap[platform].identifier,
          purchaseDate: new Date(),
          startsAt: new Date(),
          type: detail.type
        })
        this.addNewView(PAGES.message);
      } else {
        // real purchase
        this.props.startPurchase({
          tier_id: detail.identifier,
          price: detail.iap[platform].price,
          product_id: detail.iap[platform].identifier,
          title: detail.title,
          type: detail.type,
          bundleToken: detail.bundleToken,
          cid: this.props.section.cid,
          platform: (platform === "microsoft") ? PLATFORM_XBOX : platform,
          itemDetails: detail
        });
        this.props.openModal({ type: "payment", goBack: isSmartTv() ? "tvodInterstitial" : false, prev_purchase: this.state.pages.join(","), sub_id: (this.state.detailsSub && this.state.detailsSub.identifier) || null});
      }
    }
  }

  getPurchaseTitle(key) {
    switch(key) {
      case "subscriptions": return "Subscribe";
      case "rentals": return "Rent";
      case "events": return "Attend";
      case "purchases": return "Buy";
      default: return "";
    }
  }

  renderPreview(background, colorStyle) {
    if(background.imageUrl) {
      return (
        <img
          style={{visibility:"hidden", ...colorStyle}}
          onLoad={(e) => {e.target.style.visibility='visible'}}
          alt="" 
          src={background.imageUrl}
        />
      )
    } else {
      const gradientStyle = (background.gradient && background.gradient.topColor && background.gradient.bottomColor) ? {backgroundImage:  `linear-gradient(${background.gradient.topColor}, ${background.gradient.bottomColor})`} : {}
      return (
        <PreviewVideo 
          video_content={{
            contentUrl: background.videoUrl,
            muted: false,
          }}
          gradientStyle={gradientStyle}
        />
      )
    }
  }

  renderBackground(lastPage) {
    if(lastPage !== PAGES.main && this.props.runningOnMobile) return null;
    
    const { background } = this.props.section;
    const colorStyle = background && background.color ? {backgroundColor: background.color} : {}
    return (
      <div className="base" style={colorStyle}>
        {background && (background.imageUrl || background.videoUrl)
          ? this.renderPreview(background, colorStyle)
          : <div className="no-background" style={colorStyle}></div>
        }
      </div>
    )
  }

  renderTitles() {
    const {section} = this.props;
    return (
      <div className="titles">
        <span className="title">{section.title}</span>
        {section.subtitle && <span className="subtitle">{section.subtitle}</span>}
        {this.ownLockedContent && this.ownLockedContent.badge && <span className="badge">{this.ownLockedContent.badge}</span>}
      </div>
    )
  }

  renderMetaData() {
    const { duration, rating, copyright, role, genre } = this.props.section;
    return (
      <div className="meta-data">
        <div>
          {copyright && <span>{copyright}</span>}
          {duration && <span>{formatItemDuration(duration)}</span>}
          {rating && <span>{rating}</span>}
          {genre && <span>{genre}</span>}
        </div>
        {role && role.actor && <p>{`Starring: ${role.actor}`}</p>}
        {role && role.director && <p>{`${role.director.split(",").length > 1 ? "Directors" : "Director"}: ${role.director}`}</p>}
      </div>
    )
  }

  renderSaveIcon() {
    if(this.props.runningOnMobile) return null;
    const {section, feeds} = this.props;
    const secondary = feeds.secondaryColor || "#FFFFFF";
    return (
      <div className="save-icon">
        <SaveIcon 
          no_color={true} 
          locked={section.locked} 
          store={store} 
          content={section} 
          type={"content"} 
          secondary={secondary} 
          tvodPrimaryColor={this.primaryColor}
          feeds={feeds} 
          layout={'detail'}
          index={1}
          total={1}
          passToTvodWatchman={() => this.passToTvodWatchman()}
          saveAfterLogin={this.state.saveAfterLogin}
        />
      </div>
    )
  }

  renderButtons() {
    const {section, feeds} = this.props;
    let platform = this.getPlatform();
    const buttonStyle = {backgroundColor: this.primaryColor};
    if(this.lockedContentData) {
      const {rentals, subscriptions, events, purchases} = this.lockedContentData;
      let elements = null;
      let moreOption = Object.keys(this.lockedContentData).length > 1
      if(subscriptions && subscriptions.length > 0) {
        const defaultSubs = subscriptions.filter(sub => {
          return sub.isDefault;
        })
        const defaultSub = (defaultSubs && defaultSubs[0]) || subscriptions[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("subscriptions", defaultSub)} style={buttonStyle}>
            <span>Watch with {defaultSub.title}</span>
          </span>
        )
        moreOption = moreOption || subscriptions.length > 1;
      } else if(purchases && purchases.length > 0) {
        const defaultP = purchases[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("purchases", defaultP)} style={buttonStyle}>
            <span>{`${defaultP.title} $${defaultP.iap[platform].price}`}</span>
          </span>
        )
        moreOption = moreOption || purchases.length > 1;
      } else if(rentals && rentals.length > 0) {
        const defaultR = rentals[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("rentals", defaultR)} style={buttonStyle}>
            <span>{`${defaultR.title} $${defaultR.iap[platform].price}`}</span>
            <sub>Expires in {defaultR.start && Math.floor(defaultR.start / 3600)} hrs, or {defaultR.finish && Math.floor(defaultR.finish / 3600)} hrs after you start watching</sub>
          </span>
        )
        moreOption = moreOption || rentals.length > 1;
      } else if(events && events.length > 0) {
        const defaultE = events[0];
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.manageClick("events", defaultE)} style={buttonStyle}>
            <span>{`${defaultE.title} $${defaultE.iap[platform].price}`}</span>
            <sub>Event starts {defaultE.startsAt && getDateTimeForTvodInterstitial(defaultE.startsAt)}</sub>
          </span>
        )
        moreOption = moreOption || events.length > 1;
      } else {
        elements = (
          <span className="focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.moreWays)} style={buttonStyle}>
            <span>How to watch</span>
          </span>
        )
        moreOption = false;
      }
      return (
        <div className="item-play">
          {elements}
          {moreOption &&
            <span className="focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.morePurchase)} style={buttonStyle}>
              <span>More Purchase Options </span>
          </span>
          }
          {this.renderSaveIcon()}
        </div>
      )
    } else {
      const isEvent = this.ownLockedContent && this.ownLockedContent.type === "events";
      let msg = null;
      if(isEvent) {
        if(new Date(this.ownLockedContent.startsAt) > new Date()) {
          msg = `The Event starts  ${getDateTimeForTvodInterstitial(this.ownLockedContent.startsAt)}`
        } else if(new Date(this.ownLockedContent.endsAt) < new Date()) {
          msg = `The Event has ended`;
        }
      }
      return (
        <div className="item-play free">
          {msg 
            ? (
              <span className="focusable" tabIndex="-1">
                <span>{msg}</span>
              </span>
            )
            : (
              <span className="focusable" tabIndex="-1" onClick={() => this.checkIfCanPlay()} style={buttonStyle}>
                <span>Play</span><span className="arrow-right"></span>
              </span>
            )
          }
            
          {!isEvent && this.renderSaveIcon()}
        </div>
      )
    }
  }

  renderBackButton(lastPage) {
    let style = {}
    if(isSmartTv()) {
      style.display = 'none';
    }
    if(this.isLiveFakeOnSectionLevel() && lastPage === PAGES.main) {
      return null;
    }

    return (
    <span className={"icon-hollow_arrow backBtnSpan " + (this.props.runningOnMobile ? "backBtnSpan-mobile" : "")}>
      <a id="modal_back_btn" href="" onClick={(e) => {
          e.preventDefault();
          this.goBack();
        }} 
        className={(this.props.runningOnMobile ? "backBtn-mobile" : "backBtn")}
        style={{zIndex: 100, ...style}}>
          {this.props.runningOnMobile && <img src={backMobile} alt="Back"/>}
      </a>
    </span>)
  }

  renderTier(key, tier) {
    const title = this.getPurchaseTitle(key);
    let platform = this.getPlatform();
    return (
      <div>
        <span>{title}</span>
        {tier.map(tierElement => {
          const price = (key === "subscriptions") ? tierElement.metadata[0].iap[platform].price : tierElement.iap[platform].price;
          return (
            <div key={tierElement.title} 
              className="purchase-button focusable" 
              tabIndex="-1" 
              onClick={() => this.manageClick(key, tierElement)}
              style={{backgroundColor: this.primaryColor}}
            >
              <span>{`${tierElement.title} $${price}`}</span>
              {key === "rentals" &&
                <sub>Expires in {tierElement.start && Math.floor(tierElement.start / 3600)} hrs, or {tierElement.finish && Math.floor(tierElement.finish / 3600)} hrs after you start watching</sub>
              }
              {key === "events" &&
                <sub>Event starts {tierElement.startsAt && getDateTimeForTvodInterstitial(tierElement.startsAt)}</sub>
              }
            </div>
          )
        })}
      </div>
    )
  }

  renderMorePurchases() {
    if(!this.lockedContentData) return null;
    const {rentals, subscriptions, events, purchases} = this.lockedContentData;
    return (
      <div className="more-sub-wrapper">
        <div className="more-purchases">
          {subscriptions && this.renderTier("subscriptions", subscriptions)}
          {purchases && this.renderTier("purchases", purchases)}
          {rentals && this.renderTier("rentals", rentals)}
          {events && this.renderTier("events", events)}
          {this.moreWays.showMoreWays && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.moreWays)} style={{backgroundColor: this.primaryColor}}>
            <span>More Ways to Watch</span>
          </div>}
          {!this.props.logged_in && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.login)} style={{backgroundColor: this.primaryColor}}>
            <span>Sign In</span>
          </div>}
        </div>
        { (purchases || rentals || events) &&
          <div className="policy-wrapper">
            {this.diclaimerDiv()}
            {this.termsAndPrivacyPolicyDiv()}
          </div>
        }
      </div>
    )
  }

  diclaimerDiv = () => {
    let configSubText = configuration.subscription_terms_text;
    let subText = "Payment will be charged to the Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period for the same price as the first period. Any unused portion of a free period, if offered, will be forfeited when the user purchases a subscription. You may manage your subscriptions and auto-renew may be turned off by going to Subscription in Settings after purchase and cancel the subscription. No cancellation of the current subscription is allowed during an active subscription period. “Free Trial” and “FREE” represent a period of free access that will be automatically followed by an auto-renewing subscription at the standard subscription price unless auto-renew is turned off at least 24 hours before the end of the current period.";
    return (
      <div className="disclaimer">
        {configSubText? configSubText: subText}
      </div>
    )
  }

  termsAndPrivacyPolicyDiv = () => {
    if (isSmartTv()) {
      return (
        <div className="termsOfUse">
          <ul className="mob_info">
            <li><a onClick={() => this.showCTVPolicyModal('Terms of Use',this.props.feeds.loginUIMetadata.urls.terms)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>Terms of Use</a></li>
            <li className="termsVertical">|</li>
            <li><a onClick={() => this.showCTVPolicyModal('Privacy Policy',this.props.feeds.loginUIMetadata.urls.policy)} style={{ padding: '0 10px' }} className="text-white focusable" tabIndex={getTabIndex()}>Privacy Policy</a></li></ul>
        </div>
      )
    } else {
      return (
        <div className="termsOfUse">
            <ul className="mob_info">
            <li><a href={this.props.feeds.loginUIMetadata.urls.terms} target = "_blank" className="text-white" >Terms of Use</a></li>
            <li className="termsVertical">|</li>
            <li><a href={this.props.feeds.loginUIMetadata.urls.policy} target = "_blank" className="text-white">Privacy Policy</a></li></ul>
        </div>
      )
    }
  }

  renderCTVPolicy() {
    return (
      <div className="ctv-poilcy">
        <h3>{ this.state.ctvPolicy.type }</h3>
        <p>
          &nbsp; To read, please visit: &nbsp; {this.state.ctvPolicy.url }
        </p>
      </div>
    )
  }

  renderSubDetails() {
    if(!this.state.detailsSub) return null;
    const detailsSub = this.state.detailsSub;
    let platform = this.getPlatform();
    return (
      <div className="sub-details">
        <span>{detailsSub.title}</span>
        {detailsSub.metadata.map(meta => {
          const freeText = this.getFreeDurationText(meta.freeTrial);
          return (
            <div key={meta.duration} 
              className="purchase-button focusable" 
              tabIndex="-1" 
              onClick={() => this.manageClick("subscriptions", detailsSub, meta)}
              style={{backgroundColor: this.primaryColor}}
            >
              <span>{`$${meta.iap[platform].price} / ${this.getDurationText(meta.duration)}`}</span>
              {freeText && <sub>Start with a {freeText} free trial</sub>}
            </div>
          )
        })}
        {!this.props.logged_in && <div className="purchase-button extra focusable" tabIndex="-1" onClick={() => this.addNewView(PAGES.login)} style={{backgroundColor: this.primaryColor}}>
          <span>Already A Subscriber? Sign In</span>
        </div>}
      </div>
    )
  }

  renderMoreWays() {
    return (
      <div className="more-ways">
        <span>{this.props.logged_in ? this.moreWays.signedInMoreWays : this.moreWays.signedOutMoreWays}</span>
      </div>
    )
  }

  renderDescription() {
    const {section} = this.props;
    const background = section.background || {};
    const gradientStyle = (!background.videoUrl && background.gradient && background.gradient.topColor && background.gradient.bottomColor) ? {backgroundImage:  `linear-gradient(${background.gradient.topColor}, ${background.gradient.bottomColor})`} : {}
    return (
      <div className="description" style={gradientStyle}>
        {
          this.props.runningOnMobile 
          ? <div className="description-wrapper">
              {this.renderTitles()}
              {this.renderButtons()}
              <div className="tvod-summary">
                <span>{section.summary}</span>
              </div>
              {this.renderMetaData()}
            </div>
          : <div className="description-wrapper">
              {this.renderTitles()}
              {this.renderMetaData()}
              <div className="tvod-summary">
                <span>{section.summary}</span>
              </div>
              {this.renderButtons()}
            </div>
        }
      </div>
    )
  }

  renderLogin() {
    const headerLogo = getLogoFromLoginOrFeed(this.props.feeds , "registrationWall");
    const allProps = {
      feeds: checkLoginUIMetaData(this.props.feeds),
      app_id: configuration.app_id,
      background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
      screen: this.props.modal.screen,
      titleMsg: this.state.saveAfterLogin ? null : "PLEASE LOGIN TO CONTINUE WATCHING",
      directLogin: this.props.modal.directLogin
    }
    return (
      <div className="scroll-auto-h-100 login-page">
        <LoginFlow
          emailSelected={this.state.emailSelected}
          headerLogo={headerLogo}
          selectEmail={() => this.updateEmailSelected()}
          selectBack={this.goBack}
          allProps={allProps}
          runningOnMobile={this.props.runningOnMobile}
          pushToTvodWatchman={(type, url) => this.showCTVPolicyModal(type, url)}
        />
      </div>
    )
  }

  renderSimulatedMessage(){
    return (
      <ErrorDisplay 
        dismiss={() => {
          this.getAllInterstitialData();
          this.addNewView(PAGES.main, true)//reset
        }} 
        errorMsg={"This content will be unlocked."} 
        errorHeading={"This is a simulated purchase."} 
    />
    )
  }

  renderBlockUI() {
    return (
      <FocusLock>
        <div className="stream-block-modal">
          <p className="stream-modal-title">Streaming Blocked</p>
          <span>You are already streaming on {this.props.maxStreamCount} devices.</span>
          <span>Please stop streaming on a device and try again.</span>
          {this.props.runningOnMobile
            ? <div>
                <div className="line"></div>
                <div className="stream-modal-dismiss focusable" tabIndex={getTabIndex()} onClick={() => this.dissmissAll()}>OK</div>
            </div>
            : <button onClick={() => this.dissmissAll()} className="stream-modal-dismiss focusable " tabIndex={getTabIndex()} autoFocus={true}>OK</button>
          }
        </div>
      </FocusLock>
    )
  }

  renderPlayer() {
    window.history.replaceState("new video", {}, `${window.location.pathname}?mode=watch`)
    if (this.props.type === "video") {
      return (
        <OverlayVideo streamCounterUpdate={this.state.streamCounterUpdate}  modal={this.props.modal} section={this.props.section} app_id={configuration.app_id}
          all_contents={this.props.modal.all_contents} feeds={this.props.feeds} parent_id= {this.props.modal.head} runningOnMobile={this.props.runningOnMobile} validateNextVideo={(newSection) => this.validateNextVideo(newSection)}/>
      )
    } else if (this.props.type === "fake") {
      return (
        <FakeLive streamCounterUpdate={this.state.streamCounterUpdate} modal={this.props.modal} key={this.props.section.identifier} feeds={this.props.feeds} section={this.props.section} app_id={configuration.app_id} runningOnMobile={this.props.runningOnMobile}/>
      )
    } else if (this.props.type === "live") {
      return (
        <LiveSetup streamCounterUpdate={this.state.streamCounterUpdate} modal={this.props.modal} key={this.props.section.identifier} feeds={this.props.feeds} section={this.props.section} runningOnMobile={this.props.runningOnMobile}/>
      )
    } else if (this.props.type === "svideo") {
      return (
        <ContentSectionVideo streamCounterUpdate={this.state.streamCounterUpdate} feeds={this.props.feeds} section={this.props.section} runningOnMobile={this.props.runningOnMobile}/>
      )
    }
  }

  render() {
    const lastPage = this.state.pages.length > 0 ? this.state.pages[this.state.pages.length - 1] : PAGES.main;
    if(this.state.canPlayVideo && this.props.streamingAllowed) {
      return this.renderPlayer()
    } else {
      let classes = "tvod-interstitial";
      if(isSmartTv() && document.getElementById('nav-with-modal-id')) {
        if(document.getElementById('nav-with-modal-id').classList && document.getElementById('nav-with-modal-id').classList.contains("section-content")) {
          classes = "tvod-interstitial section-interstitial";
        }
      }
      return (
        <div className={classes} key={this.state.forceUpdateKey}>
          {this.renderBackground(lastPage)}
          {this.renderBackButton(lastPage)}
          {lastPage === PAGES.main && this.renderDescription()}
          {lastPage === PAGES.morePurchase && this.renderMorePurchases()}
          {lastPage === PAGES.moreWays && this.renderMoreWays()}
          {lastPage === PAGES.details &&
            <div className="more-sub-wrapper">
              {this.renderSubDetails()}
              <div className="policy-wrapper">
                {this.diclaimerDiv()}
                {this.termsAndPrivacyPolicyDiv()}
              </div>
            </div>
          }
          {lastPage === PAGES.login && this.renderLogin()}
          {lastPage === PAGES.message && this.renderSimulatedMessage()}
          {lastPage === PAGES.ctvPolicy && this.renderCTVPolicy()}
          {!this.props.streamingAllowed && this.renderBlockUI()}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  logged_in: state.user.logged_in,
  streamingAllowed: state.streams.streamingAllowed,
  allStreamObject: state.streams.allStreamObject,
  maxStreamCount: state.streams.maxStreamCount,
  fetchingStreams: state.streams.fetchingStreams,
  shouldPlayAfterPurchase: state.subscription.shouldPlayAfterPurchase,
  tvod_purchased: state.user.tvod_purchased,
  policyData: state.feeds.policyData
})
  
const mapDispatchToProps = {
  openModal: openModal,
  resetTvodMaxStream: resetTvodMaxStream,
  logoutUserActions: logoutUserActions,
  canPlayVideoCheckFailed: canPlayVideoCheckFailed,
  closeModal: closeModal,
  startPurchase: startPurchase,
  simulatedTvodPurchase: simulatedTvodPurchase
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TvodWatchman));