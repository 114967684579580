import React from 'react';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { GRID_THEME, SERIES_THEME } from '../constants/common';
import config from '../constants/config';
import { getFontStyleFromFeed, getSwiperParamsByTheme } from "../helperfunctions";
import { appendVirtualSwiperParams, checkIfEmptyMazIdFeedsInContents, getTabIndex, focusVideoItemWhichWerePlayed, isSmartTv, setLeftStyleToSlideItems, tvFocus } from '../helperfunctions/common';
import { convertSwiperParamsToSlick, makeContentFocusableSection, removeContentFromFocusableSection } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';

class GridContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      },
    }
    this.swiper = null;
    this.swiperParams = null;
    this.slick_params = null;
    this.is_in_focus = false;
    this.generateSwiperParam();
  }

  componentDidMount() {
    const swiperCont = document.querySelector(`#standard-grid.content-${this.props.content.identifier} .swiper-container`);
    this.swiper = swiperCont && swiperCont.swiper;

    if(isSmartTv() && config.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier
      }).then(val => {
        this.sn_id = val;
      }).catch(err => console.log("error",err))
    }
    console.log("slider ref",this.sliders_ref);
  }

  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // if (this.swiper) {this.swiper.slideTo(this.props.modal_progress.index)};
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item);
    }
    if (!isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)) {
      setLeftStyleToSlideItems(this.props.content.identifier, this.state.virtualData.offset);
    }
  }

  onRenderExternal = (virtualData) => {
    this.setState({
      virtualData
    },() => {
      tvFocus(this.props.layout, this.props.content.identifier);
    })
  }

  generateSwiperParam = () => {
    const _self = this;
    let params = getSwiperParamsByTheme(_self, this.props.content, GRID_THEME);
    if (!isSmartTv()) {
      this.swiperParams = appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal)
    } else {
      if (config.enableCtvVirtualSlides) {
        this.swiperParams = {
          ...appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal, {
            addSlidesAfter: 1,
            addSlidesBefore: 1
          }),
        };
      } else {
        this.swiperParams = params;
      }
    }
  }

  nextSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slideNext();
  }

  prevSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slidePrev();
  }

  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  render() {
    const _self = this;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    // let params = getSwiperParamsByTheme(_self, this.props.content, GRID_THEME);
    // _self.generateSwiperParam()
    if (this.props.content && this.props.content.thumbOrientation === "portrait") {
      if(this.props.nosaveicon){
        var gridClassName = "nothumbBox slider-box grid-view portrait-view content-" + this.props.content.identifier
      } else {
        var gridClassName = "slider-box grid-view portrait-view content-" + this.props.content.identifier
      }
    } else {
      if(this.props.nosaveicon){
        var gridClassName = "nothumbBox slider-box grid-view content-" + this.props.content.identifier
      } else {
        var gridClassName = "slider-box grid-view content-" + this.props.content.identifier
      }
    }

    if(isSmartTv() && config.use_light_sliders) {
      this.slick_params = convertSwiperParamsToSlick(this.swiperParams, this);
    }

    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    return (
      <div id="standard-grid" className={gridClassName}>
        {(this.props.layout !== SERIES_THEME && !this.props.showingSavedSection && this.props.title !== "saved_items" && !this.props.content.locked) && <h2 id={`content-head-${this.props.content.identifier}`} style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>}
        {this.props.layout !== SERIES_THEME && this.props.content.locked && <Cta layout={"standard"}time={Date.now()} content={this.props.content} feeds={this.props.feeds} />}
        {
          isSmartTv() && config.enableCtvVirtualSlides && (
          <>
            <div data-sn-right="" className={`swiper-button-next t${this.props.content.identifier}-nextEl focusable`} tabIndex={getTabIndex()} onClick={this.nextSlide}></div>
            <div data-sn-left="" className={`swiper-button-prev t${this.props.content.identifier}-prevEl focusable`} tabIndex={getTabIndex()} onClick={this.prevSlide}></div>
          </>
          )
        }
        {
          // isSmartTv() && config.use_light_sliders ?
          // <Slider {...this.slick_params}>
          // {
          //   !isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)
          //   ? (this.state.virtualData.slides)
          // : (this.props.contents)
          // }
          // </Slider> 
          // :
          // <Swiper {...this.swiperParams}>
          // {
          //   !isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)
          //   ? (this.state.virtualData.slides)
          // : (this.props.contents)
          // }
          // </Swiper>        
          <CustomSlider
            params = {this.slick_params || this.swiperParams}
            content_identifier = {this.props.content.identifier}
          >
            {
              !isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)
              ? (this.state.virtualData.slides)
            : (this.props.contents)
            }
          </CustomSlider>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(GridContent);
