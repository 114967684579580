import { PLATFORM_SAMSUNG, PLATFORM_XBOX, PLATFORM_LG } from "../constants/common";
import { checkCorrectPlatform } from "./common";
import Cookies from 'universal-cookie';


export function setLocalStorage(key, value) {
    const stringVal = JSON.stringify(value);
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.saveData(key, stringVal, null, (err) => {err && console.log(err)});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            localSettings.values[key] = stringVal;
        } else {
            localStorage.setItem(key, stringVal);
        }
    } catch (e) {
        console.log('unable to save data in local.')
    }
}

export function getLocalStorage(key) {
    let val;
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            val = window.tizen.keymanager.getData({ 'name': key });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            val = localSettings.values[key];
        } else {
            val = localStorage.getItem(key);
        }
    } catch (e) {
        console.log('unable to get data from local.')
    }
    //val will undefined in case of tizen not found the key
    if (val === undefined) val = null;
    return val && JSON.parse(val);
}

export function removeItemLocalStorage(key) {
    try {
        if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            window.tizen.keymanager.removeData({ 'name': key });
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            localSettings.values.remove(key);
        } else {
            localStorage.removeItem(key);
        }
    } catch (e) {
        console.log('unable to remove data from local.')
    }
}

export function addUserCookie(value) {
    let cookies;
    try {
      if(checkCorrectPlatform([PLATFORM_LG])) {
        localStorage.setItem('AppUser', JSON.stringify(value));
      } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
        window.tizen.keymanager.saveData('AppUser', JSON.stringify(value), null, () => {});
      } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
        let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
        localSettings.values['AppUser'] = JSON.stringify(value);
      } else {
        cookies = new Cookies();
        cookies.set('AppUser', value, { path: '/' });  
      }
    } catch(err) {
      cookies = null;
    }
}

export function getUserCookie() {
    let user_cookie;
    try {
        if(checkCorrectPlatform([PLATFORM_LG])) {
            user_cookie = localStorage.getItem('AppUser');
            if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);
        } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
            user_cookie = window.tizen.keymanager.getData({ 'name': 'AppUser' });
            if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);    
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
            let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
            user_cookie = localSettings.values['AppUser'];
            if(typeof user_cookie === 'string') user_cookie = JSON.parse(user_cookie);  
        } else {
            const cookies = new Cookies();
            user_cookie = cookies.get('AppUser');
        }
    } catch(err) {
        user_cookie = null;
    }
    return user_cookie;
}

export function removeUserCookie() {
    try {
        if(checkCorrectPlatform([PLATFORM_LG])) {
          localStorage.removeItem('AppUser');
        } else if(checkCorrectPlatform([PLATFORM_SAMSUNG]) && window.tizen) {
          window.tizen.keymanager.removeData({'name': 'AppUser'});
        } else if(checkCorrectPlatform([PLATFORM_XBOX]) && window.Windows != undefined) {
          let localSettings = window.Windows.Storage.ApplicationData.current.localSettings;
          localSettings.values.remove('AppUser');
        } else {
          const cookies = new Cookies();
          cookies.remove('AppUser', { path: '/' });
        }
      } catch(err) {
        console.log("Logout issues");
      }
}