import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import { connect } from "react-redux"

import Thumbnail from './Thumbnail'
import { deepLinkUrl, SERIES_THEME, httpsUrl, VIDEO_GRID_THEME_THEME } from '../constants/common'
import StandardTitle from "./StandardTitle";
import { openModal, setPreviewVideo } from "../actions/modalActions";
import Preview from '../components/Preview';
import { checkIfSectionLevelContent, getFontStyleFromFeed } from "../helperfunctions";
import { checkIfMenuOrItemDeeplink, checkIfToDoNothing, GetImageUrl, getTabIndex, isMenuBlockGeofence, isSmartTv } from '../helperfunctions/common'
import GridContent from '../components/GridContent';
import JumboContent from '../components/JumboContent';
import DetailContent from '../components/DetailContent';
import SpotLightContent from '../components/SpotLightContent';
import VideoGridContent from './VideoGridContent';

import { clickParentDivWhenFocusedSpotlightContent, clickParentIfFullscreen, getSpatialDataAttribute} from '../helperfunctions/ctvHelper'
import Loader from './Loader'
import configuration from '../constants/config'
import SeriesContent from './SeriesContent'

function ChangeRoute(section, head){
  if(head === undefined || (window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === head.identifier)){
    var currentLocation = window.location.pathname + "/" + section.identifier
  } else {
    var currentLocation = window.location.pathname + "/" + head.identifier + "/" + section.identifier
  }
  return currentLocation;
}

function checkSingleMenu(contents){
  var singleMenu = true
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" || contents[i].type === "live"){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

function checkMediaPresent(contents){
  var mediaPresent = false
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "vid" || contents[i].type === "image"){
      mediaPresent = true;
      break;
    }
  }
  return mediaPresent;
}

class Content extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentView: '',
      hover: false,
      sourceClass: '',
      showLoader: false,
      deeplinkActionUrl: null,
      identifier: null
    }
  }

  onMouseEventHandler(hover, sourceClass, content=null) {
    if(hover){
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      });

      this.props.setPreviewVideo(content)
      if (isSmartTv()) {
        clickParentDivWhenFocusedSpotlightContent();
        clickParentIfFullscreen();
      }
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      })
    }
  }

  onLinkCLick(route, overlay, actionUrl, e, deepLinkPath){
    if (actionUrl && checkIfToDoNothing(actionUrl, this.props.feeds)) {
      e && e.preventDefault();
      return null;
    }
    if (actionUrl && actionUrl.includes("/registerwall/")) {
      e && e.preventDefault();
      this.props.openModal({type: "login", screen: "registerWall"})
    } else if(actionUrl && actionUrl.includes("/register/")) {
      e && e.preventDefault();
      this.props.openModal({type: "login", screen: "firstSave"})
    } else if (deepLinkPath) {
      this.props.history.push(deepLinkPath)
    }  else {
      if(overlay){
        this.props.openModal({type: "error", message: "You must unlock this content in order to access it."})
      }
      this.setState({ currentView: route })
      this.props.callbackParent(route);
    }
  }

  componentDidMount(){
    document.body.classList.remove("noNav")
  }

  componentDidUpdate(prevProps) {
    if (prevProps.oneFeedLoading && !this.props.oneFeedLoading && this.state.deeplinkActionUrl) {
      this.onDeepLinkClick(null, this.state.identifier, this.state.deeplinkActionUrl)
    }
  }

  onDeepLinkClick = (e, identifier, deeplinkUrl) => {
    e && e.preventDefault();
    if (this.props.oneFeedLoading && checkIfMenuOrItemDeeplink(deeplinkUrl)) {
      this.setState({
        showLoader: true,
        deeplinkActionUrl: deeplinkUrl,
        identifier
      })
    } else {
      let deeplink = deepLinkUrl(deeplinkUrl, this.props.app_id, this.props.feeds)
      this.onLinkCLick(identifier, deeplink[1], deeplinkUrl, e, deeplink[0])
    }
  }

  isBlockedGeofence(content) {
    return isMenuBlockGeofence(this.props.isGeofencingOn, content, this.props.userCountry);
  }

  render() {
    const contents = []
    var primary = this.props.feeds.primaryColor || "#FFFFFF"
    var secondary = this.props.feeds.secondaryColor || "#FFFFFF"
    var nosaveicon = false;
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    if (this.isBlockedGeofence(this.props.content)) {
      return null;
    }

    if(this.props.content.mazContentType === "saved"  && this.props.layout === VIDEO_GRID_THEME_THEME) {
      //No saved section from MAZ ID feed for mobile
      return null;
    }

    if (this.state.showLoader) {
      return <Loader />
    }

    if(this.props.content.contents && checkSingleMenu(this.props.content.contents)) {
      this.props.content.contents.map(function (content, i) {
        content["showInterstitial"] = this.props.content.showInterstitial || content.showInterstitial;
        content["locked"] = this.props.content.locked
        if(!configuration.isAppTvodType) {
          content["access"]=this.props.content.access
        }
        content["special"]=this.props.content.special
        content["displayTitle"]=this.props.content.displayTitle
        content["registerWall"]=this.props.content.registerWall
        content["parent_id"]=this.props.content.identifier
        content["inlineMenu"]=this.props.content.inlineMenu || ""
        content["thumbOrientation"]=this.props.content.thumbOrientation
        if (content.contentUrl && (content.type === "image" || content.type === "vid")) {
          contents.push(
            <div key={content.identifier} className={"detail-swiper item-" + content.identifier}>
              <Thumbnail locked={this.props.content.locked} show_ad={this.props.content.showVideoAd} access={this.props.content.access}
                         all_contents={this.props.content.contents} primary={primary}
                         secondary={secondary} content={content}
                         head={this.props.content.identifier} detail={this.props.layout === "detail"} layout = {this.props.layout} feeds={this.props.feeds}
                         background={this.props.background}
                         index={i} total={this.props.content.contents.length}
                         />
              <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={this.props.content.locked} access={this.props.content.access}
                             content={content} type={"content"} secondary={secondary}
                             feeds={this.props.feeds}
                             layout = {this.props.layout}
                             index={i} total={this.props.content.contents.length}
                            />
            </div>
          )
        }
      }, this)
    } else if(this.props.content.contents === undefined && (this.props.content.type === "vid" || this.props.content.type === "image" || this.props.content.type === "menu")){
      let color, imgBackColor;
      if(this.state.hover && (this.state.sourceClass === "image-video-" + this.props.content.identifier)) {
        //If thumbnail image is present then on hover, background must be transparent (BUG - https://github.com/MagAppZine/MagAppZine-MagControl/issues/7190#issuecomment-699616347)
        color = this.props.content.cover ? 'transparent' : secondary.replace('#FF','#');
        imgBackColor = secondary.replace('#FF','#');
      } else {
        color = this.props.content.cover ? 'transparent' : primary.replace('#FF','#')
        imgBackColor = primary.replace('#FF','#');
      }
      const divBackgroundStyle = {backgroundColor: color};
      const imgBackStyle = {backgroundColor: imgBackColor}
      if(this.props.content.type === "menu") {
        contents.push(
          <div key={this.props.content.identifier} className="detail-swiper">
            <Link to={ChangeRoute(this.props.content, undefined)}
              onClick={(route, overlay) => this.onLinkCLick(this.props.content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
              onFocus={() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content )}
              onBlur={() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}
              { ...getSpatialDataAttribute(0 , 1, this.props.layout) }
              aria-label={this.props.content.title || ""}
              aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
            >
              <div style={divBackgroundStyle} className="img-video"
                  data-title={this.props.content.title} data-subtitle={this.props.content.subtitle} data-summary={this.props.content.summary}
                  onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content )}
                  onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}>
                {this.props.content.cover && (
                  <img style={imgBackStyle} loading="lazy" className="img-thumb swiper-lazy" 
                    style={{visibility:"hidden"}}
                    onLoad={(e) => {e.target.style.visibility='visible'}} src={this.props.content.cover ? GetImageUrl(httpsUrl(this.props.content.cover.url), this.props.content.cover.sizes) : ''}
                  />)}
              </div>
            </Link>
            {this.props.layout !== "spotlight"  &&<div className="titleHolder">
              <p style={fontStyle.primaryFont}>{this.props.content.title}</p>
            </div>}
          </div>
        )
      } else {
        contents.push(
          <div key={this.props.content.identifier} className="detail-swiper">
            <Link to={ChangeRoute(this.props.content, undefined)}
              onClick={(route, overlay) => this.onLinkCLick(this.props.content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
              onFocus = {() => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content)}
              onBlur = {() => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}  
              { ...getSpatialDataAttribute(0 , 1, this.props.layout) }
              aria-label={this.props.content.title || ""}
              aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
            >
              <div style={divBackgroundStyle} className="img-video"
                  data-title={this.props.content.title} data-subtitle={this.props.content.subtitle} data-summary={this.props.content.summary}
                  onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + this.props.content.identifier, this.props.content)}
                  onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + this.props.content.identifier, this.props.content)}>
                {this.props.content.cover && <img loading="lazy" style={imgBackStyle} className="img-thumb swiper-lazy" 
                    style={{visibility:"hidden"}}
                    onLoad={(e) => {e.target.style.visibility='visible'}} src={ this.props.content.cover ? GetImageUrl(httpsUrl(this.props.content.cover.url), this.props.content.cover.sizes) : '' }/>}
              </div>
            </Link>
            <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={this.props.content.locked} access={this.props.content.access}
                           content={this.props.content} type={"content"} secondary={secondary}
                           feeds={this.props.feeds}
                           layout = {this.props.layout}
                           index={0} total={1}
                          />
          </div>
        )
      }
    } else if(this.props.content.contents !== undefined) {
      var mediaPresent = checkMediaPresent(this.props.content.contents)
      this.props.content.contents.map((content, i) => {
        content["showInterstitial"] = this.props.content.showInterstitial || content.showInterstitial;
        const divSecodaryBack = {backgroundColor: content.cover ? 'transparent' :secondary.replace('#FF', '#')}
        const divPrimaryBack = {backgroundColor: content.cover ? 'transparent' :primary.replace('#FF', '#')}
        const imgPrimaryBack = {backgroundColor: primary.replace('#FF', '#')}
        const imgSecodaryBack = {backgroundColor: secondary.replace('#FF', '#')}
        if (this.isBlockedGeofence(content)) {
          return null;
        }
        else if(content.mazIDSectionType === "saved" && this.props.layout === VIDEO_GRID_THEME_THEME) {
          //No saved section from MAZ ID feed for mobile
        } else if(content.deeplinkUrl) {
          // var deeplink = deepLinkUrl(content.deeplinkUrl, this.props.app_id, this.props.feeds)
          if (this.state.hover && (this.state.sourceClass === "image-video-" + content.identifier)) {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                <Link to={'#'}
                  // onClick={(e) => this.onLinkCLick(content.identifier, deeplink[1], content.deeplinkUrl, e, deeplink[0])}
                  onClick={(e) => this.onDeepLinkClick(e, content.identifier, content.deeplinkUrl)}
                  className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                  onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onBlur = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                  { ...getSpatialDataAttribute(i, this.props.content.contents.length) }
                  aria-label={content.title || ""}
                  aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
                >
                        {/*If thumbnail image is present then on hover, background must be transparent (BUG - https://github.com/MagAppZine/MagAppZine-MagControl/issues/7190#issuecomment-699616347) */}
                  <div style={divSecodaryBack} 
                        className="img-video"
                      data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                      onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                      onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                    {content.cover && content.cover.url && (
                      <img loading="lazy"  style={imgSecodaryBack} className="img-thumb swiper-lazy" 
                        style={{visibility:"hidden"}}
                        onLoad={(e) => {e.target.style.visibility='visible'}} src={GetImageUrl(content.cover.url, content.cover.sizes)}
                    />)}
                  </div>
                </Link>
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if (!mediaPresent) {
              nosaveicon = true
            }
          } else {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                <Link to={'#'}
                  // onClick={(e) => this.onLinkCLick(content.identifier, deeplink[1], content.deeplinkUrl, e, deeplink[0] )}
                  onClick={(e) => this.onDeepLinkClick(e, content.identifier, content.deeplinkUrl)}
                  className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                  onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onBlur = {() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}  
                  { ...getSpatialDataAttribute(i, this.props.content.contents.length) }
                  aria-label={content.title || ""}
                  aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
                >
                  <div style={divPrimaryBack} className="img-video"
                      data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                      onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                      onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                    {content.cover && content.cover.url && (
                      <img loading="lazy" style={imgPrimaryBack} className="img-thumb swiper-lazy" 
                        style={{visibility:"hidden"}}
                        onLoad={(e) => {e.target.style.visibility='visible'}} src={GetImageUrl(content.cover.url, content.cover.sizes)}
                      />)}
                  </div>
                </Link>
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if (!mediaPresent) {
              nosaveicon = true
            }
          }
        } else if(content.type === "vid" || content.type === "image"){
          // case where video and images are with menu inside a feed
          content["locked"] = this.props.content.locked
          content["access"]=this.props.content.access
          content["special"]=this.props.content.special
          content["displayTitle"]=this.props.content.displayTitle
          content["registerWall"]=this.props.content.registerWall
          content["parent_id"]=this.props.content.identifier
          content["inlineMenu"]=this.props.content.inlineMenu || ""
          contents.push(
            <div key={content.identifier} className={"detail-swiper item-" + content.identifier}>
              <Thumbnail feeds={this.props.feeds} locked={content.locked} show_ad={this.props.content.showVideoAd} access={content.access}
                        all_contents={this.props.content.contents} primary={primary}
                        secondary={secondary} content={content}
                        head={this.props.content.identifier} detail={this.props.layout === "detail"} layout = {this.props.layout} feeds={this.props.feeds} index={i} total={this.props.content.contents.length}/>
              <StandardTitle no_color={(this.props.layout !== "jumbo")} locked={content.locked} access={content.access}
                            content={content} type={"content"} secondary={secondary}
                            feeds={this.props.feeds}
                            layout = {this.props.layout}
                            items={this.props.items} index={i} total={this.props.content.contents.length}/>
            </div>
          )
        } else {
          if(this.state.hover && (this.state.sourceClass === "image-video-" + content.identifier)){
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                <Link to={ChangeRoute(content, this.props.content)}
                    onClick={(route, overlay) => this.onLinkCLick(content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                    onFocus = {() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onBlur={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}
                    { ...getSpatialDataAttribute(i, this.props.content.contents.length) }
                    aria-label={content.title || ""}
                    aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
                  >
                  <div style={divSecodaryBack} className="img-video"
                    data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                    onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                    {content.cover && content.cover.url && (
                      <img loading="lazy" style={imgSecodaryBack} className="img-thumb swiper-lazy" 
                        style={{visibility:"hidden"}}
                        onLoad={(e) => {e.target.style.visibility='visible'}} src={GetImageUrl(content.cover.url, content.cover.sizes)}
                      />)}
                  </div>
                </Link>
              {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                <p style={fontStyle.primaryFont}>{content.title}</p>
              </div>}
              </div>
            )
            if(!mediaPresent){
              nosaveicon = true
            }
          } else {
            contents.push(
              <div key={content.identifier} className="detail-swiper">
                <Link to={ChangeRoute(content, this.props.content)}
                  onClick={(route, overlay) => this.onLinkCLick(content.identifier, false)} className="thumbLink focusable" tabIndex={getTabIndex()} data-toggle="tab"
                  onFocus={() => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                  onBlur={() => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}                                          
                  { ...getSpatialDataAttribute(i, this.props.content.contents.length) }
                  aria-label={content.title || ""}
                  aria-describedby={`content-head-${this.props.content.parent_id || this.props.content.identifier}`}
                >
                  <div style={divPrimaryBack} className="img-video"
                    data-title={content.title} data-subtitle={content.subtitle} data-summary={content.summary}
                    onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-video-" + content.identifier, content)}
                    onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-video-" + content.identifier, content)}>
                    {content.cover && content.cover.url && (
                      <img loading="lazy" style={imgPrimaryBack} className="img-thumb swiper-lazy" 
                        style={{visibility:"hidden"}}
                        onLoad={(e) => {e.target.style.visibility='visible'}}  src={GetImageUrl(content.cover.url, content.cover.sizes)}
                      />)}
                  </div>
                </Link>
                {this.props.layout !== "spotlight"  &&<div className="titleHolder">
                  <p style={fontStyle.primaryFont}>{content.title}</p>
                </div>}
              </div>
            )
            if(!mediaPresent){
              nosaveicon = true
            }
          }
        }
      }, this)
    }

    if (!contents.length) {
      return null;
    }

    if(this.props.layout === "detail"){
      return(
        <DetailContent key={contents.length} secondary={secondary} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} detail_content={this.props.content.contents}  layout={this.props.layout}/>
      )
    } else if(this.props.layout === "jumbo"){
      return(
        <JumboContent key={contents.length}  content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds}  layout={this.props.layout}/>
      )
    } else if(this.props.layout === "spotlight" || this.props.layout === "fullscreen"){
      return(
        <SpotLightContent key={contents.length}  index = {this.props.index} section = {this.props.section} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds}  layout={this.props.layout}/>
      )
    } else if(this.props.layout === VIDEO_GRID_THEME_THEME){
      return(
          <VideoGridContent nosaveicon={nosaveicon} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} />
      )
    } else if(this.props.layout === SERIES_THEME) {
      return <SeriesContent nosaveicon={nosaveicon} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} layout={this.props.layout} runningOnMobile={this.props.runningOnMobile}/>
    } else {
      return(
        <GridContent key={contents.length}  nosaveicon={nosaveicon} content={this.props.content} title={this.props.content.title} contents={contents} feeds={this.props.feeds} layout={this.props.layout}/>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    oneFeedLoading: state.feeds.oneFeedLoading,
    isGeofencingOn: state.user.isGeofencingOn,
    userCountry: state.user.userCountry
  }
}

const mapDispatchToProps = {
  openModal: openModal,
  setPreviewVideo, setPreviewVideo
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
