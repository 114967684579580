import axios from "axios";
import * as actionTypes from '../constants/actionTypes';
import * as APIS from "../constants/api";
import configuration from "../constants/config";
import { getEnvironmentUrl, getApiToken, getTvodAPIKey } from '../constants/initializer'
import { addTvodAPIRegionalisation } from "../helperfunctions/common";

export function getSearchResult(app_id, query){
    return function(dispatch, getState) {
        const {locale_id, language} = addTvodAPIRegionalisation({}, getState);
        var url =  configuration.isAppTvodType
            ? `${APIS.TVOD_SEARCH_API}?app_id=${app_id}&query=${query}&key=${getTvodAPIKey()}&device=tv&locale_id=${locale_id}&language=${language}`
            : getEnvironmentUrl() + '/api/v3/search/'+ app_id +'?q=' + query + '&api_token='+ getApiToken("search") +'&tv=true';
        dispatch({type: actionTypes.TEXT_SEARCH, payload: {string: query}})
        axios.get(url)
            .then((response) => {
                dispatch({type: actionTypes.RECEIVE_SEARCH_ITEMS, payload: response.data, app_id: app_id})
            })
    }
}

export function clearSearch() {
    return function(dispatch) {
        dispatch({type: actionTypes.CLEAR_SEARCH})
    }
}
