import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import { siteUrl } from '../constants/initializer'
import { getEmptyFeedMessages, getFetchIdFromUrl, VIDEO_GRID_THEME_THEME, EPG_THEME, SERIES_THEME } from "../constants/common"
import { closeModal, setPreviewVideo, openModal } from "../actions/modalActions"
import * as analytics  from '../constants/analytics';
import { checkIfSectionIdExist, checkIfSectionLevelContent, findDefaultSection } from '../helperfunctions';
import configuration from '../constants/config';
import { checkIfOverlayVideoIsPresent, checkLoginUIMetaData, getRedirectUrlAfterGeofence, isSmartTv, tvFocus } from '../helperfunctions/common';
import Background from '../components/Background';
import Logo from '../components/Logo';
import Banner from '../components/Banner';
import Content from '../components/Content';
import SpotlightSection from '../components/SpotlightSection';
import FullScreenSection from '../components/FullScreenSection';
import SectionVideoSetup from '../components/SectionVideoSetup';
import Video from '../components/Video';
import FullImage from '../components/FullImage';
import ContentSectionImage from '../components/ContentSectionImage';
import Subscribe from './Subscribe';
import MyQueue from './MyQueue';
import EPGSection from '../components/EPGSection';
import Loader from '../components/Loader';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import SeriesSection from '../components/SeriesSection';

function FindDefaultSection(props) {
  return findDefaultSection(props)
}

function checkSingleMenu(contents){
  var singleMenu = true;
  const firstType = contents && contents[0] && contents[0].type;
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" ||
     (contents[i].type === "live" && !configuration.isAppTvodType) ||
     (configuration.isAppTvodType && firstType !== contents[i].type)
     //Change in conditions for bug https://github.com/MagAppZine/MAZ-Video-Ui/issues/1043
    ){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

function ChangeRoute(path_location, section_id){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,3)
  if(section_id)
    currentLocation.push(section_id)
  currentLocation = currentLocation.concat(path_location)
  return currentLocation.join("/");
}

function FindSection(props, section_id){
  var feed = props;
  var currentLocation = section_id.split("/")
  var contents = props.sections
  var allContents = props.section;
  for(var i=0; i<currentLocation.length; i++){
    for(var j=0; j<contents.length; j++){
      if(contents[j].identifier == currentLocation[i]){
        feed = contents[j]
        allContents = contents
        if(contents[j].contents) {
          contents = contents[j].contents
        }
        if(!Array.isArray(contents)){
          break;
        }
      }
    }
  }
  if(feed.contents && (feed.contents[0].type == "fake" || feed.contents[0].type == "live") && feed.contents.length < 2){
    const layout = feed.regularLayout;
    const thumbOrientation = feed.thumbOrientation
    const showInterstitial = feed.showInterstitial
    feed = feed.contents[0]
    feed.regularLayout = layout
    feed.thumbOrientation = thumbOrientation
    feed.showInterstitial = showInterstitial
  }
  return [feed, allContents];
}

const getContentForMazFeeds = (mazFeedType, content, itemsReducer) => {
  if (mazFeedType === "saved") {
    const savedContents = itemsReducer.items.content;
    let newContent = {...content};
    if (savedContents.length)
      newContent.contents = savedContents.map(contentData => {
        contentData.mazContentType = 'saved'
        return contentData
      });
    newContent.mazContentType = 'saved';
    return newContent;
  }
  else if (mazFeedType === "progress") {
    const progressContents = itemsReducer.progress.contents; 
    let newContent = {...content};
    if (progressContents.length)
      newContent.contents = progressContents.map(contentData => {
        contentData.mazContentType = 'progress'
        return contentData
      });;
    newContent.mazContentType = 'progress';
    return newContent;
  }
  else if (mazFeedType === "history") {
    const historyContents = itemsReducer.history.contents;
    let newContent = {...content};
    if (historyContents.length)
      newContent.contents = historyContents.map(contentData => {
        contentData.mazContentType = 'history'
        return contentData
      });;
    newContent.mazContentType = 'history';
    return newContent;
  }
  return content;
}

const getMazFeedContentForDetailTheme = (content, itemsReducer, feeds) => {
  // const savedSection = itemsReducer.items
  const self = this;
  let newContent = {...content};
  if (newContent.mazIDSectionType) {
    newContent = getContentForMazFeeds(newContent.mazIDSectionType, newContent, itemsReducer, feeds);
  }
  if (newContent.contents && newContent.contents.length) {
    newContent.contents = newContent.contents.map((singleContent) => {
      let newSingleContent = { ...singleContent };
      if (newSingleContent.mazIDSectionType) {
        newSingleContent = getContentForMazFeeds(singleContent.mazIDSectionType, newSingleContent, itemsReducer, feeds)
      }
      return newSingleContent;
    })
    newContent.contents = newContent.contents.filter(singleContent => {
      if (singleContent.mazIDSectionType && !singleContent.contents) {
        return false;
      }
      return true;
    })
  }
  return newContent;
}

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: {},
      backgound: {},
      banners: {},
      section: {},
      path_location: [],
      section_id: props.section_id,
      all_contents: [],
      currentView: '',
      save_item: {},
      redirect_url: '/',
      show_banners: false,
      banner_id: null
    }
  }

  navigateToRoute(route) {
    this.setState({ currentView: route })
    this.props.parentCallback(route);
  }

  componentWillMount() {
    // When pressed browser back button close all modal.
    if (this.props.history.action === "POP") {
      this.props.closeModal()
      if(configuration.isAppTvodType && this.props.userEngaged && window.location.search === "?mode=watch") {
        window.history.replaceState("view", {}, `${window.location.pathname}`)
      }
    }
    // if (!this.props.pendingSubscription && !this.props.isUserLoading && this.props.isMeteringLoading) {
      // stoping close modal here, creating more problems. Can remove this code after review
      // this.props.closeModal()
      // }

    this.setState({section_id: this.props.section_id})
    if(this.props.section_id){
      var sectionData = FindSection(this.props.feeds, this.props.section_id)
      section = sectionData[0]
      section = getMazFeedContentForDetailTheme(section, this.props.items)
      this.setState({all_contents: sectionData[1]})
      this.setState({section: section})
      this.setState({logo: section.logo || this.props.feeds.logo})
      this.setState({background: section.background})
      var bannerId = section.bannerId
      if(bannerId !== undefined && this.props.feeds.bannerGroups !== undefined && this.props.feeds.bannerGroups[bannerId] !== undefined){
          this.setState({show_banners: true, banner_id: bannerId})
          this.setState({banners: this.props.feeds.bannerGroups[bannerId]})
      }
    } else {
      var section = FindDefaultSection(this.props.feeds)
      section[0] = getMazFeedContentForDetailTheme(section[0], this.props.items)
      this.setState({section: section[0]})
      this.setState({logo: section[0].logo || this.props.feeds.logo})
      this.setState({background: section[0].background})
      this.setState({path_location: section[1]})
    }
  }

  componentWillReceiveProps(nextProps){
    this.loadSectionDataInState(nextProps)
  }

  loadSectionDataInState = (nextProps) => {
    this.setState({section_id: this.props.section_id})
    if(this.props.section_id){
      var sectionData = FindSection(this.props.feeds, this.props.section_id)
      let section = sectionData[0]
      section = getMazFeedContentForDetailTheme(section, nextProps.items)
      this.setState({all_contents: sectionData[1]})
      this.setState({section: section})
      this.setState({logo: section.logo || this.props.feeds.logo})
      this.setState({background: section.background})
      var bannerId = section.bannerId
      if(bannerId !== undefined && this.props.feeds.bannerGroups !== undefined && this.props.feeds.bannerGroups[bannerId] !== undefined){
          this.setState({show_banners: true, banner_id: bannerId})
          this.setState({banners: this.props.feeds.bannerGroups[bannerId]})
      }
    } else {
      var section = FindDefaultSection(this.props.feeds)
      section[0] = getMazFeedContentForDetailTheme(section[0], nextProps.items)
      this.setState({section: section[0]})
      this.setState({logo: section[0].logo || this.props.feeds.logo})
      this.setState({background: section[0].background})
      this.setState({path_location: section[1]})
    }
    this.setState({ currentView: this.props.section_id })
    // this.props.parentCallback(this.props.section_id);
    if (!(checkIfOverlayVideoIsPresent() || (this.props.section_id === nextProps.section_id))) {
      this.props.parentCallback(this.props.section_id);
    }
  }

  handleRemoteClick = (e) => {
		let gridParent = document.querySelector('#content-box-1.standard');
		if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40) {
			if (gridParent && gridParent.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".swiper-container");
        const currSwiper = nearestContainer.swiper;
        let currIndex;
        if (e.keyCode === 37) {
          nearestContainer.swiper.slidePrev()          
        }
        if (e.keyCode === 39) {
          nearestContainer.swiper.slideNext()
        }
			}
		}
	}

  componentDidMount(){
    if (isSmartTv()) {
      if(!document.getElementById('nav-bar')) {
        JsSpatialNavigation.focus();
      } else {
        if(!document.getElementById('nav-bar').contains(document.activeElement))
          JsSpatialNavigation.focus('navbar');
      }
			// document.addEventListener('keyup',this.handleRemoteClick);
		}
        analytics.setPageViewSections(this.props.feeds, window.location.pathname)
        if(configuration.is_simulator) {
          // to add active class & style in navbar for simulator
          this.addClassToFixNavbar();
          var navbarLinks = document.getElementsByClassName("headerLinks") || []
          if (navbarLinks.length>0){
          const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
          for(var i=0; i<navbarLinks.length; i++){
            navbarLinks[i].classList.remove("active")
            if(ottTabBarInactiveColor) {
              navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
            }
          }
          if(this.props.section_id){
            if(document.getElementsByClassName("navbar-" + this.props.section_id.split("/")[0])[0]) {
              document.getElementsByClassName("navbar-" + this.props.section_id.split("/")[0])[0].classList.add("active")}
              if(ottTabBarActiveColor && document.getElementsByClassName("navbar-" + this.props.section_id.split("/")[0])[0]) {
                document.getElementsByClassName("navbar-" + this.props.section_id.split("/")[0])[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
              }
            }
          else{
            if(document.getElementsByClassName("navbar-" + this.state.path_location[0])[0]) {
              document.getElementsByClassName("navbar-" + this.state.path_location[0])[0].classList.add("active")
              if(ottTabBarActiveColor) {
                document.getElementsByClassName("navbar-" + this.state.path_location[0])[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
              }
            }
          }}
        }

        // this.tvFocus();
        tvFocus(this.state.section.regularLayout);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(configuration.is_simulator) {
      this.addClassToFixNavbar();
    }
    if (prevProps.oneFeedLoading && !this.props.oneFeedLoading) {
      this.loadSectionDataInState(this.props)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup',this.handleRemoteClick);
  }

  isCurrentSectionHaveContents = () => {
    let currSection;
    if (this.props.section_id) {
      currSection = FindSection(this.props.feeds, this.props.section_id)[0]
    } else {
      currSection = this.state.section;
    }
    let section_id = this.props.section_id
    const isSectionIdExistObject = checkIfSectionIdExist(section_id, this.props.feeds);
    if (section_id && !isSectionIdExistObject.isSectionId) {
      section_id = isSectionIdExistObject.availablePath ? isSectionIdExistObject.availablePath : "";
      this.props.history.replace(`/apps/${configuration.app_id}/${section_id}`);
      this.props.closeModal();
      return false;
    }    
    if ((currSection.type === "menu" || currSection.type === "fake") && (!currSection.contents || !currSection.contents.length)) {
      if (!checkIfSectionLevelContent()) {
        let msg = "Items are not present at the moment, Please try after some time.";
        if (currSection.mazIDSectionType)
          msg = getEmptyFeedMessages(currSection.mazIDSectionType);
        this.props.openModal({
          type: "error",
          message: msg,
          goBack: true,
        })
      }
      return false
    }
    return true
  }

  /**
   * Function to add class to fix navbar if layout is jumbo or standard
   */
  addClassToFixNavbar = () => {
    const navbar = document.getElementsByClassName("nav-view")[0];
    if (navbar && (this.state.section.regularLayout == "jumbo" || this.state.section.regularLayout == "standard" || this.state.section.regularLayout === null)) {
      navbar.parentNode.classList.add('fix-nav');
    }
    else
      navbar && navbar.parentNode.classList.remove('fix-nav');
  }

  renderSeries() {
    return (
      <SeriesSection
        app_id = {this.props.app_id}
        feeds={this.props.feeds}
        layout={this.state.section.regularLayout}
        section={this.state.section}
        callbackParent={(route) => this.navigateToRoute(route)}
        pathLocation={this.state.currentView}
        runningOnMobile={this.props.runningOnMobile}
        background={this.state.background}
      />
    )
  }

  render() {
    if ((this.state.section.type === 'menu' || checkIfSectionLevelContent()) && this.props.isBlockedGeofence(this.state.section)) {
      const redirectUrl = getRedirectUrlAfterGeofence(this.props.feeds, this.props.isBlockedGeofence);
      return <Redirect to={`${redirectUrl}`}/>
    }
    if (this.props.oneFeedLoading && !checkIfSectionLevelContent()) {
      return <Loader />
    }
    if (!this.isCurrentSectionHaveContents()) {
      return (<div className="no-content-div">
        </div>)
    }
    if(siteUrl(this.props.app_id).replace("https:","http:") === window.location.href){
      this.props.history.push(ChangeRoute(this.state.path_location, this.state.section_id))
    }
    if(this.state.section.type === "fake" || this.state.section.type === "live" || this.state.section.type === "svideo") {
      document.body.className = "onlyVideo transparent"
    } else if(this.state.section.type === "image" && this.props.section_id.split("/").length === 1){
      document.body.className = "onlyImage transparent"
    } else {
      if(this.props.runningOnMobile) {
        document.body.className = "video-grid"
      } else if(this.state.section.regularLayout == "jumbo"){
        document.body.className = "jumbo"
      } else if(this.state.section.regularLayout == "detail" && this.state.section.type !== "subscribe"){
        document.body.className = "detail"
      } else {
        document.body.className = "grid"
      }
    }

    //If overlay video is present, add noNav class, only for simulator
    if(document.querySelector('.simulator-route-container') && checkIfOverlayVideoIsPresent() && !checkIfSectionLevelContent()) {
      // props change while video is playing causes body class change
      document.body.classList.add("noNav");
    }

    if (this.state.section.type === "subscribe" || this.state.section_id === "subscription"){
      return <div> <Subscribe feeds={this.props.feeds} subscriptions={this.props.feeds.subscriptions} /></div>
    }else if(this.state.section.type === "saved" || this.state.section_id === "queue"){
      return  <div>
              <div className="content-box" id="content-box-1">
              <MyQueue  isSavedSection={true} feeds={checkLoginUIMetaData(this.props.feeds)} app_id={this.props.app_id} background={this.props.feeds.savedUIMetadata.background} item_id={0} section={this.state.section} runningOnMobile={this.props.runningOnMobile}/>
              </div>
            </div>
    }

    if(this.state.section.type === "fake"){
      return(
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={this.state.background} />
            <SectionVideoSetup feeds={this.props.feeds} section={this.state.section} background={this.state.background}/>
          </div>
        </div>
      )
    } else if(this.state.section.type === "live"){
      return(
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={this.state.background} />
            <SectionVideoSetup feeds={this.props.feeds} section={this.state.section} background={this.state.background}/>
          </div>
        </div>
      )
    } else if(this.state.section.type === "svideo") {
      return (
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={this.state.background}/>
            <SectionVideoSetup section={this.state.section} feeds={this.props.feeds} background={this.state.background}/>
          </div>
        </div>
      )
    } else if(this.state.section.type === "image" && this.props.section_id.split("/").length === 1) {
      return(
        <div className="content-box" id="content-box-1">
          <ContentSectionImage section={this.state.section} app_id={this.props.app_id} section_id={this.state.section_id} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
        </div>
      )
    } else if(this.state.section.type === "image"){
      return(
        <FullImage section={this.state.section} app_id={this.props.app_id} callbackParent={(route) => this.navigateToRoute(route)} pathLocation={this.state.currentView} all_contents={this.state.all_contents} section_id={this.state.section_id} feeds={this.props.feeds} runningOnMobile={this.props.runningOnMobile}/>
      )
    } else if(this.state.section.type === "vid"){
      return(
        <div className="content-box" id="content-box-1">
          <Video section={this.state.section} app_id={this.props.app_id} callbackParent={(route) => this.navigateToRoute(route)} pathLocation={this.state.currentView} all_contents={this.state.all_contents} section_id={this.state.section_id} feeds={this.props.feeds} isBlockedGeofence={this.props.isBlockedGeofence} />
        </div>
      )
    } else if(this.state.section.contents !== undefined && this.state.section.regularLayout === SERIES_THEME) {
      return this.renderSeries();
    } else if(this.state.section.contents !== undefined && this.state.section.regularLayout === EPG_THEME) {
      return (
        <div>
          <EPGSection
            app_id = {this.props.app_id}
            feeds={this.props.feeds}
            layout={this.state.section.regularLayout}
            content={this.state.section}
            callbackParent={(route) => this.navigateToRoute(route)}
            pathLocation={this.state.currentView}
            runningOnMobile={this.props.runningOnMobile}
          />
        </div>
      )
    } else if(this.props.runningOnMobile && this.state.section.contents !== undefined) {
      // For mobile support
      const {contents, identifier} = this.state.section;
      const isSingleMenu = checkSingleMenu(contents);
      return(
        <>
          { this.state.show_banners && <Banner oneFeedLoading={this.props.oneFeedLoading} banners={this.state.banners} app_id={this.props.app_id} feeds={this.props.feeds}  bannerId={this.state.banner_id} runningOnMobile={true}/> }
          <div className="content-box video-grid-theme">
            <Background />
            {isSingleMenu
              ? <Content key={identifier} app_id={this.props.app_id} feeds={this.props.feeds} layout={VIDEO_GRID_THEME_THEME} content={this.state.section} callbackParent={(route) => this.navigateToRoute(route)} pathLocation={this.state.currentView} />
              : this.state.section.contents.map(function(content, i) {
                return <Content key={content.identifier} app_id={this.props.app_id} feeds={this.props.feeds} layout={VIDEO_GRID_THEME_THEME} content={content} callbackParent={(route) => this.navigateToRoute(route)} pathLocation={this.state.currentView} />
              }, this)
            }
          </div>
        </>
      )
    } else if(this.state.section.regularLayout === "detail"){
      return(
        <div className="content-box" id="content-box-1">
          <Background background={this.state.background} />
          <Content background={this.state.background} app_id={this.props.app_id} feeds={this.props.feeds} content={this.state.section} callbackParent={(route) => this.navigateToRoute(route)} layout={this.state.section.regularLayout} pathLocation={this.state.currentView} />
        </div>
      )
    }else if(this.state.section.regularLayout === "spotlight" && this.state.section.identifier != getFetchIdFromUrl(this.state.section.contentUrl) || (this.state.section.regularLayout === "spotlight" && this.state.section.contents && checkSingleMenu(this.state.section.contents))){
      return(
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={this.state.background} />
            <SpotlightSection section = {this.state.section}
              app_id = {this.props.app_id}
              feeds={this.props.feeds}
              layout={this.state.section.regularLayout}
              content={this.state.section}
              callbackParent={(route) => this.navigateToRoute(route)}
              pathLocation={this.state.currentView}
              background={this.state.background}
            />
          </div>
        </div>
      )
    }else if(this.state.section.regularLayout === "fullscreen" && this.state.section.identifier != getFetchIdFromUrl(this.state.section.contentUrl) || (this.state.section.regularLayout === "fullscreen" && this.state.section.contents && checkSingleMenu(this.state.section.contents))){
      return(
        <div>
          <div className="content-box" id="content-box-1">
            <Background background={this.state.background} />
            <FullScreenSection section = {this.state.section} 
              app_id = {this.props.app_id}
              feeds={this.props.feeds}
              layout={this.state.section.regularLayout}
              content={this.state.section}
              callbackParent={(route) => this.navigateToRoute(route)}
              pathLocation={this.state.currentView}
              background={this.state.background}
            />
          </div>
        </div>
      )
    }
     else if(this.state.section.identifier != getFetchIdFromUrl(this.state.section.contentUrl) || (this.state.section.contents && checkSingleMenu(this.state.section.contents))){
      return(
        <div>
          <div className="content-box themeLogo" id="content-box-1">
            <Background background={this.state.background} />

            { this.state.logo ? <Logo logo={this.state.logo} /> : <div className="no-logo-div"></div>}

            { this.state.show_banners && <Banner oneFeedLoading={this.props.oneFeedLoading} banners={this.state.banners} app_id={this.props.app_id} feeds={this.props.feeds} bannerId={this.state.banner_id}/> }

            <Content background={this.state.background} app_id={this.props.app_id} feeds={this.props.feeds} content={this.state.section} callbackParent={(route) => this.navigateToRoute(route)} layout={this.state.section.regularLayout} pathLocation={this.state.currentView} />

          </div>
        </div>
      )
    } else if(this.state.section.contents !== undefined && this.state.section.regularLayout === "spotlight") {
      return (
        <div>
          <Background background={this.state.background} />
          <SpotlightSection section = {this.state.section}
            app_id = {this.props.app_id}
            feeds={this.props.feeds}
            layout={this.state.section.regularLayout}
            content={this.state.section}
            callbackParent={(route) => this.navigateToRoute(route)}
            pathLocation={this.state.currentView}
            background={this.state.background}
          />
        </div>
      )
    }else if(this.state.section.contents !== undefined && this.state.section.regularLayout === "fullscreen") {
      return (
        <div>
          <Background background={this.state.background} />
          <FullScreenSection section = {this.state.section}
            app_id = {this.props.app_id}
            feeds={this.props.feeds}
            layout={this.state.section.regularLayout}
            content={this.state.section}
            callbackParent={(route) => this.navigateToRoute(route)}
            pathLocation={this.state.currentView}
            background={this.state.background}
          />
        </div>
      )
    } else if(this.state.section.contents !== undefined) {
      const layoutCurr = this.state.section.regularLayout;
      return (
        <div>
          <div className={`content-box themeLogo ${layoutCurr}`} id="content-box-1">
            <Background background={this.state.background} />

              { this.state.logo ? <Logo logo={this.state.logo} /> : <div className="no-logo-div"></div>}

              { this.state.show_banners && <Banner oneFeedLoading={this.props.oneFeedLoading}  banners={this.state.banners} app_id={this.props.app_id} feeds={this.props.feeds}  bannerId={this.state.banner_id}/> }

              {this.state.section.contents.map(function(content, i){return <Content background={this.state.background} key={content.identifier} app_id={this.props.app_id} feeds={this.props.feeds} layout={this.state.section.regularLayout} content={content} callbackParent={(route) => this.navigateToRoute(route)} pathLocation={this.state.currentView} />}, this)}

          </div>
        </div>
      )
    } else {
      return(
        <div>
          <Background background={this.state.background} />
          { this.state.logo ? <Logo logo={this.state.logo} /> : <div className="no-logo-div"></div>}
          { this.state.show_banners && <Banner oneFeedLoading={this.props.oneFeedLoading} banners={this.state.banners} app_id={this.props.app_id} feeds={this.props.feeds} bannerId={this.state.banner_id} /> }
        </div>
      );
    }
  }

}

const mapStateToProps = (state) => ({
  items: state.items,
  userEngaged: state.user.userEngaged,
})

const mapDispatchToProps = {
  closeModal: closeModal,
  setPreviewVideo: setPreviewVideo,
  openModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section));
