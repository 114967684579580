import React from 'react';
import FakeLive from '../components/FakeLive'
import LiveSetup from '../components/LiveSetup'
import { httpsUrl } from "../constants/common"
import * as analytics  from '../constants/analytics';


function videoType(url){
  if (url.split('.').pop() == "m3u8"){
    return "live"
  } else {
    return "fake"
  }
}

class ContentSectionVideo extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      section: {}
    }
  }


  componentWillMount(){
    this.setState({section: {contents: [{identifier: this.props.section.identifier,contentUrl: httpsUrl(this.props.section.contentUrl)}]}})
  }

  componentDidMount(){
    //analytics.setEvent('Video', 'Video Play', this.props.content.title)
  }

  render(){
    if(videoType(this.props.section.contentUrl) === "live") {
      return (
        <LiveSetup streamCounterUpdate={this.state.streamCounterUpdate} feeds={this.props.feeds} section={this.props.section} runningOnMobile={this.props.runningOnMobile}/>
      )
    } else {
      return (
        <FakeLive streamCounterUpdate={this.state.streamCounterUpdate} feeds={this.props.feeds} section={this.state.section} runningOnMobile={this.props.runningOnMobile}/>
      )
    }
  }
}

export default ContentSectionVideo;