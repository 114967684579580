import 'react-app-polyfill/stable';
import React from "react"
import ReactDOM from "react-dom"
import App from './App';
import { detectPlatform, isSmartTv } from './helperfunctions/common';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import configuration from './constants/config';
import { keyMappings } from './helperfunctions/ctvHelper';

elementScrollIntoViewPolyfill();
const app = document.getElementById('root')

ReactDOM.render(<App/>, app);

//Initialize function
var init = function () {
    // TODO:: Do your initialization job
    // console.log('init() called');

    document.addEventListener('visibilitychange', function() {
        if(document.hidden){
            // Something you want to do when hide or exit.
        } else {
            // Something you want to do when resume.
        }
    });
 
    // add eventListener for keydown
    // document.addEventListener('keydown', function(e) {
    //     if (document.getElementById("nav-bar")) {
    //         document.getElementById("nav-bar").style.top = "0";
    //         document.getElementById("nav-bar").style.transitionDuration = ".4s" ;
    //     }

    // 	switch(e.keyCode){
    //         case 37: //LEFT arrow
    //             // alert("Left pressed");
    //             SpatialNavigation.move('left');
    //             break;
    //         case 38: //UP arrow
    //             // alert("Up pressed");
    //             SpatialNavigation.move('up');
    //             break;
    //         case 39: //RIGHT arrow
    //             // alert("Right pressed");
    //             SpatialNavigation.move('right');
    //             break;
    //         case 40: //DOWN arrow
    //             SpatialNavigation.move('down');
    //             // alert("Down pressed");
    //             break;
    //         case 13: //OK button
    //             // alert("OK pressed");
    //             console.log('Aakash event',document.activeElement.click());
    //             e.preventDefault();
    //             break;
    //         case 13: //RETURN button
    //             // tizen.application.getCurrentApplication().exit();
    //             // alert("Return pressed");
    //             break;
    //         default:
    //             console.log('Key code : ' + e.keyCode);
    //             break;
    // 	}
    // });
};
// window.onload can work without <body onload="">

function inputEvent(e) {
    if(keyMappings(e.keyCode) == 'OK') {
        document.activeElement.click();    
    } 
}

window.onload = ()  => {
    document.addEventListener('keydown', inputEvent);

    if(configuration.platform == 'xbox') {
        window.navigator.gamepadInputEmulation = "keyboard";
    }
    
    if(isSmartTv() && window.tizen) {
        // for samsung TV
        const supported_keys = [
            'MediaPlayPause',
            'MediaPlay',
            'MediaPause',
            'MediaStop',
            'MediaFastForward',
            'MediaRewind'
        ];
        window.tizen.tvinputdevice.registerKeyBatch(supported_keys);
    }
};

window.onunload = () => {
    document.removeEventListener('keydown', inputEvent);
}


  /*
Vizio, binding the event handlers
*/
    // primary event handler
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', function(e) {
        // IFA - ID for Advertisement
        window.VIZIO.setAdvertiserIDListener(function(AdvertiserID) {
            window.VIZIO.ad_id = AdvertiserID;
        });

        /**
        * Device Language Handler
        *
        * ex: {code: "en", name: "English"}
        */
        window.VIZIO.setDeviceLanguageHandler(function(deviceLanguage){
            console.log("setDeviceLanguageHandler language: "+ deviceLanguage)
        });
        
        window.VIZIO.getDevicePlaybackQualities(function(qualities){
            // gives an array of playback qualities supported per device
            console.log("Device Playback Qualities: " + qualities)
        });
        
        window.VIZIO.getDeviceHDRCapabilities(function(capabilities){
            // example: ["DolbyVision","HDR10"]
            console.log("Device HDR Capabilities: " + capabilities)
        });

    }, false);