import React, { useState } from 'react';
import { connect } from "react-redux";

import { httpsUrl } from '../constants/common';
import configuration from '../constants/config';
import { GetImageUrl } from '../helperfunctions/common';
import GridContent from './GridContent';
import { openModal } from "../actions/modalActions";
import { getThumbnailType } from '../helperfunctions';

const SeriesContent = (props) => {
  const {feeds, layout, content, contents, runningOnMobile, nosaveicon} = props;

  
  const playEpisode = (episode) => {
    const propsObj = {
      head: content.identifier, type: "video", identifier: episode.identifier,
      content: episode, show_ad: content.showVideoAd, all_contents: content.contents,
      pathname: window.location.pathname, firstpath: getThumbnailType(episode, content.identifier)
    }

    if (configuration.isAppTvodType || !content.showInterstitial) {
      props.openModal(propsObj)
    } else {
      props.openModal({
        type: 'interstitialScreen',
        modalProps: propsObj,
        thumbnailProps: {
          background: content.background
        }
      });
    }
  }

  const renderMobileEpisodes = () => {
    if(!content || !content.contents) return null;
    return (
      <div>
        {content.contents.map(item => {
          const cover = item.cover;
          return (
            <div key={item.identifier}  className="episode" onClick={() => playEpisode(item)}>
              <div className="episode-img-wrapper">
                <div className="img-video">
                  <img 
                    className="img-thumb"
                    loading="lazy"
                    style={{visibility:"hidden"}}
                    src={cover ? GetImageUrl(httpsUrl(cover.url), cover.sizes): ''}
                    onLoad={(e) => {e.target.style.visibility='visible'}} 
                  />
                </div>
              </div>
              <div className="episode-des">
                <p className="title">{item.title}</p>
                {item.subTitle && <p className="sub-title">{item.subTitle}</p>}
                {item.summary && <p className="summary">{item.summary}</p>}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderWebEpisodes = () => {
    const myContent = {...content};
    myContent.thumbOrientation = "landscape";
    return (
      <GridContent 
        key={content.identifier}
        nosaveicon={nosaveicon}
        content={myContent}
        contents={contents}
        feeds={feeds}
        layout={layout}
      />
    )
  }

  if(runningOnMobile) {
    return renderMobileEpisodes();
  } else {
    return renderWebEpisodes();
  }
}

const mapDispatchToProps = {
  openModal
}

export default connect(null, mapDispatchToProps)(SeriesContent);