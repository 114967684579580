import React from "react";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { openModal } from "../actions/modalActions";
import { validateDeviceOTP, deviceLogin, clearDeviceLogin } from "../actions/userActions"
import { withRouter } from "react-router";
import qs from "query-string";
import Loader from "./Loader";
import configuration from "../constants/config";

function DeviceCode(props) {
  const {feeds={}, location} = props;
  const {logo={}, primaryColor, secondaryColor} = feeds;
  const primary = primaryColor ? primaryColor.replace("#FF", "#") : "#FFFFFF";
  const secondary = secondaryColor ? secondaryColor.replace("#FF", "#") : "#FFFFFF";
  const query = qs.parse(location.search);

  const [otp, setOtp] = React.useState(query.code || "");
  const [msg, setMsg] = React.useState(null);
  const [deviceOtpErr, setDeviceOtpErr] = React.useState(null);

  const navBarVisibility = (isShow) => {
    const displayVal = isShow ? "flex": "none"
    const navbar = document.querySelector('#web-nav-bar') || document.querySelector('#web-nav-bar-mobile-wrapper');
    const backBtn = document.querySelector('a#main_back_id') || document.querySelector('a.backBtn-mobile');
    navbar && (navbar.style.display = displayVal)
    backBtn && (backBtn.style.display = displayVal)
  }

  React.useEffect(() => {
    navBarVisibility(false)
    props.clearDeviceLogin()
    return () => {
      navBarVisibility(true)
    }
  }, [])

  React.useEffect(() => {
    if (props.deviceOtpApi.success && !props.isLoggedIn) {
      props.openModal({type: "login", text:"Login to watch on your device"})
    }
    if (props.deviceOtpApi.success && props.isLoggedIn) {
      authorizeUser()
    }
    if (props.deviceOtpApi.error) {
      setDeviceOtpErr("Please enter correct code")
    }
  }, [props.deviceOtpApi])

  React.useEffect(() => {
    if (props.deviceAuthenticationApi.success) {
      setMsg("You can now watch content on the device")
    }
    if (props.deviceAuthenticationApi.error) {
      setMsg("Device login failed")
    }
  }, [props.deviceAuthenticationApi])

  React.useEffect(() => {
    if (props.deviceOtpApi.success && props.isLoggedIn) {
      authorizeUser()
    }
  }, [props.isLoggedIn])

  React.useEffect(() => {
    setDeviceOtpErr(null);
    if (otp.length === 6) verifyCode()
  }, [otp])

  const verifyCode = () => {
    const params = { code: otp, key: configuration.api_key }
    props.validateDeviceOTP(params)
  }

  const authorizeUser = () => {
    const params = {
      code: otp
    }
    props.deviceLogin(params)
  }

  const isLoading = props.deviceAuthenticationApi.pending || props.deviceOtpApi.pending;
  const showInput = msg ? false: true;

  return (
    <div className="otp-input-parent">
      {isLoading ? <Loader /> : null}
      
      <img src={logo.url} height="50px" style={{marginBottom: "40px"}}/>

      {showInput && <>
        <h2>Please enter the code here</h2>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          separator={<span>&nbsp;&nbsp;</span>}
          shouldAutoFocus
          inputStyle={{
            padding: 5,
            fontSize: 48,
            fontWeigth: 'bold'
          }}
          focusStyle={{
            outline: `2px solid ${secondary}`,
          }}
          isInputNum
        />
        { deviceOtpErr ? <div className="error-div">{deviceOtpErr}</div>: null }
        <button 
          className="submit-btn" 
          onClick={verifyCode} 
          style={{
            backgroundColor: `${primary}`,
          }}
          disabled={otp.length !== 6}
        >Submit</button>  
      </>}

      { msg ? <h2>{msg}</h2> : null }
    </div>
  );
}

const maspStateToProps = (state) => ({
  isLoggedIn: state.user.logged_in,
  deviceOtpApi: state.user.deviceOtp,
  deviceAuthenticationApi: state.user.deviceAuthentication
})

const mapDispatchToProps ={
  openModal,
  validateDeviceOTP,
  deviceLogin,
  clearDeviceLogin
}

export default connect(maspStateToProps, mapDispatchToProps)(withRouter(DeviceCode));
