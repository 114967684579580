import React from 'react'
import { connect } from "react-redux"
import { forgotPassword } from "../actions/userActions"
import { getTabIndex } from '../helperfunctions/common';
import ErrorDisplay from "./ErrorDisplay"

class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.resetPassword = this.resetPassword.bind(this)
        this.dismissAction = this.dismissAction.bind(this)
        this.state = {error: false, errorHeading: "", errorMsg: ""}
    }
    dismissAction(e){
        this.props.dismiss()
        if(e) {
            e.preventDefault();
        }
    }
    resetPassword(e){
        e.preventDefault();
        this.props.forgotPassword(this.props.email, this.props.app_id)
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.user_error && !nextProps.user.success){
            this.setState({error: true, errorHeading: "", errorMsg: nextProps.user.error})
        } else {
            this.setState({error: true, errorHeading: "Email Sent", errorMsg: "Please check your email for instructions on how to reset your password."})
        }
    }
    render(){
        document.body.className = "Reset"
        document.getElementsByClassName('logoHolder')[0].style.display = "none";
        if(this.state.error){
            return <ErrorDisplay dismiss={this.dismissAction} errorMsg={this.state.errorMsg} errorHeading={this.state.errorHeading} />
        } else {
            return (
                <div className="rePassword">
                    <div className="reContent">
                        <p>Do you want to reset your password?</p>
                        <a href="#" onClick={this.resetPassword} className="btn-reset focusable" tabIndex={getTabIndex()}>Reset</a><br/>
                        <a href="#" onClick={this.props.dismiss} className="focusable" tabIndex={getTabIndex()}>Cancel</a>
                    </div>
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => ({
    user_error: state.user.error,
    user: state.user.user
})

const mapDispatchToProps = {
    forgotPassword: forgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)