import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getSearchResult, clearSearch } from '../actions/searchActions'
import Background from '../components/Background'
import ThumbnailSearch from '../components/ThumbnailSearch'
import { environment } from "../constants/initializer"
import ReactGA from 'react-ga';
import configuration from '../constants/config';
import * as analytics from '../constants/analytics';
import Keyboard from 'react-simple-keyboard';
import { addRemoveAllNavigation, snFocusEventHandler } from '../helperfunctions/ctvHelper';
import { checkCorrectPlatform, getTabIndex, isSmartTv, showVirtualKeyboard } from '../helperfunctions/common';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import { PLATFORM_LG, PLATFORM_XBOX } from '../constants/common';

// let last_focus_osk = false;

const handlekeyDown = (e) => {
  if(!isSmartTv()) return;

  switch(e.keyCode) {
    case 13: // enter pressed
      document.querySelector('.search-box input').blur();
    break;
    case 65376: // done pressed
    case 65385: // cancel pressed
    case 10009: // samsung back press
    case 461: // lg back press
    case 198: // xbox back press
    case 196: // xbox back press
      JsSpatialNavigation.resume();
    break;
  }
}

const controlNavigation = (e, _type) => {
  if(!isSmartTv()) return;

  if(showVirtualKeyboard()) {
    try {
      let last_focus_osk = parseInt(document.getElementById('virtual_keyboard').getAttribute('data-last_focus_osk'));
      if(_type == 'pause' && !last_focus_osk) {
        setTimeout(() => {
          document.getElementById('virtual_keyboard').style.display = 'block';
          document.querySelector('.hg-row .focusable').focus();
          document.getElementById('virtual_keyboard').setAttribute('data-last_focus_osk', 1);
          // last_focus_osk = true;
        }, 500);
      } else if(_type == 'pause' && last_focus_osk) {
        document.getElementById('virtual_keyboard').style.display = 'none';
        document.getElementById('virtual_keyboard').setAttribute('data-last_focus_osk', 0);
        // last_focus_osk = false;
      }
    } catch(err) {
      console.log(err)
    }
    return;
  }

  if(_type == 'pause' && !checkCorrectPlatform([PLATFORM_XBOX])) {
    JsSpatialNavigation.pause();
  } else {
    JsSpatialNavigation.resume();
  }
}

const SearchScreen = (props) =>
  <div className="search-box">
    <input onKeyDown={handlekeyDown} onFocus={e => controlNavigation(e, 'pause')} onBlur={e => controlNavigation(e, 'resume')} className="focusable" tabIndex={getTabIndex()} type="text" onChange={props.update} id="search" value={props.search_value} placeholder="Search..." />
  </div>

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null
    this.state = { displayUserLogin: false, search_value: "", input_focus: '', layoutName: "default" }
    this.update = this.update.bind(this)
    if (this.props.isSearchCleared) {
      this.clearSearch();
    }
  }

  componentWillMount() {
    if(this.props.runningOnMobile) {
      document.body.className = "video-grid"
    } else {
      document.body.className = "grid"
    }
    const default_string = isSmartTv() ? "" : this.props.search_string || "";
    this.setState({ search_value: default_string });
  }

  componentDidMount() {
    if (window.location.pathname.includes('search/')) {
      this.props.history.replace(`/apps/${configuration.app_id}/search`);
    }
    const navbar = document.getElementsByClassName("nav-view")[0];
    navbar && navbar.parentNode.classList.remove('fix-nav');
    analytics.setPageViewFixedSections('Search')
    var navbarLinks = document.getElementsByClassName("headerLinks")
    for (var i = 0; i < navbarLinks.length; i++) {
      navbarLinks[i].classList.remove("active")
    }
    // document.getElementsByClassName("navbar-search")[0] && document.getElementsByClassName("navbar-search")[0].classList.add("active")

    if(configuration.is_simulator) {
      const navbar = document.getElementsByClassName("nav-view")[0];
      navbar && navbar.parentNode.classList.remove('fix-nav');
      var navbarLinks = document.getElementsByClassName("headerLinks")
      const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
      for(var i=0; i<navbarLinks.length; i++){
        navbarLinks[i].classList.remove("active")
        if(ottTabBarInactiveColor) {
          navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
        }
      }
      if (document.getElementsByClassName("navbar-search")[0]) {
        document.getElementsByClassName("navbar-search")[0].classList.add("active")
        if(ottTabBarActiveColor) {
          document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        }
      }
    }
    if(isSmartTv()) {
      this.clearSearch();

      if(configuration.use_light_sliders) {
        this.search_box_id = addRemoveAllNavigation({ selector: '.search-box .focusable'});
        this.search_result_id = addRemoveAllNavigation({ selector: '.search-row .focusable' });
        this.virtual_keyboard_id = addRemoveAllNavigation({ 
          selector: '#virtual_keyboard .focusable', 
          config: {
            leaveFor: { 
              'up': `@${this.search_box_id}`
            }
          }
        });

        JsSpatialNavigation.set('navbar', {
          leaveFor: {
            'down': `@${this.search_box_id}`
          }
        });        
      }

      if(checkCorrectPlatform([PLATFORM_LG])) {
        document.addEventListener('keyboardStateChange', this.keyboardStateChange, false);
      }

      document.querySelector('.search-section').addEventListener('sn:focused', snFocusEventHandler);
    }
    if(showVirtualKeyboard()) {
      document.getElementById('virtual_keyboard').style.display = 'none';
    }
  }

  componentWillUnmount() {
    if(isSmartTv()) {
      if(configuration.use_light_sliders) {
        // addRemoveAllNavigation({ id: this.id });
        addRemoveAllNavigation({ id: this.search_box_id });
        addRemoveAllNavigation({ id: this.search_result_id });
        addRemoveAllNavigation({ id: this.virtual_keyboard_id });

        JsSpatialNavigation.set('navbar', {
          leaveFor: {
            'down': '.content-box .focusable'
          }
        });        
      }

      if(checkCorrectPlatform([PLATFORM_LG])) {
        document.addEventListener('keyboardStateChange', this.keyboardStateChange, false);
      }
      JsSpatialNavigation.resume();

      document.querySelector('.search-section').removeEventListener('sn:focused', snFocusEventHandler);
    }
  }

  keyboardStateChange(event) {
    let visibility = event.detail.visibility;
    if(!visibility) {
      JsSpatialNavigation.resume();
    }
  }
  
  componentDidUpdate() {
    if (Object.keys(this.props.modal_progress).length > 0) {
      if (document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0]) {
        window.scrollTo(0, document.getElementsByClassName("image-content-" + this.props.modal_progress.item)[0].getBoundingClientRect().top + document.body.scrollTop - window.innerHeight / 2)
      }
      var elem = document.getElementsByClassName("searchContent hovered")[0]
      if (elem) {
        elem.classList.remove("hovered")
      }
      analytics.setPageViewFixedSections('Search')
      if(configuration.is_simulator) {
        const navbar = document.getElementsByClassName("nav-view")[0];
        navbar && navbar.parentNode.classList.remove('fix-nav');
        var navbarLinks = document.getElementsByClassName("headerLinks")
        const {ottTabBarInactiveColor, ottTabBarActiveColor} = this.props.feeds;
        for(var i=0; i<navbarLinks.length; i++){
          navbarLinks[i].classList.remove("active")
          if(ottTabBarInactiveColor) {
            navbarLinks[i].style.color = ottTabBarInactiveColor.replace("#FF", "#")
          }
        }
        // if (document.getElementsByClassName("navbar-search")[0]) {
        //   document.getElementsByClassName("navbar-search")[0].classList.add("active")
        //   if(ottTabBarActiveColor) {
        //     document.getElementsByClassName("navbar-search")[0].style.color = ottTabBarActiveColor.replace("#FF", "#")
        //   }
        // }
      }
    }
  }

  update(event) {
    //analytics.setEvent('Search', 'Search Query', '')
    var event_target = event.target.value;
    clearTimeout(this.timer);
    if (!event_target) {
      this.clearSearch()
    }
    else {
      this.timer = setTimeout(function () {
        this.props.getSearchResult(this.props.app_id, this.state.search_value)
      }.bind(this), 300)
    }
    this.setState({ search_value: event_target })
  }

  clearSearch = () => {
    this.props.clearSearch()
  }

  keyboardKeyPressed = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      const layoutName = this.state.layoutName;

      this.setState({
        layoutName: layoutName === "default" ? "shift" : "default"
      });
    }
  }

  onKeyboardChange = (input) => {
    // console.log("INPUT", input, this.keyboard.utilities.getCaretPosition());
    // this.keyboard.replaceInput(e.target.value);
    const event_target = input;

    if (!event_target) {
      this.clearSearch()
    }
    else {
      this.timer = setTimeout(function () {
        this.props.getSearchResult(this.props.app_id, this.state.search_value)
      }.bind(this), 300)
    }
    this.setState({ search_value: input });
  }

  addDataSnAttribute(type) {
    let last_btn_elms = document.getElementsByClassName(`${type}-buttons`);
    for(let i=0;i<last_btn_elms.length;i++) {
      last_btn_elms[i].setAttribute(`data-sn-${type}`,'');
    }
  }

  renderVirtualKeyboard() {
    if(!showVirtualKeyboard()) return null;

    this.layout = {
      'default': [
        '1 2 3 4 5 6 7 8 9 0',
        'q w e r t y u i o p',
        'a s d f g h j k l -',
        '{shift} z x c v b n m _',
        '.com @ {bksp} {space}'
      ],
      'shift': [
        '~ ! @ # $ % ^ & * ( ) _ +',
        'Q W E R T Y U I O P { } |',
        'A S D F G H J K L : "',
        '{shift} Z X C V B N M < > ?',
        '.com @ {bksp} {space}'
      ]
    };
    
    return (
      <div id="virtual_keyboard" data-last_focus_osk="0" style={{ width: '100%', margin: '0px auto', position: 'absolute', bottom: '0', height: 'auto', zIndex: 10 }}>
        <Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layout={this.layout}
          layoutName={this.state.layoutName}
          enableKeyNavigation={true}
          useMouseEvents={true}
          buttonTheme={[
            {
              class: 'hg-black focusable',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            },
            {
              class: 'down-buttons',
              buttons: `.com @ {bksp} {space}`
            },
            {
              class: 'left-buttons',
              buttons: `1 q a {shift} .com ~ Q A`
            },
            {
              class: 'right-buttons',
              buttons: `0 p - _ {space} + | " ?`
            }
          ]}
          buttonAttributes={[
            {
              attribute: 'tabindex',
              value: '-1',
              buttons: `${this.layout.default.join(' ')} ${this.layout.shift.join(' ')}`
            }
          ]}
          onKeyPress={btn => this.keyboardKeyPressed(btn)}
          onChange={this.onKeyboardChange}
          onRender={() => {
            this.addDataSnAttribute('down');
            this.addDataSnAttribute('left');
            this.addDataSnAttribute('right');
          }}
        />
      </div>
    );
  }

  render() {
    let contents = this.props.items.content || [];
    let search_res_string = 'Your search results will appear here.';
    if(isSmartTv()) {
      if(!this.props.isSearchPending && this.state.search_value && !contents.length) {
        search_res_string = 'No result found!';
      }
    }

    return (
      <div className="search-section">
          <Background background={this.props.background} />
          {configuration.is_simulator && <SearchScreen update={this.update} search_value={this.state.search_value} />}
          { this.props.isSearchPending &&
            <div id="searchLoader" style={{display: 'block', backgroundColor: 'transparent', zIndex: 100}} className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
            }
          { this.renderVirtualKeyboard() }
          { (contents.length > 0 && this.props.search_string.length > 0 )
            ? <div className="search-row">
              {contents.map(function(content, i) {
                return <ThumbnailSearch items={this.props.itemsReducer} locked={content.parentMenu.locked} feeds={this.props.feeds} key={i} content={content} head={1} primary={this.props.feeds.primaryColor} secondary={this.props.feeds.secondaryColor} all_contents={this.props.items.content}/>
              }, this)}
            </div>
            : (!this.props.isSearchPending 
            ? <div className="no-search-items"><span>{ search_res_string }</span></div>
              : null
            )
          }
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  items: state.search.search,
  search_string: state.search.search.search_string,
  modal_progress: state.modal.progress,
  isSearchCleared: state.search.isSearchCleared,
  isSearchPending: state.search.isSearchPending
})

const mapDispatchToProps = {
  getSearchResult: getSearchResult,
  clearSearch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
