export const truncateString = (string, length) =>{
	if (string.length > length){
		return (string.substring(0, length) + '...')
	} else{
		return string
	}
}

export const spotlightOrFullscreen = (layout) => {
	return ("fullscreen" === layout || "spotlight" === layout)
}