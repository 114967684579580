import React from 'react';
import { connect } from "react-redux"
import SaveIcon from "../components/SaveIcon";
import { getFontStyleFromFeed } from "../helperfunctions";
import TvFocusable from '../components/TvFocusable';
import { VIDEO_GRID_THEME_THEME } from '../constants/common';

class StandardTitle extends React.Component {

  clickEnter = (e) => {
    e.srcElement.childNodes[0].click();
  }

  render(){
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
		if(this.props.layout === "spotlight"){
			return null
		}
    return(
    <div className="titleHolder">
      <p className="title" style={{
        marginRight: '10px',
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        ...fontStyle.primaryFont
        }}>{this.props.content.title}</p>
      {(this.props.feeds.hasSavedSection || this.props.haveSavedMazIDFeed) &&
       this.props.layout !== "fullscreen" && this.props.layout !== VIDEO_GRID_THEME_THEME &&
      <SaveIcon 
        no_color={this.props.no_color} 
        locked={this.props.locked} 
        content={this.props.content} 
        type={this.props.type} 
        secondary={this.props.secondary} 
        feeds={this.props.feeds}
        index={this.props.index}
        total={this.props.total}
        layout={this.props.layout}
      />}
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  haveSavedMazIDFeed: state.items.haveSavedMazIDFeed,
})

export default connect(mapStateToProps, null)(StandardTitle);