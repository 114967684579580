import { combineReducers } from "redux"

import feeds from "./feedsReducer"
import user from "./userReducer"
import items from "./itemsReducer"
import search from "./searchReducer"
import remember from "./rememberSpotReducer"
import modal from "./modalReducer"
import ads from "./adReducer"
import subscription from "./subscriptionReducer";
import streams from "./streamReducer";

export default combineReducers({
    feeds,
    user,
    items,
    search,
    remember,
    modal,
    ads,
    subscription,
    streams
})