import React from 'react';
import { connect } from "react-redux";
import { managePlayAfterPurchase } from "../actions/subscriptionAction";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import crossIcon from '../images/failure.png';
import checkIcon from '../images/success.png';
import * as analytics  from '../constants/analytics';
import { isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';


function freeTrailLabel() {
    return {
        7: "with 7 days Free Trial",
        31: "with 1 month Free Trial",
        62: "with 2 months Free Trial",
        93: "with 3 months Free Trial"
    }
}

function SubDurationLabel(){
    return {
        31: `Monthly Subscription`,
        93: `3 Months Subscription`,
        186: `6 Months Subscription`, 
        366: `Annual Subscription`
    };
}

class PaymentMsg extends React.Component {
    constructor(props) {
        super(props);
        this.timeout=null;
        this.state = {
            showBacklink: false
        }
    }

    componentDidMount() {
        const self = this;
        this.hideBackIcon();
        const details = this.getPaymentDetails();
        this.timeout = setTimeout(function() {
            if(details.success) {
                self.props.managePlayAfterPurchase(true);
            }
            self.props.history.push(details.redirectSection);
            self.setState({showBacklink: true});
        }, 3000)
        this.sendPurchaseAnalytics();
    }

    showBackIcon = () => {
        if (!isSmartTv() && document.querySelector('.icon-hollow_arrow.backBtnSpan'))
            document.querySelector('.icon-hollow_arrow.backBtnSpan').style.display="block";
    }

    hideBackIcon = () => {
        if (document.querySelector('.icon-hollow_arrow.backBtnSpan'))
            document.querySelector('.icon-hollow_arrow.backBtnSpan').style.display="none";
    }

    componentWillUnmount() {
        this.showBackIcon();
        if (this.timeout)
            clearTimeout(this.timeout);
    }

    redirectBack = () => {
        const details = this.getPaymentDetails();
        this.props.history.push(details.redirectSection);
    }


    sendPurchaseAnalytics = () => {
        var paymentData = this.getPaymentDetails();
        if(paymentData.success === true){
            if(paymentData.purchaseType === 'sub'){
                var subscriptionType = SubDurationLabel()[paymentData.subDuration]
                analytics.setEvent('Subscriptions', 'Sub Purchase', subscriptionType);
            }else{
                analytics.setEvent('Purchases', 'Single Purchase', paymentData.title);
            }
        }
    }

    getPaymentDetails = () => {
        const query = queryString.parse(this.props.location.search);
        let details = {}
        details.success = query.type === 'success' ? true : false;
        details.price = query.price;
        let section = query.section;
        if(query.prev_purchase) {
            details.redirectSection = `${section}?prev_purchase=${query.prev_purchase}&sub_id=${query.sub_id}`;
        } else {
            details.redirectSection = section;
        }
        details.purchaseType = query.purchaseType;
        details.title = query.title === 'undefined' ? null : query.title;
        details.subDuration = query.duration === 'undefined' ? null: query.duration;
        details.freeTrial = query.ft === 'undefined' ? null : query.ft;
        return details;
    }

    render() {
        const subUIData = this.props.feeds.subscriptionUIMetadata;
        const tv_style = {};
        const backupColor = "#ddd6d6";
        if(isSmartTv()) {
            tv_style.display = "flex"
        }
        const backgndColor = (subUIData && subUIData.background.color && subUIData.background.color.replace("#FF","#")) || backupColor;
        const fontColor = this.props.feeds.loginUIMetadata.newSub && this.props.feeds.loginUIMetadata.newSub.colors.headerText;
        const purchseDetails = this.getPaymentDetails();
        const purTypeMsg = purchseDetails.purchaseType === 'sub' ? 'a subscription' : 'an item';
        if (!purchseDetails.success) {
            return (
                <div className="font-black payment-page" style={{backgroundColor: backgndColor, color: fontColor, ...tv_style}}>
                    {!isSmartTv() && <img className="failureImgIcon cnf-icon" src={crossIcon} alt="payment failure"/>}
                    {isSmartTv() && <div className="failureImgIcon cnf-icon"></div>}
                    <div className="pay-cnf-msg">Payment failed</div>
                    <div className='redirect-text'>You will be redirecting back in 3 seconds.</div>
                    {this.state.showBacklink && (<div className='redirect-bck' onClick={this.redirectBack}>Click here to redirect</div>)}
                </div>
            )
        }
        return (<div className="font-black payment-page" style={{backgroundColor: backgndColor, color: fontColor, ...tv_style}}>
            {!isSmartTv() && <img className="successImgIcon cnf-icon" src={checkIcon} alt="payment success"/>}
            {isSmartTv() && <div className="successImgIcon cnf-icon"></div>}
            <div className="pay-cnf-msg">Payment successful</div>
            {!configuration.isAppTvodType && <div className="pay-title">You have successfully purchased {purTypeMsg}.</div>}
            {
                purchseDetails.purchaseType === 'sub' ?
                (<div className="dur-price">
                    <div><b>Duration: </b>{SubDurationLabel()[purchseDetails.subDuration]}</div>
                    <div><b>Price: </b>${purchseDetails.price}</div>
                    {purchseDetails.freeTrial &&
                        <div>{freeTrailLabel()[purchseDetails.freeTrial]}</div>
                    }
                </div>)
                :
                (<div className="dur-price">
                    <div><b>Title: </b>{purchseDetails.title}</div>
                    <div><b>Price: </b>${purchseDetails.price}</div>
                </div>)
            }
            <div className='redirect-text'>You will be redirecting back in 3 seconds.</div>
            {this.state.showBacklink && (<div className='redirect-bck' onClick={this.redirectBack}>Click here to redirect</div>)}
        </div>)
    }
}

const mapDispatchToProps = {
    managePlayAfterPurchase
}
  
export default withRouter(connect(null, mapDispatchToProps)(PaymentMsg))