/* USER ACTION TYPES */
export const GET_USER_SUBSCRIPTION_RESOLVE = "GET_USER_SUBSCRIPTION_RESOLVE";
export const GET_SIMULATED_SUBSCRIPTION_TVOD = "GET_SIMULATED_SUBSCRIPTION_TVOD";
export const CLEAR_TVOD_SUBSCRIPTION_DATA = "CLEAR_TVOD_SUBSCRIPTION_DATA";
export const GET_USER_SUBSCRIPTION_REJECT = "GET_USER_SUBSCRIPTION_REJECT";
export const GET_USER_SUBSCRIPTION_PENDING = "GET_USER_SUBSCRIPTION_PENDING";
export const GET_USER_HISTORY_PROGRESS_RESOLVE = "GET_USER_HISTORY_PROGRESS_RESOLVE";
export const GET_USER_HISTORY_PROGRESS_REJECT = "GET_USER_HISTORY_PROGRESS_REJECT";
export const GET_USER_HISTORY_PROGRESS_PENDING = "GET_USER_HISTORY_PROGRESS_PENDING";
export const GET_USER_HISTORY_RESOLVE = "GET_USER_HISTORY_RESOLVE";
export const GET_USER_HISTORY_REJECT = "GET_USER_HISTORY_REJECT";
export const GET_USER_HISTORY_PENDING = "GET_USER_HISTORY_PENDING";
export const GET_WEBSITE_LOGIN_PENDING = "GET_WEBSITE_LOGIN_PENDING";
export const GET_WEBSITE_LOGIN_RESOLVED = "GET_WEBSITE_LOGIN_RESOLVED";
export const GET_WEBSITE_LOGIN_REJCTED = "GET_WEBSITE_LOGIN_REJCTED";
export const POLLING_START = "POLLING_START";
export const POLLING_STOP = "POLLING_STOP";
export const USER_ENGAGED = "USER_ENGAGED";
export const USER_CONCENT = "USER_CONCENT";
export const GET_FB_LOGIN_CODE_PENDING = "GET_FB_LOGIN_CODE_PENDING";
export const GET_FB_LOGIN_CODE_RESOLVED = "GET_FB_LOGIN_CODE_RESOLVED";
export const GET_FB_LOGIN_CODE_REJCTED = "GET_FB_LOGIN_CODE_REJCTED";
export const FB_POLLING_START = "FB_POLLING_START";
export const FB_POLLING_STOP = "FB_POLLING_STOP";
export const GET_FB_USER_DATA_PENDING = "GET_FB_USER_DATA_PENDING";
export const GET_FB_USER_DATA_RESOLVED = "GET_FB_USER_DATA_RESOLVED";
export const GET_FB_USER_DATA_REJECTED = "GET_FB_USER_DATA_REJECTED";
export const GET_USER_IP_PENDING = "GET_USER_IP_PENDING";
export const GET_USER_IP_RESOLVED = "GET_USER_IP_RESOLVED";
export const GET_USER_IP_REJECTED = "GET_USER_IP_REJECTED";
export const GET_USER_LOCATION_PENDING = "GET_USER_LOCATION_PENDING";
export const GET_USER_LOCATION_RESOLVED = "GET_USER_LOCATION_RESOLVED";
export const GET_USER_LOCATION_REJECTED = "GET_USER_LOCATION_REJECTED";
export const CHECKING_VALID_VOUCHER_CODE = "CHECKING_VALID_VOUCHER_CODE";
export const GET_VALID_VOUCHER_CODE = "GET_VALID_VOUCHER_CODE";
export const SET_ONBOARDING_COMPLETED = "SET_ONBOARDING_COMPLETED";

export const SET_CC_OPTION = 'SET_CC_OPTION';
export const SET_LOCAL_SUBTITLE_SETTING = 'SET_LOCAL_SUBTITLE_SETTING';

export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_RESOLVED = 'RESET_PASSWORD_RESOLVED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';

export const VALIDATE_DEVICE_OTP_PENDING = "VALIDATE_DEVICE_OTP_PENDING";
export const VALIDATE_DEVICE_OTP_RESOLVE = "VALIDATE_DEVICE_OTP_RESOLVE";
export const VALIDATE_DEVICE_OTP_REJECT = "VALIDATE_DEVICE_OTP_REJECT";
export const DEVICE_LOGIN_PENDING = "DEVICE_LOGIN_PENDING";
export const DEVICE_LOGIN_RESOLVE = "DEVICE_LOGIN_RESOLVE";
export const DEVICE_LOGIN_REJECT = "DEVICE_LOGIN_REJECT";
export const CLEAR_DEVICE_LOGIN = "CLEAR_DEVICE_LOGIN";

/* SUBSCRIPTION ACTION TYPES */
export const START_PURCHASE = "START_PURCHASE";
export const GET_SESSION_ID_PENDING = "GET_SESSION_ID_PENDING";
export const GET_SESSION_ID_RESOLVE = "GET_SESSION_ID_RESOLVE";
export const GET_SESSION_ID_REJECT = "GET_SESSION_ID_REJECT";
export const CLEAR_PENDING_PURCHASE = "CLEAR_PENDING_PURCHASE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const CANCEL_SUBSCRIPTION_PENDING = "CANCEL_SUBSCRIPTION_PENDING";
export const CANCEL_SUBSCRIPTION_RESOLVE = "CANCEL_SUBSCRIPTION_RESOLVE";
export const CANCEL_SUBSCRIPTION_REJECT = "CANCEL_SUBSCRIPTION_REJECT";
export const RENEW_SUBSCRIPTION_PENDING = "RENEW_SUBSCRIPTION_PENDING";
export const RENEW_SUBSCRIPTION_RESOLVE = "RENEW_SUBSCRIPTION_RESOLVE";
export const RENEW_SUBSCRIPTION_REJECT = "RENEW_SUBSCRIPTION_REJECT";
export const SUB_POLLING_START = "SUB_POLLING_START";
export const SUB_POLLING_STOP = "SUB_POLLING_STOP";
export const PLAY_AFTER_PURCHASE = "PLAY_AFTER_PURCHASE";

//  METRING ACTION TYPES
export const USER_METERING_RESOLVE = "USER_METERING_RESOLVE";
export const USER_METERING_REJECT = "USER_METERING_REJECT";
export const USER_METERING_PENDING = "USER_METERING_PENDING";

//Search
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const TEXT_SEARCH = "TEXT_SEARCH";
export const RECEIVE_SEARCH_ITEMS = "RECEIVE_SEARCH_ITEMS";

export const HAVE_MAZ_ID_SAVE_FEED = "HAVE_MAZ_ID_SAVE_FEED";


//CTV actions
export const GET_UUID_PENDING = "GET_UUID_PENDING";
export const GET_UUID_RESOLVED = "GET_UUID_RESOLVED";
export const GET_UUID_REJECTED = "GET_UUID_REJECTED";
export const GET_DEVICE_MODEL_PENDING = "GET_DEVICE_MODEL_PENDING";
export const GET_DEVICE_MODEL_RESOLVED= "GET_DEVICE_MODEL_RESOLVED";
export const GET_DEVICE_MODEL_REJECTED = "GET_DEVICE_MODEL_REJECTED";
export const GET_USER_ONLINE_RESOLVED = "GET_USER_ONLINE_RESOLVED";
export const GET_USER_ONLINE_REJECTED = "GET_USER_ONLINE_REJECTED";

//MAZ TV
export const LOGIN_MAZ_TV = "LOGIN_MAZ_TV"
export const LOGOUT_MAZ_TV = "LOGOUT_MAZ_TV"


export const ONE_FEED_PENDING = "ONE_FEED_PENDING"
export const ONE_FEED_RESOLVED = "ONE_FEED_RESOLVED"
export const ONE_FEED_REJECTED = "ONE_FEED_REJECTED"
export const FEED_POLICY_RESPONSE = "FEED_POLICY_RESPONSE"

//Geofencing
export const LOCATION_GEO_PENDING = "LOCATION_GEO_PENDING"
export const LOCATION_GEO_RESOLVED = "LOCATION_GEO_RESOLVED"
export const LOCATION_GEO_REJECTED = "LOCATION_GEO_REJECTED"


export const TVOD_STREAMING_ALLOWED = "TVOD_STREAMING_ALLOWED";
export const TVOD_STREAMING_MAX_OUT = "TVOD_STREAMING_MAX_OUT";
export const GET_TVOD_STREAMING_PENDING = "GET_TVOD_STREAMING_PENDING";
export const GET_TVOD_STREAMING_RESOLVED = "GET_TVOD_STREAMING_RESOLVED";
export const GET_TVOD_STREAMING_REJECTED = "GET_TVOD_STREAMING_REJECTED";
export const CLEAR_TVOD_STREAMING_DATA = "CLEAR_TVOD_STREAMING_DATA";