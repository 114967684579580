import React from 'react';
import LoaderPulse from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Logo from './Logo';
import QRCode from "qrcode.react";
import configuration from '../constants/config';
const FbIconImage = () => (
    <img alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iODAiIGhlaWdodD0iODAiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjM0Y1MUI1IiBkPSJNNDIsMzdjMCwyLjc2Mi0yLjIzOCw1LTUsNUgxMWMtMi43NjEsMC01LTIuMjM4LTUtNVYxMWMwLTIuNzYyLDIuMjM5LTUsNS01aDI2YzIuNzYyLDAsNSwyLjIzOCw1LDVWMzd6Ij48L3BhdGg+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTM0LjM2OCwyNUgzMXYxM2gtNVYyNWgtM3YtNGgzdi0yLjQxYzAuMDAyLTMuNTA4LDEuNDU5LTUuNTksNS41OTItNS41OUgzNXY0aC0yLjI4N0MzMS4xMDQsMTcsMzEsMTcuNiwzMSwxOC43MjNWMjFoNEwzNC4zNjgsMjV6Ij48L3BhdGg+PC9zdmc+"/>
)

function websiteLoginLoader(props) {
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    if (configuration.isAppTvodType) {
        return (<div className="loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <div className="loader-details">
            <div>1. On your phone or computer, go to:</div>
            <b>{props.loaderText}</b>
            <div>2. Enter the device code and login.</div>
            {props.code && <b>{props.code}</b>}
            <div className="text-center">
                <QRCode value={`${props.loaderText}?code=${props.code}`}/>
            </div>
        </div>
    </div>)
    } else {
        return (<div className="loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <div className="loader-details">
            <div>1. On your phone or computer, go to:</div>
            <b>{props.loaderText}</b>
            <div>2. Create an account or login on that device.</div>
            <div className="text-center">
                <QRCode value={props.loaderText}/>
            </div>
        </div>
    </div>)
    }
    
}

function subscriptioPaymentLoader(props) {
    const logoUrl = props.feeds.logo && props.feeds.logo.url
    return (
    <div className="payment-details-ctv loader-scrn">
        {logoUrl && <Logo logo={logoUrl}/>}
        <div className="loader-details">
            <b className="text-center">{props.loaderText}</b>
            <div className="text-center">OR</div>
            <div className="text-center">
                <QRCode value={props.loaderText}/>
            </div>
            <div>On your phone or computer, open the link or Scan the QR code</div>
            <div>To make the payment on that device.</div>
        </div>
    </div>
    )
}

function fbLoadinScreen(props) {
    return (<div className="fb-wait-loader loader-scrn">
        <div className="fb-loader-details">
            <span><FbIconImage /></span>
            <div className="fb-code">{props.loaderText}</div>
            <div className="fb-desc">Visit <b>facebook.com/device</b> on your smartphone or computer and enter the above code</div>
        </div>
    </div>)
}

function Loader(props) {
    if (props.loadingType == "websiteScreen") {
        return (websiteLoginLoader(props));
    }
    if (props.loadingType == "paymentLoader") {
        return (subscriptioPaymentLoader(props))
    }
    if (props.loadingType == "fbLoader") {
        return (fbLoadinScreen(props))
    }
    return (
        <div className="payment-loader">
            <LoaderPulse
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
            />
            <br />
            <div className="loader-text">{props.loaderText}</div>
        </div>
    )
}

export default Loader;