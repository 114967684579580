import React from 'react';
import ForwardIcon from '../icons/Forward';
import RewindIcon from '../icons/Rewind';
import PauseIcon from '../icons/PauseIcon';
import PlayIcon from '../icons/PlayIcon';
import { PLAYER_ICON } from '../../constants/common';
import { isSmartTv } from '../../helperfunctions/common';
import { keyMappings } from '../../helperfunctions/ctvHelper';

function PlayerIcon({videoId}) {
    const initState = {
        showForward: false,
        showRewind: false,
        showPlay: false,
        showPause: false,
        isVideoLoadingFirstTime: false
    }
    const [state, setState] = React.useState(initState);

    React.useEffect(() => {
        const player = document.getElementById(videoId);
        if (isSmartTv()) {
            setVideoLoadingState();
            document.addEventListener('keyup', handleRemoteClick);
        }

        return function cleanup() {
            document.removeEventListener('keyup', handleRemoteClick);
            player.removeEventListener('play', showPlay)
            player.removeEventListener('pause', showPause)
        };
    }, []);

    function handleRemoteClick(e) {
        if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
            return;
        }
        const isFocusOnControls = document.querySelector('#custom-control-bar') && document.querySelector('#custom-control-bar').contains(document.activeElement);
        const player = document.getElementById(videoId);
        const focus_on_nav = document.getElementById("nav-bar") ? document.getElementById("nav-bar").contains(document.activeElement) : false;
        if(focus_on_nav || !player) return;
        let current_time;
    
        switch(keyMappings(e.keyCode)) {
            case 'OK':
            // console.log('I am firing 13')
            // if(!checkCorrectPlatform([PLATFORM_SAMSUNG])) {
                if(player && player.paused) {
                // player.play();
                // console.log('I am firing 13 play')
                // showPlayPause(PLAYER_ICON.pause)
            } else {
                    // showPlayPause(PLAYER_ICON.play)
                // player && player.pause();
                // console.log('I am firing 13 pause')
                }
            // }
            break;
            
            case 'PLAY': //media play
            if(player && player.paused) {
                // showPlayPause(PLAYER_ICON.play)
                // player.play();
            }
            break;
    
            case 'PAUSE': //media pause
            if(player && !player.paused) {
                // showPlayPause(PLAYER_ICON.pause)
                // player.pause();
            }
            break;
    
            case 'PLAY-PAUSE': //media playpause
            if(player && player.paused) {
                // player.play();
                // showPlayPause(PLAYER_ICON.pause)
            } else {
                // showPlayPause(PLAYER_ICON.play)
                // player && player.pause();
            }
            break;
    
            case 'RIGHT': // right
                if (isFocusOnControls) {break;}
            case 'FORWARD': //forward
            // current_time = player.currentTime;
            // player.currentTime = current_time + 5;
            showRwdFwd(PLAYER_ICON.forward)
            break;
    
            case 'LEFT': // left
                if (isFocusOnControls) {break;}
            case 'REWIND': // backward
            // current_time = player.currentTime;
            // player.currentTime = current_time - 5;
            showRwdFwd(PLAYER_ICON.rewind)
            break;
    
            case 'STOP': // stop video
            if(player && !player.paused) {
                // player.pause();
                // player.currentTime = 0;
            }
            break;
        }
    }

    function showRwdFwd(type) {
        const iconType = type === PLAYER_ICON.forward ? 'showForward': 'showRewind';
        setState(prevstate => ({...prevstate, [iconType]: true}))
        setTimeout(() => {
            setState(prevstate => ({...prevstate, [iconType]: false}))
        }, 500)
    }
    
    function showPlayPause(type) {
        const iconType = type === PLAYER_ICON.play ? 'showPlay': 'showPause';
        setState(prevstate => ({...prevstate, [iconType]: true}))
        setTimeout(() => {
            setState(prevstate => ({...prevstate, [iconType]: false}))
        }, 500)
    }

    function showPlay() {
        showPlayPause(PLAYER_ICON.play)
    }

    function showPause() {
        showPlayPause(PLAYER_ICON.pause)
    }

    function setVideoLoadingState() {
        const player = document.getElementById(videoId);
        let setLoading = () => {
          setState(prevstate => ({...prevstate, isVideoLoadingFirstTime: true}))
        }
        let removeLoading = () => {
            setState(prevstate => ({...prevstate, isVideoLoadingFirstTime: false}))
          player.removeEventListener('waiting', setLoading)
          player.removeEventListener('playing', removeLoading)
        }
        player.addEventListener('waiting', setLoading)
        player.addEventListener('playing', removeLoading)
        player.addEventListener('play', showPlay)
        player.addEventListener('pause', showPause)
    }

    return (
        <>
            {state.showForward && !state.isVideoLoadingFirstTime && <ForwardIcon />}
            {state.showRewind && !state.isVideoLoadingFirstTime && <RewindIcon />}
            {state.showPause && !state.isVideoLoadingFirstTime && <PauseIcon />}
            {state.showPlay && !state.isVideoLoadingFirstTime && <PlayIcon />}
        </>
    )
}

export default PlayerIcon
