import React from 'react'
import ReactDOM from "react-dom"
import {connect} from "react-redux"
import DetailTitle from "./DetailTitle";
import { getFontStyleFromFeed, getSwiperParamsByTheme } from "../helperfunctions";
import { DETAIL_THEME } from '../constants/common';
import { isSmartTv } from '../helperfunctions/common';
import configuration from '../constants/config';
import { makeContentFocusableSection, removeContentFromFocusableSection, convertSwiperParamsToSlick } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';

class DetailContent extends React.Component {
  constructor(props){
    super(props);
    this.swiper = null
    this.state = {
      currentView: '',
    }
    this.focus_is_on = "nav";
    this.slideChangeTimeout = null;
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.onSlideChange = this.onSlideChange.bind(this);
  }

  handleRemoteClick = (e) => {
    const _self = this;
    if(configuration.use_light_sliders) {
      try {
        let elm = document.activeElement.parentElement.parentElement.parentElement;
        let index = elm.getAttribute('data-index');
        if(index >= 0 && this.props.detail_content[index]) {
          var summary = {
            title: this.props.detail_content[index].title,
            subtitle: this.props.detail_content[index].subtitle,
            description: this.props.detail_content[index].summary
          }
          ReactDOM.render(<DetailTitle haveSavedMazIDFeed={this.props.haveSavedMazIDFeed} secondary={this.props.secondary} summary={summary} type={this.props.detail_content[index].type} feeds={this.props.feeds} content={this.props.detail_content[index]} parent_content={this.props.content} />,
            document.getElementsByClassName('detailContent')[0])
        }
      } catch(er) {
        console.log("error",er);
      }
    } else {
      if (document.getElementById('detail-view-content') && document.getElementById('detail-view-content').contains(document.activeElement)) {
        document.getElementsByClassName("swiper-slide detail-swiper").forEach(e => e.classList.remove("focusedEle"));
        document.activeElement.parentElement.classList.add("focusedEle")
      }
      const foxusIndex = _self.getHoveredIndex(document.activeElement);
      _self.swiper.setTranslate(0);
      if (foxusIndex >= 0) {
        _self.setNextSlide(foxusIndex);
      }
    }
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier && !configuration.use_light_sliders){
      this.swiper && this.swiper.slideTo(this.props.modal_progress.index)
      if(document.getElementsByClassName("content-" + this.props.content.identifier)[0].getElementsByClassName("swiper-slide slideHover")[0]){
        document.getElementsByClassName("content-" + this.props.content.identifier)[0].getElementsByClassName("swiper-slide slideHover")[0].classList.remove("slideHover")
      }
      document.getElementsByClassName("item-" + this.props.modal_progress.item)[0].classList.add("slideHover")
    }
    if (!isSmartTv()) {
      this.setNextSlide();
    }
  }

  componentDidMount(){
    if (isSmartTv()) {
      document.addEventListener('keyup',this.handleRemoteClick);
    }
    var detailDiv = document.createElement('div');
    detailDiv.className = "detailContent";
    if(configuration.use_light_sliders) {
      document.getElementsByClassName("detail-view")[0].getElementsByClassName("slick-slider")[0].appendChild(detailDiv)
    } else {
      document.getElementsByClassName("detail-view")[0].getElementsByClassName("swiper-container")[0].appendChild(detailDiv)
    }
    var summary = {title: (this.props.detail_content[0].title ? this.props.detail_content[0].title : ""),
      subtitle: (this.props.detail_content[0].subtitle ? this.props.detail_content[0].subtitle : ""),
      description: (this.props.detail_content[0].summary ? this.props.detail_content[0].summary : "")}
    ReactDOM.render(<DetailTitle haveSavedMazIDFeed={this.props.haveSavedMazIDFeed} secondary={this.props.secondary} summary={summary} type={this.props.detail_content[0].type} feeds={this.props.feeds} content={this.props.detail_content[0]} parent_content={this.props.content} />,
      document.getElementsByClassName('detailContent')[0])

    if(isSmartTv()) {
      setTimeout(()=>{document.querySelector('html').scrollTop=0;}, 300)
    }
    document.getElementById("detail-view-content").addEventListener("mouseover", this.handleMouseOver , true)
    if(isSmartTv() && configuration.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        config: {
          rememberSource: true
        }
      }).then(val => this.sn_id = val)
      .catch(err => console.log("error",err))
    }
  }

  componentWillUnmount() {
    document.getElementById("detail-view-content").removeEventListener("mouseover", this.handleMouseOver , true)
    document.removeEventListener('keyup',this.handleRemoteClick);

    if(isSmartTv() && configuration.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }
  }

  handleMouseOver = (ele) => {
    if(!configuration.is_simulator) {
      const hoveredIndex = this.getHoveredIndex(ele.target);
      this.setNextSlide(hoveredIndex)
    }
  }

  getHoveredIndex(target) {
    if(!target) return undefined;
    let parents = document.getElementsByClassName("swiper-slide");
    if (isSmartTv()) {
      if(configuration.use_light_sliders) {
        parents = document.getElementsByClassName("slick-slide detail-swiper");
      } else {
        parents = document.getElementsByClassName("swiper-slide detail-swiper");
      }
    }
    if(parents && parents.length > 0) {
      for(let i = 0; i < parents.length; i++) {
        let classNames = target.classList;
        if(classNames.length === 0) {
          // for <p> inside tittle holder class
          classNames = target.parentNode.classList
          target = target.parentNode;
        }
        if(parents[i] === target || (classNames && classNames[0] && parents[i].querySelector(`.${classNames[0]}`) === target)) {
          return i;
        }
      }
    }
    return undefined;
  }

  onSlideChange = () => {
    if (isSmartTv()) return;
    if(configuration.is_simulator) {
      // simulator
      this.setNextSlide();
    } else {
      // web - for free scroll, timeout to stop flickering on fast scroll
      if(this.slideChangeTimeout) {
        clearTimeout(this.slideChangeTimeout)
        this.slideChangeTimeout = null;
      }
      this.slideChangeTimeout = setTimeout(() => {
        this.setNextSlide();
      }, 500)
    }
  }

  setNextSlide = (hoveredIndex) => {
    let index = Math.min.apply(null,[(this.props.detail_content.length-1),this.swiper.activeIndex])

    if(hoveredIndex >= 0) {
      // only in case of hover so no need to scroll
      index = hoveredIndex;
    } else if(configuration.is_simulator) {
      //Means swiper moves to last item. (when swiper moves to last, do not use slideTo but do setTranslate)
      //Below(5 lines code) is solution to safari bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/6440#issuecomment-698164686
      if (!isSmartTv()) {
        if (this.props.detail_content.length-1 === this.swiper.activeIndex) {
          this.swiper.setTranslate(-this.swiper.slidesGrid[index]);
        } else {
          this.swiper.slideTo(index)
        }
      }
    }
    if(index >= 0 && this.props.detail_content[index]) {
      var summary = {
        title: this.props.detail_content[index].title,
        subtitle: this.props.detail_content[index].subtitle,
        description: this.props.detail_content[index].summary
      }
      ReactDOM.render(<DetailTitle haveSavedMazIDFeed={this.props.haveSavedMazIDFeed} secondary={this.props.secondary} summary={summary} type={this.props.detail_content[index].type} feeds={this.props.feeds} content={this.props.detail_content[index]} parent_content={this.props.content} />,
        document.getElementsByClassName('detailContent')[0])
    }
  }

  componentWillMount(){
    window.scrollTo(0,0);
  }

  render() {
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    // We are initializiing swiper in Swiper Params.
    const params = getSwiperParamsByTheme(this, this.props.content, DETAIL_THEME, this.onSlideChange)
    let detailClassName;
    if(this.props.content.thumbOrientation === "portrait"){
      detailClassName = "slider-box detail-view portrait-view content-" + this.props.content.identifier
    } else {
      detailClassName = "slider-box detail-view content-" + this.props.content.identifier
    }
    var contents = this.props.contents
    let slick_params = null;
    if(isSmartTv()) {
      delete params.keyboard;
      if(configuration.use_light_sliders) {
        // slick_params = convertSwiperParamsToSlick(params, this);
        slick_params = {
          dots: false,
          infinite: false,
          autoplay: false,
          cssEase: "linear",
          arrows: false,
          speed: 250,
          focusOnSelect: false,
          lazyLoad: 'progressive',
          useCSS: false,
          vertical: true,
          verticalSwiping: true,
          centerMode: true,
          slidesToShow: 1,
          accessibility: false,
          disable_keyboard_events: true
        };
      }
    }

    return (
      <div className="detail-view-cls" id="detail-view-content">
        <div className={detailClassName}>
          {!this.props.showingSavedSection && <h2 id={`content-head-${this.props.content.identifier}`} style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>}
          {/* <Swiper {...params }>{contents}</Swiper> */}
          <CustomSlider
            params = { slick_params || params }
            content_identifier = {this.props.content.identifier}
          >
            { contents }
          </CustomSlider>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
  haveSavedMazIDFeed: state.items.haveSavedMazIDFeed
})

export default connect(mapStateToProps, null)(DetailContent);
