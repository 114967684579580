import React from 'react';
import Swiper from 'react-id-swiper';

import { PLATFORM_VIZIO, PLATFORM_XBOX } from '../constants/common';
import configuration from '../constants/config';
import { getLogoFromLoginOrFeed } from '../helperfunctions';
import { checkCorrectPlatform, checkLoginUIMetaData, getTabIndex, isSmartTv } from '../helperfunctions/common';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import LoginFlow from './LoginFlow';

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      showLogin: false,
      emailSelected: false,
      ctvPolicy: null,
    }
    this.swiperRef = React.createRef();
    this.handleRemoteClick = this.handleRemoteClick.bind(this)
  }
  componentDidMount() {
    if (isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
      this.id = addRemoveAllNavigation({ selector: '.onboarding-screen .focusable' });
    }
    this.setBulletsColors();
  }
  componentWillUnmount() {
    if (isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);
      addRemoveAllNavigation({ id: this.id });
    }
  }
  handleNextClick(isLastScreen) {
    if(isLastScreen) {
      const action = this.props.onboardingMetadata.action;
      if(action && action.toLocaleLowerCase() === 'register') {
        this.setState({showLogin: true});
      } else {
        this.onboardingCompleted()
      }
    } else if(this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
      this.swiperRef.current.swiper.slideNext();
    }
  }
  onboardingCompleted() {
    this.props.onboardingCompleted();
  }
  updateEmailSelected() {
    this.setState({emailSelected: true})
  }

  showCTVPolicyModal = (type, url) => {
    this.setState({ctvPolicy: {url, type}});
  }

  getImageUrl = (url, sizes) => {
    if(!sizes || sizes.length < 1) return url;
    var newUrl = url.split(".")
    newUrl[newUrl.length - 2] = newUrl[newUrl.length - 2] + "-" + sizes[0];
    newUrl = newUrl.join(".");
    return newUrl
  }

  handleRemoteClick(e) {
    const back_key_codes = {
      '10009': true, // lg
      '461': true,    // samsung
      '8': checkCorrectPlatform([PLATFORM_VIZIO]) // vizio
    };
    if(checkCorrectPlatform([PLATFORM_XBOX])) {
      back_key_codes['198'] = true;
      back_key_codes['196'] = true;
    }
    if (back_key_codes[e.keyCode]) {
      this.setState({ctvPolicy: null});
    }
  }

  setBulletsColors() {
    const colors = this.props.onboardingMetadata.colors;
    if(!(colors && colors.pageControlSelectedColor && colors.pageControlUnselectedColor)) return;

    let all = document.getElementsByClassName('swiper-pagination-bullet');
    for (var i = 0; i < all.length; i++) {
      all[i].style.background = colors.pageControlUnselectedColor;
    }
    let allActive = document.getElementsByClassName('swiper-pagination-bullet-active');
    for (var i = 0; i < allActive.length; i++) {
      allActive[i].style.background = colors.pageControlSelectedColor;
    }
  }

  renderLogin() {
    const headerLogo = getLogoFromLoginOrFeed(this.props.feeds, "registrationWall");
    const allProps = {
      feeds: checkLoginUIMetaData(this.props.feeds),
      app_id: configuration.app_id,
      background: (this.props.feeds.loginUIMetadata.colors && this.props.feeds.loginUIMetadata.colors.background) || null,
      titleMsg: "PLEASE LOGIN TO CONTINUE",
      directLogin: true
    }
    return (
      <div className={(this.props.runningOnMobile ? "web-route-container web-route-container-mobile" : "")}>
        <div className="scroll-auto-h-100 onboarding-screen login-page">
          {this.state.ctvPolicy
            ? <div className="ctv-poilcy">
                <h3>{ this.state.ctvPolicy.type }</h3>
                <p>
                  &nbsp; To read, please visit: &nbsp; {this.state.ctvPolicy.url }
                </p>
              </div>
            : <LoginFlow
                emailSelected={this.state.emailSelected}
                headerLogo={headerLogo}
                selectEmail={() => this.updateEmailSelected()}
                selectBack={this.goBack}
                allProps={allProps}
                runningOnMobile={this.props.runningOnMobile}
                pushToOnboardingScreen={(type, url) => this.showCTVPolicyModal(type, url)}
              />
          }
        </div>
      </div>
    )
  }

  render () {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 0,
      on: {
        slideChange: () => {
          if(this.swiperRef && this.swiperRef.current && this.swiperRef.current.swiper) {
            this.setState({activeIndex: this.swiperRef.current.swiper.activeIndex});
          }
          this.setBulletsColors();
        }
      }
    }
    const { screens =[], allowSkip, buttonTitle, background, colors } = this.props.onboardingMetadata;
    if(screens.length === 0 || this.state.showLogin) {
      return this.renderLogin();
    }
    const buttonStyle = {color: colors.btnTitleColor || "white", backgroundColor: colors.btnColor || "blue"};
    const isLastScreen = this.state.activeIndex + 1 === screens.length;
    return(
      <div className={"onboarding-screen" + (this.props.runningOnMobile ? " mobile-view" : "")}>
        <div className="first-layer">
          {background && background.imageUrl && <img className="big-image" src={background.imageUrl}/>}
        </div>
        <div className="second-layer">
          <Swiper {...params} ref={this.swiperRef}>
            {screens.map(screen => {
              return (
                <div key={screen.title}>
                  <div className="screen-item">
                    {screen.image && screen.image.url && <img className="small-image" src={this.getImageUrl(screen.image.url, screen.image.sizes)}/>}
                    <p className="title" style={{color: colors.titleColor || "white"}}>{screen.title}</p>
                    <p className="summary" style={{color: colors.descriptionColor || "white"}}>{screen.summary}</p>
                  </div>
                </div>
              )
            })}
          </Swiper>
        </div>
        <button className="next-btn focusable" style={buttonStyle} tabIndex={getTabIndex()} onClick={() => this.handleNextClick(isLastScreen)}>{isLastScreen ? buttonTitle : "Next"}</button>
        {allowSkip && <span className="skip-btn focusable" tabIndex={getTabIndex()} onClick={() => this.onboardingCompleted()}>Skip</span>}
      </div>
    )
  }
}

export default OnBoarding;