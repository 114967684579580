import { DETAIL_THEME, GRID_THEME, JUMBO_THEME, SPOTLIGHT_THEME } from '../constants/common';
import config from '../constants/config';
import * as webSwiperParams from './webSwiperParams'

export function getSwiperParamsByTheme(_self, content, themeType, nextSlideCB) {
  if (!config.is_simulator) {
    return webSwiperParams.getWebSwiperParamsByTheme(_self, content, themeType, nextSlideCB)
  }
  if (themeType === GRID_THEME) {
    return getGridThemeParams(_self, content)
  }
  if (themeType === SPOTLIGHT_THEME) {
    //Passing props in case of spotlight content, instead of section.content
    return swiperParam(content, _self)
  }
  if (themeType === JUMBO_THEME) {
    return getJumboThemeParams(_self, content)
  }
  if (themeType === DETAIL_THEME) {
    return getDetailThemeParams(_self, content, nextSlideCB)
  }
}

export function getBannerSwiperParams(initialSlide, runningOnMobile) {
  if (!config.is_simulator) {
    if(runningOnMobile) {
      return webSwiperParams.getMobileBannerSwiperParams(initialSlide)
    }
    return webSwiperParams.getWebBannerSwiperParams(initialSlide)
  }
    return {
      initialSlide: initialSlide,
      slidesPerView: 1.2,
      loop: true,
      spaceBetween: 40,
      autoplay: {
        delay: 4000
      },
      loopedSlides: 3,
      mousewheelControl: true,
      mousewheelInvert: true,
      centeredSlides: true,
      mousewheelForceToAxis: true,
      breakpoints: {
        1920: {
          slidesPerView: 1.18,
        },
        768: {
          slidesPerView: 1.32,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 1.4,
          spaceBetween: 30
        }
      },
      mousewheel: {
       forceToAxis: true,
       invert: true
     }
    }
}

export const swiperParam = (props, self) => {
  if (props.content && props.section && props.section.thumbOrientation === "portrait") {
    var params = {
      mousewheelControl: true,
      observer: true,
      observeParents: true,
      slidesPerView: 7.6,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      breakpoints: {
        ...portraitBreakpoints(props.section, props.index)
      },
      onInit: (swiper) => {
        self.swiper = swiper
      },
      mousewheel: {
        forceToAxis: true,
        invert: true
     }
    }
    if(props.nosaveicon){
      var gridClassName = "spotlight nothumbBox slider-box grid-view portrait-view content-" + props.content.identifier
    } else {
      var gridClassName = "spotlight slider-box grid-view portrait-view content-" + props.content.identifier
    }

  } else {
    var params = {
      mousewheelControl: true,
      observer: true,
      observeParents: true,
      slidesPerView: 3.6,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      freeMode: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      breakpoints: {
        ...landscapeBreakpoints(props.section, props.index)
      },
      onInit: (swiper) => {
        self.swiper = swiper
      },
      mousewheel: {
        invert: true,
        forceToAxis: true,
      }
    }
    if(props.nosaveicon){
      var gridClassName = "spotlight nothumbBox slider-box grid-view content-" + props.content.identifier
    } else {
      var gridClassName = "spotlight slider-box grid-view content-" + props.content.identifier
    }
  }


  return {gridClassName: gridClassName, params: params}
}

function landscapeBreakpoints(section, index) {
  if (section && section.regularLayout == 'fullscreen') {
    if (index == 0) {
      /** Breakpoints for fullscreen first Row */
      return {
        // 3840 only if screen is greater than 1920
        3840: {
          slidesPerView: 5.8,
          spaceBetween: 28
        },
        1920: {
          slidesPerView: 5.5,
          spaceBetween: 28
        },
        1600: {
          slidesPerView: 5.1,
          spaceBetween: 28
        },
        1280: {
          slidesPerView: 5.1,
          spaceBetween: 28
        },
        1024: {
          slidesPerView: 4.8,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 4.3,
          spaceBetween: 15
        },
      }
    }
    /**  Breakpoints for fullscreen other RowS*/
    return {
      // 3840 only if screen is greater than 1920
      3840: {
        slidesPerView: 4.3,
        spaceBetween: 28
      },
      1920: {
        slidesPerView: 3.9,
        spaceBetween: 28
      },
      1600: {
        slidesPerView: 3.6,
        spaceBetween: 28
      },
      1280: {
        slidesPerView: 3.6,
        spaceBetween: 28
      },
      1024: {
        slidesPerView: 3.4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 2.9,
        spaceBetween: 15
      },
    }
  }
  /**  Breakpoints for Spotlight Rows*/
  return {
    1600: {
      slidesPerView: 3.4,
      spaceBetween: 28
    },
    1280: {
      slidesPerView: 4.1,
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 2.8,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 2.3,
      spaceBetween: 15
    },
  }
}

 
function portraitBreakpoints(section, index) {
  if (section && section.regularLayout == 'fullscreen') {
    if (index == 0) {
      /** Breakpoints for fullscreen first Row */
      return {
        // 3840 only if screen is greater than 1920
        3840: {
          slidesPerView: 6.8,
          spaceBetween: 28
        },
        1920: {
          slidesPerView: 6.5,
          spaceBetween: 28
        },
        1600: {
          slidesPerView: 6.1,
          spaceBetween: 28
        },
        1280: {
          slidesPerView: 6.1,
          spaceBetween: 28
        },
        1024: {
          slidesPerView: 5.8,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 5.3,
          spaceBetween: 15
        },
      }
    }
    /**  Breakpoints for fullscreen other RowS*/
    return {
      // 3840 only if screen is greater than 1920
      3840: {
        slidesPerView: 5.3,
        spaceBetween: 28
      },
      1920: {
        slidesPerView: 4.9,
        spaceBetween: 28
      },
      1600: {
        slidesPerView: 4.6,
        spaceBetween: 28
      },
      1280: {
        slidesPerView: 4.6,
        spaceBetween: 28
      },
      1024: {
        slidesPerView: 4.3,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3.9,
        spaceBetween: 15
      },
    }
  }
  /** Default breakpoint for spotlight theme (If it has portrait thumb orientation) */
  return {
    1400: {
      slidesPerView: 4.5,
      spaceBetween: 30
    },
  }
};

function getGridThemeParams(_self, section) {
  const commonParams = {
    mousewheelControl: false,
    spaceBetween: 40,
    mousewheelForceToAxis: true,
    mousewheelInvert: true,
    preloadImages: false,
    slidesOffsetAfter: 30,
    lazyLoading: true,
    freeMode: true,
    observer: true,
    observeParents: true,
    onInit: (swiper) => {
      _self.swiper = swiper
    },
    mousewheel: {
     forceToAxis: true,
     invert: true,
    }
  }
  if (section && section.thumbOrientation === "portrait") {
    return {
      ...commonParams,
      slidesPerView: 4.5,
      breakpoints: {
        1920: {
          slidesPerView: 4.5
        },
        1366: {
          slidesOffsetAfter: 0
        },
        768: {
          slidesPerView: 3.3,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 2.1,
          spaceBetween: 30
        }
      }
    }
  } else {
    return {
      ...commonParams,
      slidesPerView: 3.1,
      breakpoints: {
        1920: {
          slidesPerView: 3.1
        },
        1366: {
          slidesOffsetAfter: 0
        },
        768: {
          slidesPerView: 2.2,
          spaceBetween: 30
        },
        520: {
          slidesPerView: 1.2,
          spaceBetween: 30
        }
      }
    }
  }
}

function getJumboThemeParams(_self, section) {
  if(section && section.thumbOrientation === "portrait"){
    return {
      mousewheelControl: true,
      slidesPerView: 3.2,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        1920: {
          slidesPerView: 3.2,
          spaceBetween: 30
        },
        1600: {
          slidesPerView: 3.01,
        },
        768: {
          slidesPerView: 2.1,
          spaceBetween: 25
        }
      },
      onInit: (swiper) => {
        _self.swiper = swiper
      },
      mousewheel: {
       forceToAxis: true,
       invert: true,
     }
    }
  } else {
    return {
      mousewheelControl: true,
      slidesPerView: 2.01,
      spaceBetween: 40,
      mousewheelForceToAxis: true,
      mousewheelInvert: true,
      preloadImages: false,
      lazyLoading: true,
      freeMode: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        1920: {
          slidesPerView: 2.01,
          spaceBetween: 35
        },
        768: {
          slidesPerView: 1.3,
          spaceBetween: 30
        }
      },
      onInit: (swiper) => {
        _self.swiper = swiper
      },
      mousewheel: {
       forceToAxis: true,
       invert: true,
     }
    }
  }
}

function getDetailThemeParams(_self, content, nextSlideCB) {
  const commonParams = {
    mousewheel: true,
    mousewheelControl: true,
    direction: 'vertical',
    resistance: false,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    slidesPerView: 'auto',
    preloadImages: false,
    freeModeMomentum: false,
    touchReleaseOnEdges: true,
    observer: true,
    observeParents: true,
    // lazyLoadingOnTransitionStart: true,
    slidesOffsetAfter: 400,
    on:{
      init: () => {
        _self.swiper =  document.querySelector('.swiper-container-vertical').swiper
      },
      transitionEnd: () => {
        nextSlideCB()
      }
    },
    mousewheel: {
      forceToAxis: true,
      invert: true,
    },
    height: window.innerHeight,

  }
  if(content.thumbOrientation === "portrait"){
    return {
      ...commonParams,
      spaceBetween: 48,
      breakpoints: {
        1400: {
          spaceBetween:35
        }
      }
    }
  } else {
    return {
      ...commonParams,
      spaceBetween: 50,
      breakpoints: {
        1400: {
          spaceBetween:30
        }
      }
    }
  }
}

