import { connect } from "react-redux"
import React from 'react';


import store from './../store.js';
import configuration from "../constants/config.js";
import { getDateTimeForTvodInterstitial, getDateWithoutHyphen } from "./common.js";

function isAvailable(props,special=false, register_wall=false, content=null){
    // locked content is normally locked sub
    // if it is special then it will locked by only iap or registerwall
    var newState = store.getState();
    if(configuration.isAppTvodType) {
      return isAvailableForTvod(newState.user.tvod_purchased, content)
    }
    let iap_lock = special
    let sub_lock = true
    let responses = []

    if (content){
      iap_lock = content.access.iap || false
      special = content.special
      register_wall =  (content.registerWall && register_wall)
    }
    if(special){
      sub_lock = false
    }
    if(newState.user && newState.user.validVoucherCode) {
      // Got voucher code to unlock everything
      return true;
    }

    if (!register_wall && !iap_lock && !sub_lock){
      return true
    }
    if(register_wall){
      responses.push(newState.user.logged_in)
    }
    if (iap_lock){
      responses.push(newState.user.items_purchased.includes(props))
    }
    if (sub_lock) {
      responses.push(newState.user.subscription || newState.user.items_purchased.includes(props))
    }
    let response = responses.filter(function(value) {return value})
    return response[0] || false
}

function isAvailableForTvod(tvod_purchased, content) {
  if(content && content.access && content.access.bundles && content.access.bundles.length > 0) {
    let list = {};
    for(let i=0; i < content.access.bundles.length; i++) {
      const bundle = content.access.bundles[i];
      const purchased = tvod_purchased[bundle.bundleToken];
      if(purchased && purchased.isActive) {
        if(bundle.type === "purchases") {
          list[bundle.type] = true;
        } else if(bundle.type === "events" && content.access.endsAt && content.access.startsAt) {
          list[bundle.type] = content.access.endsAt;
        } else if((bundle.type === "subscriptions" || bundle.type === "rentals") && (getDateWithoutHyphen(purchased.endsAt) > new Date())) {
          list[bundle.type] = getDateWithoutHyphen(purchased.endsAt);
        }
      }
    }
    if(list["purchases"]) {
      return {
        type: "purchases",
        badge: "You own this item"
      }
    } else if(list["subscriptions"]) {
      return {
        type: "subscriptions",
        badge: "Included with your subscription",
        endsAt: list["subscriptions"]
      }
    } else if(list["rentals"]) {
      return {
        type: "rentals",
        badge: `Your rental expires ${getDateTimeForTvodInterstitial(list["rentals"])}`,
        endsAt: list["rentals"]
      }
    } else if(list["events"]) {
      return {
        type: "events",
        badge: "You have a ticket to this event",
        endsAt: list["events"],
        startsAt: getDateWithoutHyphen(content.access.startsAt),
      }
    } else {
      return false;
    }
  }
  if(content && content.access && content.access.endsAt && content.access.startsAt) {
    // Free event , no badge
    return {
      type: "events",
      endsAt: getDateWithoutHyphen(content.access.endsAt),
      startsAt: getDateWithoutHyphen(content.access.startsAt),
    }
  }
  return true;
}

store.subscribe(isAvailable)



export default isAvailable;

/**
 * Function will check in all subscription that user is subscribed or not.
 * @param {*} allSubscription
 */
export function isUserSubscribed(allSubscription) {
  let isSubscribed = false;
  let sub_platform = "web_as_output";
  allSubscription.forEach(subs => {
    if (subs.type == 'subscription') {
      const currDate = new Date();
      //Adding 1 minute in currDate, So don't show wrong data in subscription page. when subscription get cancelled.
      currDate.setMinutes(currDate.getMinutes() + 1);
      const subEndDate = new Date(subs.end_date);
      if (subEndDate > currDate) {
        isSubscribed = true;
        sub_platform = subs.platform
      }
    }
  });
  return [isSubscribed,sub_platform];
}
