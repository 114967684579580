import React from 'react';
import VolumeMute from './VolumeMute';
import VolumeUp from './VolumeUp';
import VolumeDown from './VolumeDown';
import PlayIcon from './PlayIcon';
import PauseIcon from './PauseIcon';
import { checkCorrectPlatform, getTabIndex } from '../../helperfunctions/common';
import Rewind from './Rewind';
import Forward from './Forward';
import { PLATFORM_VIZIO, PLAYER_ICON } from '../../constants/common';
import JsSpatialNavigation from '../../ctvnavigation/js-spatial-navigation';
import { keyMappings } from '../../helperfunctions/ctvHelper';

function toHHMMSS(sec_num) {
    // let sec_num = parseInt(sec_num, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
};

function PlayerProgressBar({videoId, isLive, ctv_focus_on}) {
    const initState = {
        showForward: false,
        showRewind: false,
    }
    const player = document.getElementById(videoId);
    if(!player) return null;
    const [state, setState] = React.useState(initState);
    const [currTime, setCurrTime] = React.useState(toHHMMSS(0))
    const [totalTime, setTotalTime] = React.useState(toHHMMSS(0))
    const [muted, setMuted] = React.useState(player.muted);
    const [volume, setVolume] = React.useState(getVolumeInPercetaage());
    const [isPaused, setIsPaused] = React.useState(player.paused);
    let timeout;
    
    React.useEffect(() => {
        setVideoEventHandler();
        document.addEventListener('keyup', handleRemoteClick);

        return function cleanup() {
            document.removeEventListener('keyup', handleRemoteClick);
            
            player.ontimeupdate = () => {}
            timeout && clearTimeout(timeout);
        };
    }, []);

    function setVideoEventHandler() {

        player.onloadedmetadata = function() {
            setTotalDur();
            timeout = setTimeout(() => {showProgressBar()}, 4000);
        }
        player.ontimeupdate = function(){
            let currTime = player.currentTime.toFixed();
            setCurrTime(toHHMMSS(currTime))
            isLive && setTotalDur();
            let percentage = ( player.currentTime / player.duration ) * 100;
            if (document.querySelector("#custom-seekbar span.seek")) {
                document.querySelector("#custom-seekbar span.seek").style.width = percentage+"%";
            }
        };
        player.onvolumechange = function() {
            setMuted(player.muted);
            setVolume(getVolumeInPercetaage());
            console.log('volume change', player.volume, player.muted)
        }
        player.onplay = function() {
            setIsPaused(false)
        }
        player.onpause = function() {
            setIsPaused(true)
        }
    }

    function setTotalDur() {
        const dur = player.duration.toFixed() || 0;
        if(isNaN(dur)) {
            setTotalTime("00:00:00");
        } else {
            setTotalTime(toHHMMSS(dur));
        }
    }

    function handleRemoteClick(e) {
        e.preventDefault()
        if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
            return;
        }

        const focus_on_nav = document.getElementById("nav-bar") ? document.getElementById("nav-bar").contains(document.activeElement) : false;
        const progressBar = document.querySelector('#custom-control-bar');

        if(focus_on_nav || !player || !progressBar) return;

        switch(keyMappings(e.keyCode)) {
            case 'OK':
            case 'DOWN': //down
            case 'PLAY-PAUSE': //media playpause
                showProgressBar();
            break;

            case 'LEFT':
            case 'RIGHT':
                showProgressBar();
            break;

            case 'UP': // up
                // if(progressBar && progressBar.style.visibility == 'visible') {
                //     progressBar.style.visibility = 'hidden';
                // } else if(progressBar && progressBar.style.visibility == 'hidden') {
                //     if(document.activeElement.id == 'inline_arrow') {
                //         document.getElementById('inline_arrow').blur();
                //     } else {
                //         // showProgressBar();
                //     }
                // }
            break;

            case 'PLAY': //media play
            case 'PAUSE': //media pause
            case 'STOP': // stop video
                if(player && player.paused) {
                    showProgressBar();
                }
            break;
        }
    }

    function showProgressBar() {
        let flag = false
        const progressBar = document.querySelector('#custom-control-bar');
        if (progressBar) {
            if(progressBar.style.visibility == 'hidden') {
                flag = true;
            }
            progressBar.style.visibility = 'visible';
            timeout && clearTimeout(timeout);
            timeout = setTimeout(() => {
                progressBar.style.visibility = 'hidden';
            }, 5000)
            if(flag) {
                JsSpatialNavigation.focus('.play-pause');
            }
        }
    }

    function getVolumeInPercetaage() {
        return Math.floor(player.volume * 10)*10;
    }

    function onPlayPause() {
        if (player.paused || player.ended) player.play();
        else player.pause();
    }

    function onMute() {
        player.muted = !player.muted;
    }

    function onVolumeUp() {
        player.muted = false;
        let currentVolume = Math.floor(player.volume * 10) / 10;
        if (currentVolume < 1) player.volume += 0.1;
    }

    function onVolumeDown() {
        player.muted = false;
        let currentVolume = Math.floor(player.volume * 10) / 10;
        if (currentVolume > 0) player.volume -= 0.1;
    }

    function onForward() {
        let current_time = player.currentTime;
        player.currentTime = current_time + 5;
        showRwdFwd(PLAYER_ICON.forward)
    }

    function onBackward() {
        let current_time = player.currentTime;
        player.currentTime = current_time - 5;
        showRwdFwd(PLAYER_ICON.rewind)
    }

    function showRwdFwd(type) {
        const iconType = type === PLAYER_ICON.forward ? 'showForward': 'showRewind';
        setState(prevstate => ({...prevstate, [iconType]: true}))
        setTimeout(() => {
            setState(prevstate => ({...prevstate, [iconType]: false}))
        }, 500)
    }

    function getDataSnUp() {
        if(videoId.includes('live-video')) {
            return '#nav-bar .swiper-slide .active.focusable:first-child';
        } else if(ctv_focus_on) {
            return ctv_focus_on;
        } else {
            return '#inline_arrow';
        }
    }

    function onSeekBarClick(e) {
        let elm = document.getElementById('custom-seekbar');
        if(!elm) return;

        const total_width = elm.clientWidth;
        const left = e.pageX - elm.offsetLeft;
        const percentage = left / total_width;

        if(!player || !left || !percentage) return;
        
        const vid_time = player.duration * percentage;
        player.currentTime = vid_time;
    }

    return (
        <div id='custom-control-bar' className="custom-controls">
            <div className="progress-bar">
                <span>{currTime}</span>
                <div id="custom-seekbar" onClick={ onSeekBarClick }>
                    <span className='seek'></span>
                </div>
                <span className='text-right'>{totalTime}</span>
            </div>
            <div className='controls-buttons-div'>
                <span>
                    <span role="button" aria-label={isPaused ? 'Play' : 'Pause'} data-sn-up={getDataSnUp()} className='play-pause controls-icon focusable' tabIndex={getTabIndex()} onClick={onPlayPause}>
                        {isPaused
                        ?   <PlayIcon />
                        :   <PauseIcon />
                        }
                    </span>
                    <span role="button" aria-label={'rewind'} data-sn-up={getDataSnUp()} className='play-pause rewind controls-icon focusable' tabIndex={getTabIndex()} onClick={onBackward}>
                        <Rewind />
                    </span>
                    <span role="button" aria-label={'forward'} data-sn-up={getDataSnUp()} className='play-pause forward controls-icon focusable' tabIndex={getTabIndex()} onClick={onForward}>
                        <Forward />
                    </span>
                </span>
                <span className="volume">
                    <span role="button" aria-label={muted ? 'unmute volume' : 'mute volume'} data-sn-up={getDataSnUp()} className={`mute-vol controls-icon focusable ${muted ? 'muted' : ''}`} tabIndex={getTabIndex()} onClick={onMute}>
                        <VolumeMute />
                    </span>
                    {
                        !checkCorrectPlatform([PLATFORM_VIZIO]) ?
                        <>
                            <span role="button" aria-label={`volume down`} data-sn-up={getDataSnUp()} className='down-vol controls-icon focusable' tabIndex={getTabIndex()} onClick={onVolumeDown}>
                                <VolumeDown />
                            </span>
                            <span className='volume-level'>
                                {volume}%
                            </span>
                            <span role="button" aria-label={`volume up`} data-sn-up={getDataSnUp()} className='up-vol controls-icon focusable' tabIndex={getTabIndex()} onClick={onVolumeUp}>
                                <VolumeUp />
                            </span>
                        </>: <></>
                    }
                </span>
            </div>
            {state.showForward && <Forward />}
            {state.showRewind && <Rewind />}
        </div>
    )
}

export default PlayerProgressBar
