import React from 'react';
import Swiper from 'react-id-swiper';
import { deepLinkUrl, httpsUrl, getSaveFeedSectionIdentifier, PLATFORM_HTML_SIMULATOR, PLATFORM_WEB } from '../constants/common'
import { connect } from "react-redux"
import { openModal } from "../actions/modalActions"
import { Redirect } from 'react-router'
import * as analytics  from '../constants/analytics';
import configuration from '../constants/config'
import { checkCorrectPlatform, checkIfMenuOrItemDeeplink, checkIfToDoNothing, getCurrentPlatform } from '../helperfunctions/common';
import { getBannerSwiperParams } from '../helperfunctions';
import { isSmartTv } from '../helperfunctions/common';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import Loader from '../components/Loader'
import { addRemoveAllNavigation, keyMappings } from '../helperfunctions/ctvHelper';

/**
 * check, if to show banner
 */
function checkBannerSegmentation(bannerId, feeds, isLoggedIn, isSubscribed) {
  let platform = getCurrentPlatform();
  if(configuration.isAppTvodType && platform === PLATFORM_WEB) {
    platform = "web";
  }
  bannerId = bannerId.toString();
  const bannersSeg = feeds.bannerGroupSegmentation;

  if (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) return true;
  if (!bannersSeg) return false;
  // Check if banner id present in all section
  if (bannersSeg.all && bannersSeg.all[platform] && bannersSeg.all[platform].includes(bannerId)) 
    return true;
  // Check if banner id present in Logged in section
  if (isLoggedIn && bannersSeg.logged_in && bannersSeg.logged_in[platform] && bannersSeg.logged_in[platform].includes(bannerId)) 
    return true;
  // Check if banner id present in Logged out section
  if (!isLoggedIn && bannersSeg.logged_out && bannersSeg.logged_out[platform] && bannersSeg.logged_out[platform].includes(bannerId)) 
    return true;
  // Check if banner id present in Subscription section
  if (isSubscribed && bannersSeg.sub && bannersSeg.sub[platform] && bannersSeg.sub[platform].includes(bannerId))
    return true;
  // Check if banner id present in Non Subscription section
  if (!isSubscribed && bannersSeg.non_sub && bannersSeg.non_sub[platform] && bannersSeg.non_sub[platform].includes(bannerId))
    return true;
  return false;
}
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.swiper = null;
  }

  state = {
   redirect: false,
   showLoader: false,
   bannerName: null,
   deeplinkActionUrl: null
  }

  componentDidMount(){
    //analytics.setEvent('Banners', 'Banner View', banner.name)
    const bannerContainer = document.querySelector('.banner > .swiper-container');
    this.swiper = bannerContainer && document.querySelector('.banner > .swiper-container').swiper;
    if (isSmartTv()) {
      document.addEventListener('keyup',this.handleRemoteClick);
      let banner_slides = document.querySelectorAll('.banner .swiper-wrapper .swiper-slide .focusable');
      banner_slides[banner_slides.length - 1] && banner_slides[banner_slides.length - 1].setAttribute('data-sn-right', '');
		}
    this.bannerClickListener();
    if(isSmartTv() && configuration.use_light_sliders) {
      setTimeout(() => {
        this.id = addRemoveAllNavigation({ selector: '.banner .focusable' });
      },500)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.oneFeedLoading && !this.props.oneFeedLoading && this.state.deeplinkActionUrl) {
      this.onDeepLinkClick(null, this.state.deeplinkActionUrl, this.state.bannerName)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup',this.handleRemoteClick);
    if(isSmartTv() && configuration.use_light_sliders) {
      addRemoveAllNavigation({ id: this.id });
      // removeContentFromFocusableSection({
      //   enableDefaultSection: true,
      //   content_identifier: 'banner',
      //   id: this.sn_id
      // });
    }
  }

  bannerClickListener = () => {
    const _self = this;
    const allSlide = this.getAllSlides();
    const slides = document.querySelectorAll('.banner .swiper-slide > a');
    slides.forEach(ele => {
      let index = ele.getAttribute('data-index');
      if (index) {
        const banner = allSlide[parseInt(index)];
        ele.addEventListener('click', (e) => {_self.onDeepLinkClick(e, banner.actionUrl, banner.name)});
      }
    })
  }

  onLinkClick(event,overlay,actionUrl,path, name) {
    analytics.setEvent('Banners', 'Banner Action', name)
    if (checkIfToDoNothing(actionUrl, this.props.feeds)) {
      event.preventDefault();
      return null;
    }
    if (actionUrl.includes("/registerwall/")) {
      this.props.openModal({type: "login", screen: "registerWall"})
    } else if (actionUrl.includes("/register/")) {
      this.props.openModal({type: "login", screen: "firstSave"})
    } else {
      this.setState({
        redirect: true,
        redirectPath: path
      })
    }
    event && event.preventDefault()
  }

  sendAnalytics(name){
    analytics.setEvent('Banners', 'Banner Action', name)
  }

  handleRemoteClick = (e) => {
		let bannerParent = document.querySelector('.banner');

    const key_code = keyMappings(e.keyCode);
		if (key_code === 'LEFT' || key_code === 'RIGHT' || key_code === 'UP' || key_code === 'DOWN') {
			if (bannerParent && bannerParent.contains(document.activeElement)) {
				const nearestContainer = document.activeElement.closest(".swiper-container");
				let currIndex;
				nearestContainer.querySelectorAll(".swiper-wrapper > div").forEach((ele, index) => {
					if (ele.contains(document.activeElement)) {
						currIndex = index;
            nearestContainer.swiper && nearestContainer.swiper.slideTo(currIndex)
					}
				})
			}
    }
	}

  /**
   * In case of SmartTv, when banner slide change, move the focus to active slide. If current focus in banner element.
   */
  onSlideChange = () => {
    let bannerParent = document.querySelector('.banner');
    if (isSmartTv() && this.swiper && bannerParent.contains(document.activeElement)) {
      let activeSlide = this.swiper.activeIndex;
      const activeParent = document.querySelectorAll('.banner .swiper-wrapper > div')[activeSlide];
      if (!activeParent.contains(document.activeElement)) {
        let focusableEle = activeParent.querySelector('.focusable');
        JsSpatialNavigation.focus(focusableEle, false);
      }
      if(configuration.use_light_sliders) {        
        elementScrollIntoView(document.activeElement, { behavior: 'auto', block: 'center' });
      } else {
        elementScrollIntoView(document.activeElement, {behavior: 'smooth', block: 'center', inline: 'center'});
      }
      this.swiper && this.swiper.autoplay.start();
    }
  }

  onDeepLinkClick = (event, actionUrl, bannerName) => {
    event && event.preventDefault();
    if (this.props.oneFeedLoading && checkIfMenuOrItemDeeplink(actionUrl)) {
      this.setState({
        showLoader: true,
        deeplinkActionUrl: actionUrl,
        bannerName
      })
    } else {
      let deeplink = deepLinkUrl(actionUrl, this.props.app_id, this.props.feeds)
      this.onLinkClick(event,deeplink[1], actionUrl, deeplink[0], bannerName)
    }
  }

  getAllSlides = () => {
    var allSlides = []
    for(var i=0;i<this.props.banners.length;i++){
      allSlides.push(this.props.banners[i])
    }
    if(!this.props.runningOnMobile && allSlides.length < 3) {
      // In case of web & simulator less number of banners would leave empty space. To avoid that increasing the numbers
      allSlides = allSlides.concat(allSlides);
    }
    return allSlides;
  }

  render() {
    const onSlideChangeCB = this.onSlideChange;
    const self = this;
    if (this.state.redirect){
      return <Redirect to={this.state.redirectPath}/>;
    }

    if (!checkBannerSegmentation(this.props.bannerId, this.props.feeds, this.props.isLoggedIn, this.props.isSubscribed)) {
      return null;
    }
    var allSlides = this.getAllSlides();
    const initialSlide = Math.floor(allSlides.length / 2)
    const params = getBannerSwiperParams(initialSlide, this.props.runningOnMobile);
    const saveId = getSaveFeedSectionIdentifier(this.props.feeds);
    let swiperParams = params;
    let class_name = 'focusable';
    if (isSmartTv()) {
      swiperParams = {
        ...swiperParams,
        on: {
          slideChangeTransitionEnd: () => {
            onSlideChangeCB();
          }
        }
      }

      if(configuration.use_light_sliders) {
        swiperParams.speed = 0;
      }
      class_name += ' d-block';
    }
    
    return (
      <div className="slider-box banner">
        {this.state.showLoader && <Loader />}
        <Swiper {...swiperParams}>
          {allSlides.map(function(banner, i) {
            if(banner.actionUrl){
              if(self.props.runningOnMobile && (
                banner.actionUrl.includes("save") || ( saveId !== "" && banner.actionUrl.includes(saveId))
              )) {
                // Not rendering save banner in case of mobile
                return null;
              }
              // var deeplink = deepLinkUrl(banner.actionUrl, self.props.app_id, self.props.feeds)
              return (
                <div key={i}>
                  <a id={`banr_${i}`} href="#" className={ class_name } tabIndex="-1" data-index={i}>
                    <img className="img-banner" src={httpsUrl(banner.url)}/>
                  </a>
                </div>
              )
            } else {
              //analytics.setEvent('Banners', 'Banner Action', banner.name)
              return (
                <div key={i}>
                  <a  id={`banr_${i}`} href="#" className={ class_name } tabIndex="-1" onClick={() => self.sendAnalytics(banner.name)}>
                    <img className="img-banner" src={httpsUrl(banner.url)}/>
                  </a>
                </div>
              )
            }
          }, this)}
        </Swiper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.logged_in,
    isSubscribed: state.user.subscription
  }
}

const mapDispatchToProps = {
  openModal: openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
