import React from 'react';

import '../css/switch-slider.css';
import { getTabIndex, isSmartTv } from '../helperfunctions/common';

class SwitchSlider extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            isChecked: props.isChecked || false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!('isChecked' in this.props) && prevState.isChecked !== this.state.isChecked) {
            if(this.props.onSliderChange) {
                this.props.onSliderChange(this.state.isChecked);
            }
        }
    }

    toggleSwitch() {
        if(isSmartTv()) {
            let elm;
            const total_nodes = document.getElementsByClassName("switch-slider-wrapper");
            for(let i=0;i<total_nodes.length;i++) {
                if(total_nodes[i] && total_nodes[i].contains(document.activeElement)) {
                    elm = total_nodes[i];
                }
            }
            
            if('isChecked' in this.props) {
                this.props.onSliderChange(!this.props.isChecked);
            } else {
                this.setState({
                    isChecked: !this.state.isChecked
                },() => {
                    elm.focus();
                });
            }
        } else {
            if('isChecked' in this.props) {
                this.props.onSliderChange(!this.props.isChecked);
            } else {
                this.setState({
                    isChecked: !this.state.isChecked
                });
            }
        }
    }

    ancClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.toggleSwitch();
    }

    render() {
        if (isSmartTv()) {
            return (
                <span id={`ak_id`} onClick={this.ancClick} className="switch-slider-wrapper focusable" tabIndex={getTabIndex()}>
                    <input type="checkbox" checked={('isChecked' in this.props) ? this.props.isChecked : this.state.isChecked}/>
                    {(this.state.isChecked && this.props.primaryColor)
                        // if color privided then using it else would use default color in css file
                        ? <span className="switch-slider round" style={{backgroundColor: this.props.primaryColor}}></span>
                        : <span className="switch-slider round"></span>
                    }
                </span>
            )
        } else {
            return (
                <label id="ak_id" className="switch-slider-wrapper focusable" tabIndex={getTabIndex()}>
                    <input type="checkbox" checked={('isChecked' in this.props) ? this.props.isChecked : this.state.isChecked} onClick={() => this.toggleSwitch()}/>
                    {(this.state.isChecked && this.props.primaryColor)
                        // if color privided then using it else would use default color in css file
                        ? <span className="switch-slider round" style={{backgroundColor: this.props.primaryColor}}></span>
                        : <span className="switch-slider round"></span>
                    }
                </label>
            )
        }
    }
}

export default SwitchSlider;