import React, { Component } from 'react';
import './css/tvx-fonts.css';
import './css/tvx-default.css';
import './css/swiper.min.css';
import './css/dev.css';
import './css/tvx-large.css';
import './css/desktop.css';
import './css/style.css';
import './css/responsive.css';
import './css/webStyle.css';
import './css/webResponsiveStyle.css';
import './css/ctv.css';
import './css/slick_slider_ctv.css';
import Layout from "./containers/Layout";
import { Provider } from "react-redux";
import store from "./store";
import config from './constants/config';
import JsSpatialNavigation from './ctvnavigation/js-spatial-navigation';
import { isSmartTv } from './helperfunctions/common';
import configuration from './constants/config';
import { BrowserRouter as Router } from 'react-router-dom';
import { getCtvKeyMappings } from './helperfunctions/ctvHelper';

class App extends Component {
  componentWillMount(){
    // if running on smart TV this method will initialize 
    // spatial navigation
    this.initializeCTVNavigation();
  }

  componentDidMount() {
    if(config.web_custom_script_header) {
      document.head.insertAdjacentHTML("beforeend", config.web_custom_script_header)
    }
    if(config.web_custom_script_footer) {
      document.body.insertAdjacentHTML("beforeend", config.web_custom_script_footer)
    }
  }

  initializeCTVNavigation() {
    try {
      if(isSmartTv()) {
        JsSpatialNavigation.setKeyMap(getCtvKeyMappings());
        JsSpatialNavigation.init();

        if(configuration.use_light_sliders) {
          if(document.getElementById('root')) {
            document.getElementById('root').style.overflowX = 'hidden';
          }

          JsSpatialNavigation.add(`navbar`, {
            selector: `#nav-bar .focusable`,
            enterTo: 'default-element',
            defaultElement: '#nav-bar .swiper-slide .active.focusable:first-child',
            straightOnly: false,
            leaveFor: {
              'down': '.content-box .focusable'
            }            
          });
        } else {
          JsSpatialNavigation.add({
            selector: '.focusable'
          });
        }
      }
    } catch(err) {
      console.log("unable to add spatial naviogation",err);
    }
  }

  render() {
    if (!config.is_simulator){
      if (config.title){
      document.title = config.title
      }
      let favicon = document.getElementById("favicon");
      if (config.favicon){
        favicon.href = config.favicon;
      }else{favicon.href = "favicon.ico";}
    }
    return (
      <Provider store={store}>
        <Router>
          <Layout/>
        </Router>
      </Provider>
    );
  }
}

export default App;
