import React from 'react'
import { connect } from 'react-redux'
import { httpsUrl, IS_INTERSTITIAL_FOR_IMAGE } from '../constants/common'
import { openModal, setPreviewVideo } from "../actions/modalActions"
import { getThumbnailType } from '../helperfunctions';
import { GetImageUrl, getTabIndex, isAppRunningOnLowEndPlatform, isSmartTv } from '../helperfunctions/common';
import { clickParentDivWhenFocusedSpotlightContent, clickParentIfFullscreen, getHref, getSpatialDataAttribute } from '../helperfunctions/ctvHelper';

function ThumbnailType(content, head){
  var contentUrl = window.location.pathname.split("/")
  var lastElement = contentUrl[contentUrl.length - 1]
  if(head === lastElement){
    contentUrl = window.location.pathname + "/" + content.identifier
  } else {
    contentUrl = window.location.pathname + "/" + head + "/" + content.identifier
  }
  return contentUrl
}

class ThumbnailImage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hover: false,
      sourceClass: ''
    }
  }

  onMouseEventHandler(hover, sourceClass, content = null) {
    if(document.getElementsByClassName("swiper-slide slideHover")[0]){
      document.getElementsByClassName("swiper-slide slideHover")[0].classList.remove("slideHover")
    }
    if(hover){
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      });
      this.props.setPreviewVideo(content)
      if (isSmartTv()) {
        clickParentDivWhenFocusedSpotlightContent();
        clickParentIfFullscreen();
      }
    } else {
      this.setState({
        hover: hover,
        sourceClass: sourceClass
      })
    }
  }

  checkIfInterstitalIsOn = () => {
    if (this.props.content.showInterstitial)
      return true;
    return false;
  }

  openModal(e){
    console.log("image modal!");
    e.preventDefault()
    document.getElementsByClassName("item-" + this.props.content.identifier)[0].classList.add("slideHover")
    const propsObj = {
      head: this.props.head, type: "image", identifier: this.props.content.identifier, content: this.props.content,
      all_contents: this.props.all_contents, pathname: window.location.pathname,
      firstpath: getThumbnailType(this.props.content, this.props.head)
    }
    if (!IS_INTERSTITIAL_FOR_IMAGE || (this.props.layout == "detail" || !this.checkIfInterstitalIsOn())) {
      this.props.openModal(propsObj)
    } else {
      this.props.openModal({
        type: 'interstitialScreen',
        modalProps: propsObj,
        thumbnailProps: {
          background: this.props.background
        }
      });
    }
  }

  componentDidUpdate(){
    var source = document.getElementsByClassName(this.state.sourceClass)[0]
    if(!this.state.hover && source && !this.props.detail && source.getAttribute("data-src")){
      source.setAttribute('src', source.getAttribute("data-src"))
      source.removeAttribute('data-src')
      source.classList.add('swiper-lazy-loaded')
    }
  }

  clickEnter = (e) => {
    // console.log('Click enter',e);
    // document.activeElement.click();
    e.srcElement.childNodes[0].click();    
  }

  removeTransition() {
    if(isAppRunningOnLowEndPlatform()) {
      return 'remove-transition';
    }
    return '';
  }

  render(){
    if(this.props.remember[this.props.content.identifier]){
      var played = this.props.remember[this.props.content.identifier]["p"]
      var total = this.props.remember[this.props.content.identifier]["t"]
      var style = {
        width: played*100/total + "%",
        backgroundColor: this.props.secondary.replace('#FF','#')
      }
    }

    const divSecodaryBack = {backgroundColor: this.props.content.cover ? 'transparent' :this.props.secondary.replace('#FF', '#')}
    const divPrimaryBack = {backgroundColor: this.props.content.cover ? 'transparent' :this.props.primary.replace('#FF', '#')}
    const imgPrimaryBack = {backgroundColor: this.props.primary.replace('#FF', '#')}
    const imgSecodaryBack = {backgroundColor: this.props.secondary.replace('#FF', '#')}

    if(this.state.hover) {
      return (
        <a { ...getHref() } className={`thumbLink thumbHover focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} onClick={(event) => this.openModal(event)}
          onFocus={() => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier, this.props.content)}
          onBlur={() => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}
          { ...getSpatialDataAttribute(this.props.index, this.props.total, this.props.layout) }
          aria-label={this.props.content.title}
          aria-describedby={`content-head-${this.props.content.parent_id}`}
        >
          <div style={divSecodaryBack} className="img-video" data-title={this.props.content.title} data-subtitle={this.props.content.subtitle}
              data-summary={this.props.content.summary} onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier, this.props.content)}
              onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}>
            {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
            {this.props.content.cover && <img loading="lazy" style={imgSecodaryBack} className={"img-thumb swiper-lazy image-content-" + this.props.content.identifier} data-src={GetImageUrl(httpsUrl(this.props.content.cover.url), this.props.content.cover.sizes)}/>}
          </div>
        </a>
      )
    } else {
      return(
        <a { ...getHref() } className={`thumbLink focusable ${this.removeTransition()}`} tabIndex={getTabIndex()} onClick={(event) => this.openModal(event)}
          onFocus={() => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier, this.props.content)}
          onBlur={() => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}                    
          { ...getSpatialDataAttribute(this.props.index, this.props.total, this.props.layout) }
          aria-label={this.props.content.title}
          aria-describedby={`content-head-${this.props.content.parent_id}`}
        >
          <div style={divPrimaryBack} className="img-video" data-title={this.props.content.title} data-subtitle={this.props.content.subtitle}
              data-summary={this.props.content.summary}onMouseEnter={(hover, sourceClass) => this.onMouseEventHandler(true, "image-content-" + this.props.content.identifier, this.props.content)}
              onMouseLeave={(hover, sourceClass) => this.onMouseEventHandler(false, "image-content-" + this.props.content.identifier)}>
            {this.props.remember[this.props.content.identifier] && <span className="remSpot" style={style}></span>}
            {this.props.content.cover && <img loading="lazy" style={imgPrimaryBack} className={"img-thumb swiper-lazy image-content-" + this.props.content.identifier} data-src={GetImageUrl(httpsUrl(this.props.content.cover.url), this.props.content.cover.sizes)}/>}
          </div>
        </a>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  modal: state.modal.modal,
  subscription: state.user.subscription
})

const mapDispatchToProps = {
  openModal: openModal,
  setPreviewVideo: setPreviewVideo
}

export default connect(mapStateToProps,mapDispatchToProps)(ThumbnailImage);
