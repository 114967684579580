import { PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_XBOX } from "../constants/common";
import configuration from "../constants/config";
import { checkCorrectPlatform, getCurrentPlatform } from "../helperfunctions/common";

export default function reducer(
  state = {spotxid: '', firstVideoAdIndex: 0, videoAdFrequency: 0, firstVideoAdSeen: false,
    firstVideoCount: 0, frequencyCount: 0, app_id: 0}, action) {

  switch (action.type) {
    case "ADS_SETUP_DATA": {
      const videoAds = action.payload.videoAds;
      let adData = {};
      if(action.payload.spotxIds){
        const { web_as_output, tvos, samsung, lg, vizio, xbox } = action.payload.spotxIds;

        let spotxId = web_as_output;
        if(!spotxId && (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]))) {
          // if simulator and don't have ad id for Web as output
          spotxId = tvos;
        } else if(lg && configuration.is_simulator && checkCorrectPlatform([PLATFORM_LG])) {
          adData = {
            videoAdtype: 'spotX',
            adURL: lg
          }
        } else if(samsung && configuration.is_simulator && checkCorrectPlatform([PLATFORM_SAMSUNG])) {
          adData = {
            videoAdtype: 'spotX',
            adURL: samsung
          }
        } else if(vizio && configuration.is_simulator && checkCorrectPlatform([PLATFORM_VIZIO])) {
          adData = {
            videoAdtype: 'spotX',
            adURL: vizio
          }
        } else if(xbox && configuration.is_simulator && checkCorrectPlatform([PLATFORM_XBOX])) {
          adData = {
            videoAdtype: 'spotX',
            adURL: xbox
          }
        }

        if(spotxId && !checkCorrectPlatform([PLATFORM_SAMSUNG, PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_XBOX])) {
          adData = {
            videoAdtype: "spotX",
            spotxid: spotxId
          };
        }
      } else if(videoAds && videoAds.videoAdtype) {
        let curr_platform = getCurrentPlatform();
        
        if(curr_platform && videoAds[curr_platform]) {
          adData = {
            videoAdtype: videoAds.videoAdtype,
            adURL: videoAds[curr_platform]
          }
        } else if(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR]) && videoAds["web_as_output"]) {
          adData = {
            videoAdtype: videoAds.videoAdtype,
            adURL: videoAds["web_as_output"]
          }
        }
      }
      return {
        ...state, ...adData,
        firstVideoAdIndex: action.payload.firstVideoAdIndex, videoAdFrequency: action.payload.videoAdFrequency,
        firstVideoAdSeen: false, app_id: action.app_id,
        nonVmapAdBreakFrequency: action.payload.nonVmapAdBreakFrequency || null,
        nonVmapCuePointKey: action.payload.nonVmapCuePointKey === undefined ? false : action.payload.nonVmapCuePointKey,
        requestAdEverytimeForLive:  action.payload.requestAdEverytimeForLive === undefined ? true : action.payload.requestAdEverytimeForLive
      }
    }
    case "UPDATE_VIDEO_COUNT": {
      if(state.firstVideoAdSeen){
        var total_seen = state.frequencyCount + action.payload
        return {
          ...state, frequencyCount: total_seen % state.videoAdFrequency
        }
      } else {
        if(action.payload + state.firstVideoCount <= state.firstVideoAdIndex) {
          return {
            ...state, firstVideoCount: action.payload + state.firstVideoCount
          }
        } else {
          return {
            ...state, firstVideoAdSeen: true, frequencyCount: (action.payload + state.firstVideoCount - state.firstVideoAdIndex)
          }
        }
        var total_seen
      }
    }
  }

  return state
}