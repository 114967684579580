import React from 'react';
import configuration from '../constants/config'


class PoweredByScreen extends React.Component {
  render(){
      const domain = window.location.origin;

      const launchUrl = (configuration.is_simulator || configuration.launch_screen.startsWith("https://")) ? configuration.launch_screen : `${domain}/${configuration.launch_screen}`;
      return (
        <div className={"poweredby-screen " + (this.props.runningOnMobile ? "poweredby-screen-mobile" : "")}>
          <img src={launchUrl} />
        </div>
      )
  }
}

export default PoweredByScreen;
