import { elementScrollIntoView } from "seamless-scroll-polyfill";
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';
import configuration from "../constants/config";
import { PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_XBOX } from "../constants/common";
import { checkCorrectPlatform, isSmartTv } from "./common";

/**
 * This function will check if focus in one of the fullscreen or spotlight theme. If yes then we are clicking parent div, which are calling the action to 'setLockedContent' in spotlightContent.js file.
 */
export function clickParentDivWhenFocusedSpotlightContent() {
    const spotFullSlider = document.querySelector('#fullscreen-grid,#spotlight-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".spotlight.slider-box");
        nearestContainer && nearestContainer.click();
    }
}

/**
 * Function will check if focus in fullscreen section. If yes then click the parent div, which call the action to mute, unmute video for background videos.
 */
export function clickParentIfFullscreen() {
    const spotFullSlider = document.querySelector('#fullscreen-grid');
    if (spotFullSlider && spotFullSlider.contains(document.activeElement)) {
        const nearestContainer = document.activeElement.closest(".fullscreen-parent-row");
        nearestContainer && nearestContainer.click();
    }
}

export function checkIfFocusInsideBanner() {
    const bannerParent = document.querySelector('.banner');
    if (bannerParent && bannerParent.contains(document.activeElement))
        return true;
    return false
}

export function focusEpg() {
    let parentEpg = document.getElementById('EPG-layout');
    let tot_els = parentEpg.querySelectorAll('.focusable');
    if (!tot_els.length) return;
    tot_els.forEach((el, j) => {
        if (el.getAttribute('data-row')) return;

        el.setAttribute('data-row', j);

        el.addEventListener('sn:focused', function (data) {
            elementScrollIntoView(this, { behavior: 'smooth', block: 'center', inline: 'center' });
        });
    });
}

export function convertSwiperParamsToSlick(params, _self) {
    // default slick params
    let slick_params = {
        dots: false,
        infinite: false,
        autoplay: false,
        cssEase: "linear",
        arrows: false,
        speed: 250,
        focusOnSelect: false,
        lazyLoad: 'ondemand',
        useCSS: false,
        onInit: () => {
          document.getElementsByClassName('slick-track').forEach(elm => elm.classList.add('d-flex'));
        }
    };

    slick_params['slidesToShow'] = params['slidesPerView'];
    if(params['direction'] && params['direction'] == 'vertical') {
        slick_params['vertical'] = true;
        slick_params['slidesToShow'] = 1;
    }

    if(params['breakpoints']) {
        slick_params['responsive'] = [];
        for(let key in params['breakpoints']) {
            slick_params['responsive'].push({
                breakpoint: key,
                settings: {
                    slidesToShow: params['breakpoints'][key]['slidesPerView'] || slick_params['slidesToShow']
                }
            });
        }
    }

    return slick_params;
}

export function makeContentFocusableSection({ disableDefaultSection, content_identifier, config, time=500}) {
    try {
        JsSpatialNavigation.add(`navbar`, {
            selector: `#nav-bar .focusable`,
            enterTo: 'default-element',
            defaultElement: '#nav-bar .swiper-slide .active.focusable:first-child',
            straightOnly: false,
            leaveFor: {
                'down': '.slider-box .focusable'
            }
        });
    } catch(err) {
        console.log("navbar",err);
    }

    return new Promise((res, rej) => {
        try {
            const selector = `.content-${content_identifier} .focusable`;
            let default_config = {
                selector,
                enterTo: 'last-focused',
                defaultElement:  `.content-${content_identifier} .focusable`,//document.querySelector(selector),
                ...config // overwrite default configuration
            };
            if(disableDefaultSection) {
                // disable default section
                // JsSpatialNavigation.disable('section-1');
            }
    
            let id = JsSpatialNavigation.add(`section_${content_identifier}`,default_config);
    
            document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).addEventListener('sn:focused', snFocusEventHandler);
            
            // if(!document.getElementById('nav-bar')) {
            //     JsSpatialNavigation.focus();
            // } else {
            //     JsSpatialNavigation.focus('navbar');
            // }                
            res(id);
        } catch(err) {
            let emsg = err.message;
            if(emsg.includes('already existed')) {
                // console.log("err",err.message);
                JsSpatialNavigation.enable(`section_${content_identifier}`);

                document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).addEventListener('sn:focused', snFocusEventHandler);
            }
            rej(err);
        }   
    })
}

export function removeContentFromFocusableSection({ enableDefaultSection, content_identifier, id}) {
    return new Promise((res, rej) => {
        try {
            if(enableDefaultSection) {
                // enable default section
                // JsSpatialNavigation.enable('section-1');
            }
    
            // remove created section
            // JsSpatialNavigation.remove(id);
            // JsSpatialNavigation.remove(`section_${content_identifier}`);
            JsSpatialNavigation.disable(`section_${content_identifier}`);
    
            document.querySelector(`.content-${content_identifier}`) && document.querySelector(`.content-${content_identifier}`).removeEventListener('sn:focused', snFocusEventHandler);    
    
            // JsSpatialNavigation.remove(`navbar`);
            res(true)
        } catch(err) {
            console.log("err",err);
            rej(false)
        }     
    });
}

export function enableDisableNavbarFocusable(enable = true) {
    try {
        // JsSpatialNavigation.clear();
        if(enable) {
            JsSpatialNavigation.enable('navbar');
        } else {
            JsSpatialNavigation.disable('navbar');
        }
    } catch(err) {
        console.log("err",err);
    }
}

export function addRemoveAllNavigation({ id, selector, config = {}}) {
    try {
        if(!id) {
            return JsSpatialNavigation.add({
                selector,
                ...config
            });
        } else {
            JsSpatialNavigation.disable(id);
        }
    } catch(err) {
        console.log("error",err);
    }
}

export function getSpatialDataAttribute(_index, _total, _layout = null) {
    if(!isSmartTv() && !configuration.use_light_sliders) return;

    if(!_index == null || _total == null) return;
    if(_layout && _layout == 'detail') {
        if(_index == _total - 1) {
            return { 'data-sn-down': '' }
        }
        return {
            // 'data-sn-right': ''
        };
    }

    _index = parseInt(_index);
    _total = parseInt(_total);

    if(_index == 0) {
        if(_total == 1) {
            return {
                'data-sn-left': '',
                'data-sn-right': ''
            }
        } else {
            return {
                'data-sn-left': ''
            }
        }
    } else if(_index == _total - 1) {
        return {
            'data-sn-right': ''
        }
    }
    return;
}

export function snFocusEventHandler(data) {
    if(!data || !data.detail) return;
    if(data.srcElement && data.srcElement.classList.contains('hg-black')) {
        return;
    }

    if(data.detail.direction == 'up' || data.detail.direction == 'down') {
        elementScrollIntoView(data.srcElement, { behavior: 'auto', block: 'center' });
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
        elementScrollIntoView(data.srcElement, { behavior: 'auto', block: 'center' });
    }
}

export function FetchCurrentDeviceID() {
    return new Promise((resolve, reject) => {
        if (checkCorrectPlatform([PLATFORM_LG])) {
            if (!window.webOSDev) {
                reject(null);
                return;
            }
            window.webOSDev.LGUDID({
                onSuccess: function (res) {
                    // user device from server
                    if (res && res.id) {
                        resolve(res.id);
                    } else {
                        resolve(null);
                    }
                },
                onFailure: function (res) {
                    reject(null);
                }
            });
        } else if (checkCorrectPlatform([PLATFORM_SAMSUNG])) {
            if(!window.tizen) {
                reject(null);
                return;
            }
            let did = window.tizen.systeminfo.getCapability("http://tizen.org/system/tizenid") || window.webapis.productinfo.getDuid();
            resolve(did);
        } else if(checkCorrectPlatform([PLATFORM_VIZIO])) {
            if(!window.VIZIO) {
                reject(null);
                return;
            }
            window.VIZIO.getDeviceId(function(deviceId) {
                if(!deviceId) {
                    reject(null);
                } else {
                    resolve(deviceId);
                }
            });
        }
    })
}

export function retainFocusOnBackPress(last_focus, modal, url) {
    if(last_focus) {
      JsSpatialNavigation.pause();
      try {   
        setTimeout(() => {
            if(last_focus) {
                let elm
                if(modal && last_focus['modals'][modal]) {
                    elm = last_focus['modals'][modal];
                } else if(url && last_focus['paths'][url]) {
                    elm = last_focus['paths'][url];
                }
                if(elm) {
                    elm.focus();
                    elementScrollIntoView(elm, { behavior: 'auto', block: 'center', inline: 'center' });
                }
            }
        },250);
      } catch(err) {
        console.log('error on retain focus',err)
      }
      JsSpatialNavigation.resume();
    };
}

export function alignToTop(e) {
    if(!isSmartTv()) return;
    elementScrollIntoView(e.currentTarget, { behavior: 'auto', block: 'center' });
}

export function isTextEllipsis(elm) {
    return elm.scrollHeight > elm.clientHeight;
}

export function handleTextTruncate(elm) {
    /**
     * This method truncates the text
     * on last full stop which can be
     * displayed on the screen, and it
     * removes all the web links to avoid
     * complexity
     */
    try {
        if(elm && isTextEllipsis(elm)) {
            let text = elm.innerHTML;
            // removed the links from the text
            text = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
            text = text.split('.');

            //checking one by one if the text can be shown
            if(text.length > 1) {
                let show_text = "";
                for(let i=0; i < text.length-1;i++) {
                    elm.innerHTML = `${show_text} ${text[i]}.`;
                    if(isTextEllipsis(elm)) {
                        elm.innerHTML = `${show_text}`;
                        break;
                    } else {
                        show_text += `${text[i]}.`;
                    }
                }
            } else {
                elm.innerHTML = "";
            }
        }
    } catch(err) {
        console.log("Error occured while text truncate",err);
    }
}

export function getHref(href = "") {
    if(!isSmartTv()) {
        return {
            href
        };
    }
    return {};
}

export function getRole(role) {
    if(!isSmartTv()) return {};
    return {
        role
    };
}

export function getParameterByName(name, url) {
    if(!name || !url) return;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function toggleSubtitlesVisibility(player, show_subtitle = true) {
    let mode;
    if(show_subtitle) {
      mode = 'showing';
    } else {
      mode = 'hidden'
    }
    const tracks = player.textTracks();
    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      track.mode = mode;
    }
}

export function getCtvKeyMappings() {
    // key mappings for Samsung, LG, VIZIO and XBOX
    return {
        '37': 'left',
        '214': 'left',
        '205': 'left',
        '140': 'left',
        '39': 'right',
        '213': 'right',
        '206': 'right',
        '141': 'right',
        '38': 'up',
        '211': 'up',
        '203': 'up',
        '138': 'up',
        '40': 'down',
        '212': 'down',
        '204': 'down',
        '139': 'down'
    };
}

export function keyMappings(key_code) {
    //detect platform and return key maps accortding to that
    switch(parseInt(key_code)) {
        case 37:
        case 214:
        case 205:
        case 140:
            return 'LEFT';
        
        case 39:
        case 213:
        case 206:
        case 141:
            return 'RIGHT';

        case 38:
        case 211:
        case 203:
        case 138:
            return 'UP';
                
        case 40:
        case 212:
        case 204:
        case 139:
            return 'DOWN';
        
        case 13:
        case 142:
        case 195:
            return 'OK';
        
        case 415:
            return 'PLAY';
        
        case 19:
            return 'PAUSE';
        
        case 10252:
            return 'PLAY-PAUSE';

        case 413:
            return 'STOP';
        
        case 417:
            return 'FORWARD';
        
        case 412:
            return 'REWIND';

        default:
            return parseInt(key_code);
    }
}