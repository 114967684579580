import configJson from "./configuration.json"
import { detectPlatform } from "../helperfunctions/common"
let config = configJson;
const configData = window.configData || {};
// removing vizio to read from configuration.js
const platform = ['Samsung', 'LG']
if (!platform.includes(detectPlatform()) && configData && Object.keys(configData).length) {
  config = configData;
}
let configuration = {...config}
let app_id = document.getElementById("root").getAttribute("data-appId")
if(app_id){
  configuration.app_id = app_id
}
let env = document.getElementById("root").getAttribute("data-env")
if(env){
  configuration.env = env
}
let launch_screen = document.getElementById("root").getAttribute("data-launchscreen")

if (launch_screen){
  configuration.launch_screen = launch_screen
}
let kEnableGAUserBeaconing = document.getElementById("root").getAttribute("data-kEnableGAUserBeaconing")
if(kEnableGAUserBeaconing){
  configuration.kEnableGAUserBeaconing = kEnableGAUserBeaconing
}
let kEnableMazReporting = document.getElementById("root").getAttribute("data-kEnableMazReporting")
if(kEnableMazReporting != null) {
  configuration.kEnableMazReporting = kEnableMazReporting
}
let is_simulator = document.getElementById("root").getAttribute("data-is_simulator")

// If it is simulator then setting platform and making other config as null
if (is_simulator === "true"){
  configuration.is_simulator = true;
  configuration.platform = 'html5';
  configuration.fb_app_id = null;
  configuration.fb_client_token = null;
}
let subText = document.getElementById("root").getAttribute("data-subscription_terms_text");
if (subText) {
  configuration.subscription_terms_text = subText;
}
let showLiveControls = document.getElementById("root").getAttribute("data-show_live_controls");
if (showLiveControls === "true") {
  configuration.show_live_controls = true;
}
let epgTimeline = document.getElementById("root").getAttribute("data-epg_header_time");
if (epgTimeline) {
  configuration.epg_header_time = parseInt(epgTimeline)
}


if (configuration.env === "staging1") {
  configuration.env = "staging";
}

if (configuration.enableCtvVirtualSlides == "true" || configuration.enableCtvVirtualSlides == true) {
  configuration.enableCtvVirtualSlides = true;
} else {
  configuration.enableCtvVirtualSlides = false
}

/**Use light sliders for CTV */
if(configuration.platform == 'web_as_output' || configuration.platform == 'html5' || configuration.platform == 'preview') {
  configuration.use_light_sliders = false;
} else {
  configuration.use_light_sliders = true;
}
/**Use light sliders for CTV */

let kAppType = document.getElementById("root").getAttribute("data-kAppType");
if (kAppType) {
  configuration.kAppType = kAppType
}
configuration.isAppTvodType = (configuration.kAppType === "tvod");

/** Detect Vizio & XBOX **/
if(detectPlatform() === 'Vizio') {
  configuration.platform = 'vizio_tv';
  configuration.use_light_sliders = true;
  configuration.is_simulator = true;
} else if(detectPlatform() == 'Xbox') {
  configuration.platform = 'xbox';
  configuration.use_light_sliders = true;
  configuration.is_simulator = true;
}
/** Detect Vizio & XBOX **/


export default configuration
