import LZString from 'lz-string';
import axios from "axios";
import { environment, getEnvironmentUrl, getMazCoreEnvUrl, getOneFeedAPIToken, getTvodApiHeaders, getTvodAPIKey } from '../constants/initializer'
import { updateMeteringDetailsForFeed } from "./userActions";
import { haveSavedMAZFeed } from "./itemActions"

import config from '../constants/config'
import { FEED_POLL_INTERVAL, PLATFORM_HTML_SIMULATOR, PLATFORM_WEB, SUBTITLE_OPTION } from '../constants/common'
import { checkCorrectPlatform, getDateWithoutHyphen, isAppRunningOnLowEndPlatform, isSmartTv } from '../helperfunctions/common';
import { FEED_POLICY_RESPONSE, ONE_FEED_PENDING, ONE_FEED_REJECTED, ONE_FEED_RESOLVED, SET_CC_OPTION, SET_LOCAL_SUBTITLE_SETTING } from '../constants/actionTypes';
import { getLocalStorage, setLocalStorage } from '../helperfunctions/storage';
import * as APIS from "../constants/api";
import configuration from '../constants/config';

// console.log("HEllo",config.env)
var crypto = require('crypto'),
            algorithm = 'aes-256-cbc',
            key = (config.app_id + "-" + config.env.repeat(5)).substring(0,32),
            iv = "0000000000000000";


function decrypt(text) {
    var cipher = crypto.createDecipheriv(algorithm,key,iv)
    var crypted = cipher.update(text, "base64", "utf-8")
    crypted += cipher.final("utf-8");
    return crypted;
}

function checkSavedFeedInSection(section) {
  let isSavedFeed = false;
  if (!section)
    isSavedFeed = false;
  if (section.mazIDSectionType === 'saved')
    isSavedFeed = true;
  if (section.type === 'menu' && section.contents && section.contents.length) {
    for(let i=0; i<section.contents.length; i++) {
      isSavedFeed = checkSavedFeedInSection(section.contents[i])
      if (isSavedFeed) { return isSavedFeed }
    }
  }
  return isSavedFeed;
}

const checkIfSavedMazIdFeedSections = (sections) => {
  return new Promise((resolve, reject) => {
    for (let i=0; i<sections.length; i++) {
      if (checkSavedFeedInSection(sections[i])) {
        return resolve(true);
      }
    }
    return resolve(false);
  })
}

function storeFeedInLocalStorage(response_data) {
  const app_id = config.app_id;
  try {
    if (!isSmartTv()) {
      let compressed = LZString.compressToUTF16(JSON.stringify(response_data))
      localStorage.setItem(`${app_id}-MY_FEED`, compressed);
    }
  } catch(e) {
    console.log("error while comprssing")
  }
}

function oneFeedPolling(dispatch, firstResponse, policyData={}) {
  if(isSmartTv()) return;

  let updatedResponse = firstResponse;
  let intervalTime = FEED_POLL_INTERVAL.staging;
  if (config.env === 'production') {
    intervalTime = FEED_POLL_INTERVAL.production;
  }
  const app_id = config.app_id;
  setInterval(function() {
    // const epgTheme = document.getElementById("EPG-layout");
    // if(epgTheme) return; //stop polling for epg theme
    const url = configuration.isAppTvodType ? APIS.getTvodEncryptedOneFeedUrl(app_id, policyData.locale_id, policyData.language) : getEnvironmentUrl() + "/apps/get_wao_feed/" + app_id + `?simulator=${config.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])}`;
    axios.get(url, getTvodApiHeaders(true))
    .then((response) => {
      let response_data = JSON.parse(decrypt(response.data));
      if(updatedResponse && Object.keys(updatedResponse).length > 1) {
        //Comparing old response with new one.
        if(updatedResponse.modified && response_data.modified &&
          getDateWithoutHyphen(response_data.modified) > getDateWithoutHyphen(updatedResponse.modified)
        ) {
          updateReduxState(dispatch, response_data, app_id);
          updatedResponse = response_data;
          storeFeedInLocalStorage(response_data);
        }
      }
    })
  }, intervalTime)
}

function getFeedfromLocalStorage(app_id) {
  let my_feed;
  let parsedLocalFeed=null;
  if (!isSmartTv()) {
    my_feed = localStorage.getItem(`${app_id}-MY_FEED`);
  }
  try {
    if(my_feed) {
      my_feed = JSON.parse(LZString.decompressFromUTF16(my_feed));
      if(my_feed && Object.keys(my_feed).length > 1) {
        parsedLocalFeed = my_feed;
        // updateReduxState(dispatch, my_feed, app_id);
      }
    }
  } catch(e) {
    my_feed = null;
  }
  return parsedLocalFeed;
}

export function getFeeds(app_id) {
  return async function(dispatch) {
    let my_feed = getFeedfromLocalStorage(app_id);
    if (my_feed) {
      updateReduxState(dispatch, my_feed, app_id);
    }

    let policyData;
    if(configuration.isAppTvodType) {
      try {
        policyData = await getTvodAppPolicy(app_id, dispatch);
      } catch(error) {
        return;
      }
    }

    let oneFeedData;
    /** If localstorage feed is present, do not load appFeed */
    if (!my_feed) {
      loadAppFeed(app_id, policyData)
      .then(appFeed => {
        if (!oneFeedData)
          updateReduxState(dispatch, appFeed, app_id);
      });
    }

    if (!my_feed) {
      dispatch({
        type: ONE_FEED_PENDING
      })
    }
    getOneFeed(app_id, policyData)
    .then(data => {
      oneFeedData = data || {};
      dispatch({
        type: ONE_FEED_RESOLVED,
        payload: oneFeedData
      })
      if(my_feed && Object.keys(my_feed).length > 1) {
        // in local storage
        if(my_feed.modified && oneFeedData.modified &&
          new Date(oneFeedData.modified) > new Date(my_feed.modified)
        ) {
          updateReduxState(dispatch, oneFeedData, app_id);
        }
      } else {
        updateReduxState(dispatch, oneFeedData, app_id);
      }
      storeFeedInLocalStorage(oneFeedData);
      oneFeedPolling(dispatch, oneFeedData, policyData);
    })
    .catch(err => {
      dispatch({
        type: ONE_FEED_REJECTED
      })
    })
    }
}

function getTvodAppPolicy(app_id, dispatch) {
  return axios.post(APIS.TVOD_POLICY_API, {"app_id": app_id, "key": getTvodAPIKey(), "language": "en" }, getTvodApiHeaders(true))
    .then(sign_response => {
        const { signature, locale_id, languages } = sign_response.data;
        if((isSmartTv() && !signature) || !locale_id || !languages) {
          dispatch({
            type: ONE_FEED_REJECTED
          })
          throw 'Unable to fetch one feed!';
        } else {
          const policyData = {
            signature: signature,
            locale_id: locale_id,
            language: languages[0]
          }
          dispatch({
            type: FEED_POLICY_RESPONSE,
            payload: policyData
          })
          return policyData;
        }
    })
}

function getOneFeed(app_id, policyData) {
  if(configuration.isAppTvodType) {
    const { signature, locale_id, language } = policyData;
    const url = isSmartTv() ? `${APIS.getTvodOneFeedUrl(app_id, locale_id, language)}${signature}` : APIS.getTvodEncryptedOneFeedUrl(app_id, locale_id, language);
    return axios.get(url, getTvodApiHeaders(true))
    .then(response => {
      response = isSmartTv() ? response.data : JSON.parse(decrypt(response.data));
      return response;
    }).catch(err => {
      console.log("error occured!",err);
    })
  } else {
    if(isSmartTv()) {
      return fetch(`${getEnvironmentUrl()}/services/cf_access?app_id=${app_id}&app_type=ComboApp&api_token=${getOneFeedAPIToken()}`, {
        'method': 'GET',
        'Content-type': 'application/json'
      })
      .then(res => res.json())
      .then(sign_response => {
        let sign_res_data = sign_response;
        if(!sign_res_data.success) throw 'Unable to fetch one feed!';

        return fetch(`https://cloud.mazdigital.com/feeds/${environment()}/comboapp/${app_id}/api/v3/tv_one_feed${sign_res_data.signature}`, {
          'method': 'GET',
          'Content-type': 'application/json'
        })
        .then(res => res.json())
        .then(response => {
          return response;
        }).catch(err => {
          console.log("error occured!",err);
        })
      })
    } else {
      const url = getEnvironmentUrl() + "/apps/get_wao_feed/" + app_id + `?simulator=${config.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])}`;
      return axios.get(url)
      .then(response => {
        let response_data = JSON.parse(decrypt(response.data));
        return response_data;
      })
    }
  }
}

function convertToContentsInsideSectionArr(arr) {
  let newArr = [];
  for(let i=0; i<arr.length; i++) {
    let contents = arr[i];
    if (contents.contentArray) {
      contents.contents = contents.contentArray;
      delete contents.contentArray;
    }
    newArr.push(contents)
  }
  return newArr;
}

function convertToContentsInSection(section) {
  if (section.contentArray) {
    const sectionContents = section.contentArray;
    delete section.contentArray;
    section.contents = convertToContentsInsideSectionArr(sectionContents)
    return section;
  } else {
    return section;
  }
}

function convertAppFeedContentArrayToContents(appFeed) {
  const sections = appFeed.sections;
  const newSections = [];
  for(let i=0; i<sections.length; i++) {
    newSections.push(convertToContentsInSection(sections[i]))
  }
  delete appFeed.sections;
  appFeed.sections = newSections;
  return appFeed
}

function loadAppFeed(app_id, policyData = {}) {
  const { locale_id, language } = policyData;
  const url = configuration.isAppTvodType ? APIS.getTvodEncryptedAppFeedUrl(app_id, locale_id, language) : getEnvironmentUrl() + "/apps/get_tv_app_feed/" + app_id + `?simulator=${config.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])}`
  return axios.get(url)
  .then(response => {
    const decrypted = decrypt(response.data)
    const appFeed = configuration.isAppTvodType ? decrypted : LZString.decompressFromBase64(decrypted)
    const covertedFeed = convertAppFeedContentArrayToContents(JSON.parse(appFeed))
    return covertedFeed;
  })
}

function updateReduxState(dispatch, feed, app_id) {
  dispatch({type: "ADS_SETUP_DATA", payload: feed, app_id: app_id})
  dispatch({type: "RECEIVE_FEEDS", payload: feed, app_id: app_id})
  dispatch(updateMeteringDetailsForFeed());
  dispatch(checkCloseCaption(feed))
  if (feed.sections && feed.sections.length) {
    checkIfSavedMazIdFeedSections(feed.sections)
    .then(data => data && dispatch(haveSavedMAZFeed()))
  }
}

function checkCloseCaption(feed) {
  return function(dispatch) {
    if (feed.ccMenu) {
      const localSetting = getLocalStorage(SUBTITLE_OPTION);
      let showSubtitle = localSetting;
      if (localSetting === null) {
        setLocalStorage(SUBTITLE_OPTION, true)
        showSubtitle = true;
      }
      dispatch({
        type: SET_CC_OPTION,
        payload: {
          showSetting: true,
          localSub: showSubtitle
        }
      })
    }
  }
}

export function channgeLocalCaption(showSub) {
  return function(dispatch) {
    setLocalStorage(SUBTITLE_OPTION, showSub)
    dispatch({
      type: SET_LOCAL_SUBTITLE_SETTING,
      payload: showSub
    })
  }
}
