import configuration from '../constants/config';
import { getDateWithoutHyphen, isSmartTv } from './common';
import isAvailable from './is_available';
export function getParentFeed(feeds, sectionUrl) {
    let sections = feeds.sections
    let content;
    let pathLocation = sectionUrl.split("/")
    for(let i=0; i<pathLocation.length - 1; i++){
      for(let j=0; j<sections.length; j++){
        if(sections[j].identifier === pathLocation[i]){
          content = sections[j]
          if(sections[j].contents) {
            sections = sections[j].contents;
          }
        }
      }
    }
    return content
}
/**
 * This function return an object which tell when you press back, where the user should go.
 */
export function backButtonObj(feeds, section_id, runningOnMobile){
  let sections = feeds.sections
  let content;
  let pathLocation = section_id.split("/");
  let temp = 1;
  let parentIndex = 1;
  const appId = configuration.app_id;
  if(isNaN(pathLocation[0])){return {pathLocation: "settings", backPath: `/apps/${appId}/settings`, style: {}}}
  for(let i=0; i<pathLocation.length - 1; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
        if (temp === 1)  {
          if (pathLocation.length-1 != i)
            parentIndex = pathLocation.length - i - 1;
          if (content.regularLayout === "detail" && !runningOnMobile) {
            temp = 2;
          } else {
            temp = 3;
          }
        }
        temp--;
      }
    }
  }

  pathLocation = pathLocation.slice(0,-parentIndex).join("/")
  if (!content) {
    return {pathLocation: `apps/${appId}`, backPath: `/apps/${appId}`, style: {}}
  }
  if(content.regularLayout === "spotlight"){
    return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {position: "fixed"}}
  }
  return {pathLocation: pathLocation, backPath: `/apps/${appId}/${pathLocation}`, style: {}}
}

export function checkIfSectionLevelContent() {
  const urlArr = window.location.pathname.split('/').slice(3);
  if (urlArr.length === 1)
    return true;
  return false;
}


/**
 * Function to check whether proper sections is present, and proper contents in all section. (true for proper feed)
 * @param {*} feed One feed
 */
export function checkFeedIntegrity(feed) {
  const sections = feed.sections;
  if (!sections || !sections.length)
    return false;
  return true;
}


// The function return a pathname for an item
export function getThumbnailType(content, head){
  var contentUrl = window.location.pathname.split("/")
  var lastElement = contentUrl[contentUrl.length - 1]
  if (isSmartTv() && window.location.pathname.includes(content.identifier)) {
    contentUrl = window.location.pathname;
  } else {
    if(head === lastElement || head === content.identifier){
      contentUrl = window.location.pathname + "/" + content.identifier
    } else {
      contentUrl = window.location.pathname + "/" + head + "/" + content.identifier
    }
  }
  return contentUrl
}

export function findDefaultSection(oneFeed) {
  window.feeds = oneFeed
  var feed = 0;
  var contents = oneFeed.sections;
  var defaultSection = contents[0];
  var defaultSectionArray = [defaultSection]
  var pathLocation = [defaultSection.identifier]
  var defaultChanged = 0
  while(feed == 0){
    for(var i=0; i < contents.length; i++){
      if(contents[i].isDefault){
        if(pathLocation.length == 1 && defaultChanged == 0) {
          pathLocation = pathLocation.slice(0,-1)
          defaultSectionArray = defaultSectionArray.slice(0,-1)
          defaultChanged = 1
        }
        defaultSection = contents[i]
        defaultSectionArray.push(defaultSection)
        pathLocation.push(defaultSection.identifier)
        feed = 0
        if(defaultSection.type !== "live" && defaultSection.type !== "cvideo" && defaultSection.type !== "image" && defaultSection.contents){
          contents = defaultSection.contents
        } else {
          feed = 1
          break
        }
        break;
      } else {
        feed = 1
      }
    }
  }
  if(pathLocation.length > 1 && defaultSectionArray[defaultSectionArray.length - 2].regularLayout !== "detail"){
    pathLocation = pathLocation.slice(0,-1)
    return [defaultSectionArray[defaultSectionArray.length - 2], pathLocation]
  } else {
    return [defaultSection, pathLocation];
  }
}

export function getGdprParamsForApi(feeds) {
  const gdprObj = {
    gdpr: {}
  }
  if (!feeds || !feeds.gdpr || !feeds.gdpr.privacy)
    return {};
  if (feeds.gdpr.privacy.partner) {
    gdprObj.gdpr.partner = true;
  }
  if (feeds.gdpr.privacy.third_party) {
    gdprObj.gdpr.third_party = true;
  }
  return gdprObj;
}

export function checkIfSectionIdExist(section_id, feeds) {
  if (section_id.includes('subscription')) {
    return {
      isSectionId: true,
    }
  }
  let sections = feeds.sections
  let content={};
  let pathLocation = section_id.split("/");
  let availablePath = [];
  const appId = configuration.app_id;
  if(isNaN(pathLocation[0])){
    return {
      isSectionId: false,
      availablePath: ""
    }
  }
  for(let i=0; i<pathLocation.length; i++){
    for(let j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        availablePath.push(sections[j].identifier);
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents
        }
      }
    }
    if (pathLocation[i] !== content.identifier) {
      return {
        isSectionId: false,
        availablePath: availablePath.length ? availablePath[0] : ""
      }
    }
  }
  return {
    isSectionId: true,
  };
} 

export function  findCurrentContentType(feeds, paths) {
  var currentLocation = paths.split("/")
  var contents = feeds.sections
  let type = "";
  for(var i=0; i<currentLocation.length; i++) {
    if(currentLocation[i] === "" || currentLocation[i] === "apps") continue;
    for(var j=0; j<contents.length; j++){
      if(contents[j].identifier == currentLocation[i]) {
        type = contents[j].type;
        if(contents[j].contents) {
          contents = contents[j].contents
        }
        if(!Array.isArray(contents)){
          break;
        }
      }
    }
  }
  return type;
}

export function getTvodInterstitalMetaData(props, platform) {
  if(!props) return {};
  const {section: {locked, access}, feeds} = props;

  let lockedContentData = null;
  let showMoreWays = false;
  let ownLockedContent = isAvailable(null, null, null, props.section);
  if(!ownLockedContent && access && access.bundles && access.bundles.length > 0 && feeds.tvod && feeds.tvod.tiers) { // Not checking key "locked" for now
    const tiers = feeds.tvod.tiers;
    lockedContentData = {};
    access.bundles.forEach(bundle => { // bundle loops
      if(tiers[bundle.type] && tiers[bundle.type].length > 0) {
        tiers[bundle.type].forEach(tier => { // tiers loop
          if(tier.identifier === bundle.identifier) {
            let isValid = false;
            if(bundle.type === "subscriptions" && tier.metadata) {
              // check if this subscription tier valid
              let isValidMeta = true;
              tier.metadata.forEach(meta => {
                if(!meta.iap || !meta.iap[platform]) {
                  isValidMeta = false
                }
              })
              isValid = isValidMeta;
              if(!isValid) {
                showMoreWays = true;
              }
            } else if(tier.iap && tier.iap[platform]) {
              // check if this tier valid
              isValid = true
            }
            if(isValid) {
              // valid for platform
              if(!lockedContentData[bundle.type]) {
                lockedContentData[bundle.type] = [];
              }
              let formattedTier = {...tier, ...bundle};
              if(bundle.type === "events") {
                formattedTier.startsAt = getDateWithoutHyphen(access.startsAt);
                formattedTier.endsAt = getDateWithoutHyphen(access.endsAt);
              }
              lockedContentData[bundle.type].push(formattedTier);
            }
          }
        })// tiers loop ends
      }
    }); // bundle loops ends
  }
  let signedInMoreWays = "Additional purchase options for this content are not available on this device.";
  let signedOutMoreWays = "Please sign in to access your content. If you are not already a member, please join and return to the app";
  if(feeds && feeds.tvod && feeds.tvod.platforms && feeds.tvod.platforms[platform]) {
    if(feeds.tvod.platforms[platform].loggedInUserExternalPurchaseText) signedInMoreWays = feeds.tvod.platforms[platform].loggedInUserExternalPurchaseText
    if(feeds.tvod.platforms[platform].loggedOutUserExternalPurchaseText) signedOutMoreWays = feeds.tvod.platforms[platform].loggedOutUserExternalPurchaseText
  }
  return {
    lockedContentData,
    ownLockedContent,
    moreWays: {
      showMoreWays,
      signedInMoreWays,
      signedOutMoreWays
    }
  };
}

export function getLogoFromLoginOrFeed(feeds, screen) {
  if (configuration.isAppTvodType) {
    screen = "tvodLogin"
  }
  return feeds.loginUIMetadata && feeds.loginUIMetadata[screen] && feeds.loginUIMetadata[screen].headerImage || (feeds.logo && feeds.logo.url);
}
