import React from "react"
import { withRouter } from "react-router-dom";
import config from "../../constants/config";

const Stripe = window.Stripe;
let stripeObj = null;
if (Stripe && config.stripe_public_key && config.stripe_connect_id) {
    stripeObj = Stripe(config.stripe_public_key,
        {stripeAccount: config.stripe_connect_id});
}

function StripePage(props) {
  const sessionId = props.match.params.sessionId;

  React.useEffect(() => {
    if (sessionId) {
      stripeCheckout(sessionId);
    }
  }, []);

  function stripeCheckout(sessionId) {
    try {
      stripeObj.redirectToCheckout({
          sessionId: sessionId
      }).then(function (result) {
          console.log(result);
      })
    }
    catch(e) {
      props.history.push("/pay/failure");
    }
  }

  return (
    <></>
  );
}

export default withRouter(StripePage);
