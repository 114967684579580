import React, {useEffect} from 'react';
import { GEOFENCE_MSG } from '../constants/common';
import { getTabIndex, isSmartTv } from '../helperfunctions/common';
import Loader from './Loader';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import configuration from '../constants/config';

function GeofenceConfirm(props) {
    let id;
    useEffect(() => {
        //didmount
        if(isSmartTv() && configuration.use_light_sliders) {
            id = addRemoveAllNavigation({ selector: '#geofence-modal .focusable' });
        }
      
        return () => {
            //will unmount
            if(isSmartTv() && configuration.use_light_sliders) {
                addRemoveAllNavigation({ id });
            }
        }
    });

    return (
        <>
            {props.isLoading && (<Loader />)}
            <div id="geofence-modal" className="confirm-modal">
                <div onClick={(e) => {
                    e.stopPropagation();
                }}>
                <h3>Permission</h3>
                <p>{GEOFENCE_MSG}</p>
                <div className="button-div">
                    <button  className="focusable" tabIndex={getTabIndex()} onClick={() => props.onButtonPress(true)}>Allow</button>
                    <button className="focusable" tabIndex={getTabIndex()} onClick={() => props.onButtonPress(false)}>Deny</button>
                </div>
                </div>
            </div>
        </>
    )
}

export default GeofenceConfirm;