import React from "react";
import { connect } from "react-redux";
import { isMobile } from 'mobile-device-detect';
import WebFont from 'webfontloader';

import { getFeeds, channgeLocalCaption } from "../actions/feedsActions";
import NavBar from "./NavBar";
import PoweredByScreen from "../components/PoweredByScreen";
import SponsorScreen from "../components/SponsorScreen";
import Modal from "../components/Modal";
import { setUserCookie, getUserSubscriptionDetails, getUserMeteringDetails, userEngaged, getUserIp, getUserDeviceID, getDeviceModel, loginThroughMazTv, getLocationFromIP, setGeolocation, checkVoucherCodeValidity, checkIfUserOnline, setOnboardingCompleted } from "../actions/userActions";
import { getAllRememberSpot } from "../actions/rememberSpotActions";
import { getSavedItems, getUSerFeedHistoryOrProgress, getTvodSavedItems } from "../actions/itemActions";
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { generateFontStyleTagAndFontNames,
  getPrimaryAndSecondaryFontsFromFeed, 
  checkFeedIntegrity, checkIfSectionLevelContent} from '../helperfunctions';
import configuration from '../constants/config'
import FeedError from "../components/FeedError";
import { getGeoFromLocal, getRandomString, isSmartTv, setGeoFenceInLocal } from '../helperfunctions/common';
import { checkCorrectPlatform } from "../helperfunctions/common";
import { DEFAULT_GEO_LOCALTION, MAZ_REPORTING_INTERVAL, PLATFORM_HTML_SIMULATOR, PLATFORM_LG, PLATFORM_SAMSUNG, PLATFORM_VIZIO, PLATFORM_WEB, PLATFORM_WEB_PREVIEW, PLATFORM_XBOX } from "../constants/common";
import GeofenceConfirm from "../components/GeofenceConfirm";
import { setPageViewFixedSections } from "../constants/analytics";
import { sendActiveUserEvent, sendAppUsageMinutes } from "../helperfunctions/mazAnalytics";
import { getTvodStreams } from "../actions/streamActions";
import Toast from "../components/toast";
import Pay from "./Pay";
import Reset from "../components/ResetPassword";
import { getLocalStorage, getUserCookie, removeItemLocalStorage, setLocalStorage } from "../helperfunctions/storage";
import OnBoarding from "../components/OnBoarding";

const cookies = new Cookies();
class Layout extends React.Component {
  constructor(props) {
    super(props);
    const openGeoModal = this.checkGeoPermissionInLocal()
    this.state = {
      launchScreen: true,
      openGeofenceModal: openGeoModal,
      show_toast: false
    }
    this.beekenInterval = null;
    this.beekenCount = -1;
    this.mazBeekenInterval = null;
    this.mazBeakenCount = 0;
    this.mazBeakenStartTime = new Date().toISOString();
    this.userClickEvent = this.userClickEvent.bind(this)
  }

  componentDidMount() {
    document.body.addEventListener("click", this.userClickEvent, true);
    this.props.getUserIp();
    this.props.getUserDeviceID();
    this.props.getDeviceModel();
    const onMessageReceive = this.onMessageReceive;
    window.addEventListener("message", onMessageReceive);

    if(configuration.kEnableGAUserBeaconing) {
      this.beekenInterval = setInterval(() => {
        if(document.hidden) {
          this.beekenCount = -1;
        } else {
          this.beekenCount++;
        }
        if(this.beekenCount === 0 || this.beekenCount === 240) {
          // Send first event / every 4 mins
          this.beekenCount = 0;
          setPageViewFixedSections("User Activity Beacon")
        }
      }, 1000);

      if(isSmartTv()) {
        document.addEventListener('webkitvisibilitychange', this.pageVisibilityHandler);        
      }
    }
    sendActiveUserEvent();
    this.mazBeekenInterval = setInterval(() => {
      this.handleAppMinuteUsage();
    }, 1000)

    if(isSmartTv()) {
      this.online_status_interval = setInterval(() => {
        this.props.checkIfUserOnline();
      }, 4500);
    }

    if(isSmartTv()) {
      document.addEventListener('VIZIO_LIBRARY_DID_LOAD', (e) => {
        window.VIZIO && window.VIZIO.setClosedCaptionHandler((isCCEnabled) => {
          if(isCCEnabled) { // CC is on
            this.props.channgeLocalCaption(true);
          } else { // CC is of
            this.props.channgeLocalCaption(false);
          }
        });
      });
    }
    this.checkOnboradCompletion();    
  }

  pageVisibilityHandler() {
    if(document.webkitHidden) {
      // reset counter for CTV
      this.beekenCount = -1;
    }
  }

  onMessageReceive = (event) => {
    const _self = this;
    const data = event.data;
    if (data.request_from === "maz-tv" && data.action === "login") {
      const {user_id, auth_token, app_id} = data;
      if (user_id && auth_token && app_id && app_id == configuration.app_id) {
        _self.props.loginThroughMazTv(auth_token, user_id)
      }
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.userClickEvent, true);
    window.removeEventListener("message", this.onMessageReceive);
    this.handleAppMinuteUsage(true);
    if(this.beekenInterval) {
      clearInterval(this.beekenInterval)
      this.beekenInterval = null
    }
    if(this.mazBeekenInterval) {
      clearInterval(this.mazBeekenInterval)
      this.mazBeekenInterval = null
    }
    if(isSmartTv()) {
      if(this.online_status_interval) {
        clearInterval(this.online_status_interval);
        this.online_status_interval = null;
      }

      if(configuration.kEnableGAUserBeaconing) {
        document.removeEventListener('webkitvisibilitychange', this.pageVisibilityHandler);
      }
    }
  }

  componentWillMount() {
    // Polling is happening inside getFeeds Action.
    this.props.getFeeds(configuration.app_id);
    let user_cookie = getUserCookie();
    if(user_cookie){
      if(configuration.isAppTvodType) {
        this.props.getTvodStreams();
        this.props.getTvodSavedItems();
      } else {
        this.getUserMetering(user_cookie.auth_token, user_cookie.user_id);
        this.getUserMazFeedHistoryAndProgress(user_cookie.auth_token, user_cookie.user_id);
        this.props.getSavedItems(user_cookie.auth_token, "100", "0", user_cookie.user_id)
        if(!this.props.rem_fetched_after_login)
          this.props.getAllRememberSpot(user_cookie.auth_token, user_cookie.user_id)
      }
      this.getUserSubscription(user_cookie.auth_token, user_cookie.user_id);
      this.props.setUserCookie(user_cookie, configuration.app_id)
    }

    this.props.checkVoucherCodeValidity(null, configuration.app_id);
  }

  componentDidUpdate(prevProps, prevState) {
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline)) {
      this.setState({ show_toast: true });
    }

    // When feeds load for the first time. Get primary and secondary font and update the state.
    if (prevProps.feeds.text === 1 && this.props.feeds && this.props.feeds.fonts && this.props.feeds.fonts.length) {
      const fonts = getPrimaryAndSecondaryFontsFromFeed(this.props.feeds);
      this.loadFonts(fonts);
    }
    if (prevProps.geoLoading && !this.props.geoLoading) {
      this.setState({
        openGeofenceModal: false
      })
    }
    if(prevProps.logged_in !== this.props.logged_in && this.props.feeds.onboardingMetadata) {
      if(this.props.logged_in) {
        this.onboardingCompleted();
      } else {
        this.revokeOnboradingCompletion();
      }
    }
  }

  checkOnboradCompletion() {
    if(getLocalStorage(`${configuration.app_id}-onboarding`) ===  'done') {
      this.props.setOnboardingCompleted(true);
    }
  }

  revokeOnboradingCompletion() {
    if(this.props.feeds.onboardingMetadata.onboardingWall) {
      removeItemLocalStorage(`${configuration.app_id}-onboarding`);
      this.props.setOnboardingCompleted(false);
    }
  }

  onboardingCompleted() {
    if(!this.props.onBoardingCompleted) {
      setLocalStorage(`${configuration.app_id}-onboarding`, 'done');
      this.props.setOnboardingCompleted(true);
    }
  }

  handleAppMinuteUsage(unmounting) {
    if(!document.hidden) {
      this.mazBeakenCount++;
    }
    if(unmounting || this.mazBeakenCount === MAZ_REPORTING_INTERVAL) {
      //  every configure  mins / unmounting
      sendAppUsageMinutes(this.mazBeakenCount, this.mazBeakenStartTime);
      this.mazBeakenCount = 0;
      this.mazBeakenStartTime = new Date().toISOString();
    }
  }

  checkGeoPermissionInLocal = () => {
    const geoObj = getGeoFromLocal();
    let openGeoModal = true;
    if (geoObj && geoObj.isGeoPermissionSet) {
      openGeoModal = false
      if (geoObj && geoObj.isGeoFenceOn && geoObj.geoFenceCountry) {
        this.props.setGeolocation(geoObj.geoFenceCountry)
      }
    }
    return openGeoModal;
  }

  checkToOpenGeofenceModal() {
    return true;
  }

  onSponsorTimeout(){
    this.setState({launchScreen: false})
  }

  //Get user MAZ feed details. (history and progress)
  getUserMazFeedHistoryAndProgress = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId, 
      combo_app_id: configuration.app_id
    }
    this.props.getUSerFeedHistoryOrProgress({...params, filterType: 'history'});
    this.props.getUSerFeedHistoryOrProgress({...params, filterType: 'progress'});
  }

  //Get user subscription when page refreshes (first time get is in userAcess.js)
  getUserSubscription = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId, 
      combo_app_id: configuration.app_id
    }
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])))
      this.props.getUserSubscriptionDetails(params);
  }
  //Get user metering when page refreshes (first time get is in userAcess.js)
  getUserMetering = (token, userId) => {
    const params = {
      auth_token: token,
      user_id: userId
    }
    if (!(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])))
      this.props.getUserMeteringDetails(params);
  }
  /**
   * Adding Primary and Secondary Fonts (If present) that are coming from feed into HTML head and load it using webfont.
   */
  loadFonts = (fonts) => {
      const styleTag = generateFontStyleTagAndFontNames(fonts);
      document.getElementsByTagName('head')[0].appendChild(styleTag.style);
      WebFont.load({
        custom: {
          families: [...styleTag.fontNames]
        }
      })
  }

  userClickEvent() {
    this.props.userEngaged()
  }

  getParentClassNameUsingPlatform() {
    if (configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) {
      return "simulator-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
      if (isMobile) {
        return "web-route-container web-route-container-mobile"
      }
      return "web-route-container";
    }
    if (checkCorrectPlatform([PLATFORM_LG]))
      return "simulator-route-container ctv-container lg-container";
    if (checkCorrectPlatform([PLATFORM_SAMSUNG]))
      return "simulator-route-container ctv-container samsung-container";
    if (checkCorrectPlatform([PLATFORM_VIZIO]))
      return "simulator-route-container ctv-container vizio-container";
    if (checkCorrectPlatform([PLATFORM_XBOX]))
      return "simulator-route-container ctv-container xbox-container";
  }

  onGeofenceButtonClick = (getLocation) => {
    let defaultCountry = this.props.feeds.defaultCountry && this.props.feeds.defaultCountry.toUpperCase() || DEFAULT_GEO_LOCALTION.toUpperCase();
    if (!getLocation) {
      this.setState({
        openGeofenceModal: false
      })
      setGeoFenceInLocal(true, defaultCountry);
      this.props.setGeolocation(defaultCountry);
      //Save in localstorage
    } else {
      this.props.getLocationFromIP(defaultCountry);
    }
  }

  renderToast() {
    if(this.props.isUserOnline) {
      setTimeout(() => {
        this.setState({ show_toast: false });
      }, 3000)
  
      return <Toast type={"success"} msg={'Internet Connected'} />
    } else {
      return <Toast type={"error"} msg={'Internet Disconnected!!'} />
    }
  }

  defaultRenderComp = () => {
    if(this.props.feeds.text != 1 && this.props.feeds.sponsor && this.state.launchScreen){
      return (
        <SponsorScreen sponsor={this.props.feeds.sponsor} onTimeout={() => this.onSponsorTimeout()} runningOnMobile={isMobile}/>
      );
    }
    else if (this.props.feeds.text != 1 && !checkFeedIntegrity(this.props.feeds)) {
      return (<FeedError />)
    }
    else if (this.props.feeds.text != 1 && checkFeedIntegrity(this.props.feeds) && this.state.openGeofenceModal && this.props.feeds.isGeoFence) {
      return (<GeofenceConfirm
        onButtonPress={this.onGeofenceButtonClick}
        isLoading={this.props.geoLoading}
      />)
    }
    else if (this.props.feeds.text != 1 && this.props.feeds.onboardingMetadata  && !this.props.onBoardingCompleted) {
      return (<OnBoarding 
        onboardingMetadata={this.props.feeds.onboardingMetadata}
        feeds={this.props.feeds}
        onboardingCompleted={() => this.onboardingCompleted()}
        runningOnMobile={isMobile}
      />)
    }
    else if(this.props.feeds.text != 1) {
      return(
        <Router>
          <div className={this.getParentClassNameUsingPlatform()}>
            { isSmartTv() && this.state.show_toast ? this.renderToast() : null }
            <Modal oneFeedLoading={this.props.oneFeedLoading} feeds={this.props.feeds} app_id={this.props.app_id} runningOnMobile={isMobile}/> 
            <NavBar oneFeedLoading={this.props.oneFeedLoading} feeds={this.props.feeds} app_id={this.props.app_id} background={this.props.background} runningOnMobile={isMobile} isGeofencingOn={this.props.isGeofencingOn} userCountry={this.props.userCountry}/>
          </div>
        </Router>
      )}
    else
      return (
        <PoweredByScreen runningOnMobile={isMobile}/>
      )
  }

  render() {
    const DefaultRender = this.defaultRenderComp;
    // Check if got redirected from error page on S3
    if (window.location.search.includes("?paths=")) {
      const redirectTo = window.location.search.replace("?paths=", "");
      return <Redirect to={redirectTo} />
    }

    return(
      <Switch>
        <Route exact path={"/pay/*"} component={Pay} />
        <Route exact path={"/password/reset"} component={Reset} />
        <Route component={DefaultRender} />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  feeds: state.feeds.feeds,
  app_id: state.feeds.app_id,
  background: state.feeds.background,
  rem_fetched_after_login: state.remember.fetched_after_login,
  oneFeedLoading: state.feeds.oneFeedLoading,
  geoLoading: state.user.isGeoLoading,
  isGeofencingOn: state.user.isGeofencingOn,
  userCountry: state.user.userCountry,
  isUserOnline: state.user.isUserOnline,
  logged_in: state.user.logged_in,
  onBoardingCompleted: state.user.onBoardingCompleted
})

const mapDispatchToProps = {
  getFeeds: getFeeds,
  setUserCookie: setUserCookie,
  getAllRememberSpot: getAllRememberSpot,
  getSavedItems: getSavedItems,
  userEngaged: userEngaged,
  getUserSubscriptionDetails,
  getUserMeteringDetails,
  getUSerFeedHistoryOrProgress,
  getUserIp,
  getUserDeviceID,
  getDeviceModel,
  loginThroughMazTv,
  getLocationFromIP,
  setGeolocation,
  checkVoucherCodeValidity,
  getTvodStreams,
  getTvodSavedItems,
  checkIfUserOnline,
  channgeLocalCaption,
  setOnboardingCompleted
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
