import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import { localRememberSpot } from '../actions/rememberSpotActions'
import { updateVideoCount } from "../actions/adsActions"

import Cta from "../components/Cta"
import { closeModal,openModal } from '../actions/modalActions'
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, PLATFORM_HTML_SIMULATOR, MAZ_REPORTING_INTERVAL } from "../constants/common"
import isAvailable from '../helperfunctions/is_available'
import InlineMenu from "./InlineMenu";
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { backButtonObj, checkIfSectionLevelContent } from '../helperfunctions';
import * as analytics  from '../constants/analytics';
import playButton from "../images/icons/play-button.png";
import TvFocusable from '../components/TvFocusable';
import { showAdsAsPerFrequency, runAdInVideo, constructSpotxParams } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";
import configuration from '../constants/config';
import { addRemoveAllNavigation, toggleSubtitlesVisibility } from '../helperfunctions/ctvHelper';
import { sendVideoMinutes } from '../helperfunctions/mazAnalytics';
import { pushTvodStreamData } from '../actions/streamActions';

import 'videojs-contrib-ads';
import 'videojs-ima';
import { onVideoError, isSmartTv, onCTVVideoError, getItemCuePoints, checkCorrectPlatform, checkIfSafari, getTabIndex, getVttCaption } from '../helperfunctions/common';
import PlayerIcon from './icons/PlayerIcon';

var videojs = require('video.js');
let current_player,current_src,current_type;

function videoType(url){
  url = url.split('.')
  if (url[url.length - 1].split('?')[0] == "m3u8"){
    return "application/x-mpegURL"
  } else {
    return "video/mp4"
  }
}

function FindVideo(props){
  var videoIndex = 0;
  if(props.remember[props.section.identifier]){
    var all_contents = props.section.contents
    var contentId = props.remember[props.section.identifier]["contentId"]
    for(var i=0; i<all_contents.length; i++){
      if(contentId === all_contents[i].identifier){
        videoIndex = i;
        break;
      }
    }
  }
  return videoIndex;
}

function showVideoAd(state, props){
  var adToShow = props.ads.spotxid.length > 0
  adToShow = adToShow && window.SpotX && props.section.showVideoAd
  var showAd = false
  if(adToShow){
    showAd = showAdsAsPerFrequency(props.ads, state.video_count);
  }
  return showAd
}

function playVideo(url, type, props, showAd, new_video, _this, state){
  if(!document.getElementById('fake-video')) return; // if video tag doesn't exist

  if(showAd && !isSmartTv()) {
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
    var adContainer = document.getElementsByClassName('fake-video-ad')[0];
    const cust_params = constructSpotxParams(props.feeds, state.contents[state.index]);
    if(document.getElementById('fake-video_html5_api')){
      adContainer = document.getElementById('fake-video');
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById('fake-video_html5_api'),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    } else {
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById('fake-video'),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    }
  } else {

    //Increase Video Count
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}
    const player = videojs('fake-video', {
      controls: false,
      loadingSpinner: isSmartTv(),
      html5: {
        nativeTextTracks: false
      }
    });
    const _videoType = videoType(url);
    current_player = player;
    current_src = url;
    current_type = _videoType;
    player.on('error', function(e) {
      if(isSmartTv()) {
        onCTVVideoError(e, player, 'live');
      } else {
        onVideoError(_videoType, player);
      }      
    });
    player.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(props.runningOnMobile) {
        _this.setNextFakeVideo(state.index, true)
      }
    });
    const streamProgress = (configuration.isAppTvodType && props.allStreamObject[state.contents[state.index].cid] && props.allStreamObject[state.contents[state.index].cid].progress) || null;
    player.ready(() => {
      player.src({src: url, type: videoType(url), hls: {withCredentials: true}})
      runAdInVideo(player, props, state, _this, props.section.showVideoAd, "fake-video_html5_api", state.contents[state.index]);
      player.play();
      var newVideo = document.getElementById("fake-video_html5_api");
      newVideo.muted = false;
      const video_id = state.contents[state.index].identifier
      const rememberSpotId = props && props.remember[props.section.identifier]
      const rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
      newVideo.addEventListener("loadedmetadata", function(){
        if(streamProgress && newVideo.duration) {
          this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
        } else if(rememberSpot && rememberSpotId["p"]) {
          this.currentTime = rememberSpotId["p"];
        }
      }, {once: true})
      if(isSmartTv()) {
        newVideo.addEventListener("play", () => _this.hideNavbar())
        newVideo.addEventListener("pause", () => _this.hideNavbar(true))
      }
      // ads breaks
      const showVideoAd = props.ads && props.section.showVideoAd && props.ads.videoAdtype && props.ads.videoAdtype !== "spotX" && showAdsAsPerFrequency(props.ads, state.video_count)
      const nonVmapAdBreak = (showVideoAd && (props.ads.nonVmapAdBreakFrequency || props.ads.nonVmapCuePointKey));
      const adBreakData =  nonVmapAdBreak ? {
        state,
        _this, 
        player, 
        nonVmapAdBreakFrequency: props.ads.nonVmapAdBreakFrequency,
        nonVmapCuePointKey: props.ads.nonVmapCuePointKey,
        videoItem: state.contents[state.index] || null
      } : null;
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(adBreakData, _this), false)
      const vttCaption = getVttCaption(state.contents && state.contents[state.index]);
      vttCaption && player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
    })
  }
}

function handleVideoTimeUpdate(adBreakData, _this) {
  if(document.getElementById("fake-video_html5_api")){
    var video = document.getElementById("fake-video_html5_api");
  } else {
    var video = document.getElementById("fake-video");
  }

  if(video) {
    handleVideoMinutes(video, _this)
  }

  if(adBreakData && video) {
    const currentTime = Math.floor(video.currentTime);
    const {state, _this, player, nonVmapAdBreakFrequency, nonVmapCuePointKey, videoItem} = adBreakData;
    let timeMatched = false;
    if(nonVmapAdBreakFrequency && (currentTime % (nonVmapAdBreakFrequency * 60) === 0)) {
      timeMatched = true;
    } else if(nonVmapCuePointKey && videoItem && videoItem.cuePoints) {
      const cuePoints = getItemCuePoints(videoItem.cuePoints);
      if(cuePoints.indexOf(currentTime) > -1) {
        timeMatched = true;
      }
    }
    if(!state.playing_Ad && currentTime !== 0 && currentTime !== state.adBreak && timeMatched) {
      _this.pushTvodStreams();
      _this.saveVideoTimeInRemeberSpot(video);
      if(player) player.dispose()
      _this.setState({adBreak: currentTime});
    }
  }
}

function handleVideoMinutes(videoEl, _this) {
  const time = Math.floor(videoEl.currentTime);
  const tier = videoEl.videoHeight;
  if(time !== _this.streamedVideoTime) {
    _this.streamedVideoTime = time;
    _this.streamedVideoSeconds++;
  }
  if(tier && _this.streamedVideoTier && tier !== _this.streamedVideoTier) {
    prepareMazEventData(_this, "tier_changed");
  } else if(_this.streamedVideoSeconds === MAZ_REPORTING_INTERVAL) {
    prepareMazEventData(_this, "beacon");
  }
  if(tier) {
    _this.streamedVideoTier = tier;
  }
}

function prepareMazEventData(_this, type) {
  const {streamedVideoSeconds, streamedVideoTier, videoStartTime, state, props, videoCount} = _this || {};
  if(streamedVideoSeconds == null || !streamedVideoTier || !state) {
    return;
  }
  const { index, contents } = state;
  const data = {
    eventValue: streamedVideoSeconds.toString(),
    eventStartTime: videoStartTime,
    tierStreamed: `${streamedVideoTier}px`,
    contentId: contents[index].identifier,
    contentUrl: contents[index].contentUrl,
    eventAction: type
  }

  resetMazEventData(_this);
  sendVideoMinutes(data, props.feeds, window.location.pathname, contents[index].title, videoCount)
}

function resetMazEventData(_this) {
  if(_this) {
    _this.videoStartTime = new Date().toISOString();
    _this.streamedVideoSeconds = 0;
  }
}

class FakeLive extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      contents: "",
      index: 0,
      original_index: 0,
      type: "video/mp4",
      video_count: 0,
      metering_type: null,
      adBreak: 0,
      playing_Ad: false,
      video_title: "",
      showTitle: false,
      showMetering: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      freeContentOver: false,
      showPlayButton: !this.props.userEngaged,
      video_start: false,
      afterMount: false
    }
    this.backButton = this.backButton.bind(this)
    this.maintainTitle = this.maintainTitle.bind(this)
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
    this.METERING_TYPE = getMeteringTypes();
    this.videoCount=0;
    this.simulatorInterval = null;
    this.simulatorCount = 0;
    this.videoStartedAt = new Date();
    this.streamedVideoTier = null;
    this.streamedVideoSeconds = 0;
    this.streamedVideoTime = 0;
    this.videoStartTime = new Date().toISOString();
    this.video_stream_buffered = 0;
    this.navbar_timeout = null;
  }

  componentWillMount(){
    var index = FindVideo(this.props);
    this.setState({index: index})
    this.setState({original_index: index})
    this.setState({contents: this.props.section.contents})
    this.setState({type: videoType(httpsUrl(this.props.section.contents[0].contentUrl))})
  }

  shouldComponentUpdate(nextProps, nextState){
    if(!isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) && nextProps.subscription){
      if(document.getElementById('fake-video-overlay')) document.getElementById('fake-video-overlay').style.display = 'none';
      return false;
    } else {
      return true;
    }
  }

  hideNavbar(clear = false) {
    const navbar = document.getElementById('nav-bar');
    if(navbar) {
      this.navbar_timeout && clearTimeout(this.navbar_timeout);
      navbar.style.display = 'block';
      if(clear) {
        return;
      }      
      this.navbar_timeout = setTimeout(() => {
        navbar.style.display = 'none';
      }, 5000);
    }
  }
  
  onAdReady() {
    const adCont = document.getElementById("fake-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  saveVideoTimeInRemeberSpot(video) {
    if(configuration.isAppTvodType) return;
    this.props.rememberSpot(this.props.app_id, this.props.section.identifier,
      parseInt(videojs(video).currentTime()), parseInt(videojs(video).duration()), false, this.props.section.type,
      this.props.logged_in, this.props.user.auth_token, this.props.user.user_id, this.state.contents[this.state.index].identifier);
  }

  onAdStart(ev) {
    this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
  }

  onAdError() {
    this.setState({
      adWrapperExist: false,
      playing_Ad: false
    })
  }

  updateSrcInSmartTv(_type) {
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }

    if (podInfo.podPosition == podInfo.podLength) { 
      // console.log("played manually");
      // current_player.reset();
      current_player.src({ src: current_src, type: current_type, hls: {withCredentials: true} });
      const _this = this;
      current_player.ready(() => {
        current_player.play();

        var newVideo = document.getElementById("fake-video_html5_api");
        const video_id = _this.state.contents[_this.state.index].identifier
        const rememberSpotId = _this.props && _this.props.remember[_this.props.section.identifier]
        const rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
        const video_cid = configuration.isAppTvodType && _this.state.contents[_this.state.index].cid
        const streamProgress = (video_cid && _this.props.allStreamObject[video_cid] && _this.props.allStreamObject[video_cid].progress) || null;
        newVideo.addEventListener("loadedmetadata", function(){
          if(streamProgress && newVideo.duration) {
            this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
          } else if(rememberSpot && rememberSpotId["p"]) {
            this.currentTime = rememberSpotId["p"];
          }
          // set the subtitle if exist
          if(current_player && current_player.remoteTextTracks() && current_player.remoteTextTracks()[0] && current_player.remoteTextTracks()[0].src) {
            current_player.remoteTextTracks()[0].mode='showing';
          }
        }, {once: true})
        const vttCaption = getVttCaption(_this.state.contents && _this.state.contents[_this.state.index]);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
      })
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);
    }
    this.setState({playing_Ad: false})
    this.maintainTitle();
  }

  
  onAllAdComplete() {
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false})
    if(this.state.videoInQueue >= 0) {
      this.setNextFakeVideo(this.state.videoInQueue);
    }
  }

  startVideo() {
    let showAd = false
    if(this.props.ads.spotxid !== undefined){
      showAd = showVideoAd(this.state, this.props)
    }
    this.setState({video_title: this.state.contents[this.state.index].title})
    if(!showAd){
      this.videoCount = this.videoCount+1;
    }
    playVideo(httpsUrl(this.state.contents[this.state.index].contentUrl), this.state.type, this.props, showAd, true, this, this.state)
    if(!showAd){
      this.setState({video_title: this.state.contents[this.state.index].title})
      this.maintainTitle();
    }
    this.videoStartTime = new Date().toISOString();
    this.setState({
      playing_Ad: showAd,
      showPlayButton: false
    })
  }

  playOriginalVideo(){
    this.setState({playing_Ad: false})
    this.maintainTitle()
    const _self = this;
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}
    var video_id = this.state.contents[this.state.index].identifier
    var rememberSpotId = this.props.remember[this.props.section.identifier]
    var rememberSpot = rememberSpotId && (rememberSpotId.contentId === video_id)
    var original_index = this.state.original_index
    var index = this.state.index
    var video = videojs('fake-video', {
      html5: {
        nativeTextTracks: false
      }
    });
    const _videoType = videoType(this.state.contents[this.state.index].contentUrl);
    video.on('error', function(e) {
      if(isSmartTv()) {
        onCTVVideoError(e, video, 'live');
      } else {
        onVideoError(_videoType, video);
      } 
    });
    video.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(this.props.runningOnMobile) {
        this.setNextFakeVideo(this.state.index, true)
      }
    });
    var url = httpsUrl(this.state.contents[this.state.index].contentUrl)
    video.ready(function () {
      video.src({
        src: url,
        type: videoType(_self.state.contents[_self.state.index].contentUrl),
        hls: {
          withCredentials: true
        }
      });
      if(video.play){
        video.play()
        _self.videoCount = _self.videoCount+1;
      }
      var newVideo = document.getElementById("fake-video_html5_api")
      newVideo.muted = false;
      const video_cid = configuration.isAppTvodType && _self.state.contents[_self.state.index].cid;
      const streamProgress = (video_cid && _self.props.allStreamObject[video_cid] && _self.props.allStreamObject[video_cid].progress) || null;
      newVideo.addEventListener("loadedmetadata", function(){
        if(streamProgress && newVideo.duration) {
          this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
        } else if((original_index === index) && rememberSpot && rememberSpotId["p"] &&
          (rememberSpotId["p"] != rememberSpotId["t"])){
          newVideo.currentTime = rememberSpotId["p"]
        }
      }, {once: true})
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(null, _self), false);
      const vttCaption = getVttCaption(_self.state.contents && _self.state.contents[_self.state.index]);
      vttCaption && video.addRemoteTextTrack({src: vttCaption, default: _self.props.showSubtitle}, false);
    }, this);
  }

  setNextFakeVideo(index, prevVideoEnded){
    if(this.state.adWrapperExist && prevVideoEnded && isSmartTv()) return;

    var newIndex = index + 1
    if (newIndex >= this.state.contents.length) {
      newIndex = 0
    }
    if(prevVideoEnded && this.state.adWrapperExist) {
      //ad will play, don't run next video for now
      this.setState({videoInQueue: index});
      return;
    }
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      setTimeout(function(){
        removeSpotxIframe();
        this.playOriginalVideo()
      }.bind(this), 500)
    } else {
      this.pushTvodStreams();
      this.sendWatchedVideoAnalytics();
      prepareMazEventData(this, prevVideoEnded ? "video_ended" : "video_exited");
      analytics.setEventForSimlive(this.props.feeds, window.location.pathname, 'Video','Simulated Live Play', this.state.contents[this.state.index].title, this.videoCount, this.state.contents[this.state.index].contentUrl)
      // dispose previous one before playing next
      var video = document.getElementById("fake-video");
      if (video)
        videojs(video).dispose();
      var url = httpsUrl(this.state.contents[newIndex].contentUrl)
      var type = videoType(url)
      this.setState({video_title: this.state.contents[newIndex].title})
      this.setState({index: newIndex, type: type, adBreak: 0})
      this.setState({video_count: this.state.video_count + 1})
    }
  }

  componentDidMount() {
    if(isSmartTv() && configuration.use_light_sliders) {
      this.id = addRemoveAllNavigation({ selector: '.video-play-extra-button .focusable' });
      this.inline_id = addRemoveAllNavigation({ selector: '#video-inlineMenu .focusable' });
    }
    this.setState({afterMount: true});

    if(!this.state.showPlayButton) {
      this.startVideo()
    } else {
      if(isSmartTv()) {
        if(document.getElementById('fake-video_html5_api')) {
          document.getElementById('fake-video_html5_api').addEventListener('loadedmetadata',(e) => {
            this.setState({ video_start: true });
          },{once: true});  
        }          
      }
      if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
    }

    if(configuration.is_simulator && checkCorrectPlatform(PLATFORM_HTML_SIMULATOR) && !checkIfSafari()) {
      // For Bug simulator Bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/8267
      this.simulatorInterval = setInterval(() => {
        this.checkVideoStatusForSimulator()
      },1000)
    }
    if(isSmartTv()) {
      document.addEventListener('keyup', this.handleRemoteClick);
      this.hideNavbar();
    }
  }

  handleRemoteClick = (e) => {
    this.hideNavbar();
  }

  pushTvodStreams() {
    const video = document.getElementById("fake-video_html5_api");
    if(video && configuration.isAppTvodType && video.duration) {
      const cid = this.state.contents && this.state.contents[this.state.index] && this.state.contents[this.state.index].cid;
      const progress = Math.floor((video.currentTime / video.duration) * 100) || 0;
      this.props.pushTvodStreamData({"cid": cid, "progress": progress})
    }
  }

  clearSimulatorInterval() {
    if(this.simulatorInterval) {
      clearInterval(this.simulatorInterval);
      this.simulatorInterval = null;
    }
  }
 
  checkVideoStatusForSimulator() {
    if(!this.state.playing_Ad && !this.state.showPlayButton) {
      this.simulatorCount++;
      if(this.simulatorCount > 5) {
        const player = videojs('fake-video');
        if(player && !player.paused() && player.readyState() == 0) {
          player.trigger("error");
          player.pause();
          if(document.getElementById('fake-video')) document.getElementById('fake-video').classList.add("vjs-error")
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline)) {
      if(this.props.isUserOnline) {
        // restore video
        current_player.src({ src: current_src, type: current_type, hls: {withCredentials: true} });
        current_player.ready(() => {
          current_player.play();
        });
        const vttCaption = getVttCaption(this.state.contents && this.state.contents[this.state.index]);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle}, false);
      }
    }
    
    const video = document.getElementById("fake-video");

    if(prevState.index !== this.state.index || prevProps.section.identifier !== this.props.section.identifier) {

      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      if(!showAd){
        this.videoCount = this.videoCount+1;
      }
      playVideo(httpsUrl(this.state.contents[this.state.index].contentUrl), this.state.type, this.props, showAd, false, this, this.state)
      if(!showAd) {
        this.setState({video_title: this.state.contents[this.state.index].title})
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true, showTitle: false, showMetering: false})
      }
      this.simulatorCount = 0;
      this.videoStartedAt = new Date();
    } else if(prevState.adBreak !== this.state.adBreak && video) {
      this.simulatorCount = 0;
      playVideo(httpsUrl(this.state.contents[this.state.index].contentUrl), this.state.type, this.props, false, false, this, this.state)
    }

    if(prevState.freeContentOver !== this.state.freeContentOver && this.state.freeContentOver) {
      var spotxIframe = getSpotxIframe();
      if(video) videojs(video).dispose();
      if(spotxIframe) {
        removeSpotxIframe();
      }
    }

    if(prevState.playing_Ad !== this.state.playing_Ad) {
      if(this.state.playing_Ad) {
        prepareMazEventData(this, "ad_started");
      } else {
        resetMazEventData(this);
      }
    }

    if(prevProps.streamCounterUpdate !== this.props.streamCounterUpdate && this.props.streamCounterUpdate) {
      this.pushTvodStreams();
    }
    if(isSmartTv() && prevProps.showSubtitle != this.props.showSubtitle) {
      toggleSubtitlesVisibility(current_player, this.props.showSubtitle);
    }
  }

  componentWillUnmount(){
    this.pushTvodStreams();
    prepareMazEventData(this, "video_exited");
    this.sendWatchedVideoAnalytics();
    analytics.setEventForSimlive(this.props.feeds, window.location.pathname, 'Video','Simulated Live Play', this.state.contents[this.state.index].title, this.videoCount, this.state.contents[this.state.index].contentUrl)

    var video = document.getElementById("fake-video")
    if (getSpotxIframe()) {
      removeSpotxIframe();
    } else if(video && !configuration.isAppTvodType) {
      this.props.rememberSpot(this.props.app_id, this.props.section.identifier,
        parseInt(videojs(video).currentTime()), parseInt(videojs(video).duration()), false, this.props.section.type,
        this.props.logged_in, this.props.user.auth_token, this.props.user.user_id, this.state.contents[this.state.index].identifier);
    }
    if (video) {
      videojs(video).dispose();
    }
    this.clearSimulatorInterval();

    if(isSmartTv()) {
      if(configuration.use_light_sliders) {
        addRemoveAllNavigation({ id: this.id });
        addRemoveAllNavigation({ id: this.inline_id });  
      }

      document.removeEventListener('keyup', this.handleRemoteClick);
      this.hideNavbar(true);
    }
  }

  sendWatchedVideoAnalytics() {
    const { index, contents } = this.state;
    const time = Math.floor((new Date() - this.videoStartedAt) / 1000);
    analytics.setEventForWatchedVideo(contents[index].contentUrl, this.props.feeds, window.location.pathname, contents[index].title, time, this.videoCount);
  }

  onFreeContentOver() {
    this.setState({freeContentOver: true})
    this.clearSimulatorInterval();
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
  }
 
  backButton(e){
    this.props.closeModal()
    this.props.updateVideoCount(this.state.video_count + 1)
    const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
    this.props.history.replace(backUrlObj.backPath);
    if(isSmartTv() && document.getElementById("nav-bar")) {
      document.getElementById("nav-bar").style.display = '';
    }
    if(e) e.preventDefault();
  }


  maintainTitle(){
    let displayTitle = this.props.section.displayTitle;
    let metering_type = null;
    const METERING_TYPE = getMeteringTypes();
    if (this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall)) {
      if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) {
        metering_type = METERING_TYPE.VIEWS
      } else if (this.props.section.access && this.props.section.access.timed !== undefined) {
        metering_type = METERING_TYPE.TIME
      }
    }
    

    this.handleMetering(false);
    if (displayTitle && displayTitle.type === "duration"){
      this.handleTitle(true)
      var dTime = displayTitle.value*1000
      setTimeout(() => {
        this.handleTitle(false);
        this.handleMetering(true);
        if (metering_type === METERING_TYPE.VIEWS) {
          setTimeout(() => {
            this.handleMetering(false);
          }, dTime)
        }
      }, dTime)
    } else if(displayTitle && displayTitle.type == "full"){
      this.handleTitle(true)
      if(metering_type) {
        // if metering hide title after 5 seconds
        setTimeout(() => {
          this.handleTitle(false);
          this.handleMetering(true);
          if (metering_type === METERING_TYPE.VIEWS) {
            // if view metering hide metering after 5 seconds
            setTimeout(() => {
              this.handleMetering(false);
              this.handleTitle(true);
            }, 5000)
          }
        }, 5000)
      }
    } else {
      this.handleTitle(false)
      this.handleMetering(true);
      if (metering_type === METERING_TYPE.VIEWS) {
        setTimeout(() => {
          this.handleMetering(false);
        }, 5000)
      }
    }
  }

  handleMetering(show) {
   this.setState({showMetering: show})
  }

  handleTitle(show){
    this.setState({showTitle: show});
  }
  
  render(){
    let inlineVal = this.props.section.inlineMenu || "";
    if(inlineVal && this.state.showPlayButton) {
      inlineVal = "limited";
    }
    let navbar_present = document.getElementsByClassName("nav-view")[0] || false;
    const isSectionLevel = checkIfSectionLevelContent();
    
    // if(isSmartTv() && document.getElementById("nav-bar")) document.getElementById("nav-bar").style.display = 'none';
    let margin_style = {};
    if(isSmartTv() && isSectionLevel) {
      margin_style = {marginTop: '3em'}
    }

    const runningOnMobile = this.props.runningOnMobile;
    return (
      <div className="videoFull" style={{top: 0, left: 0, position: "absolute"}}>
        <div key={`${this.state.contents[this.state.index].identifier}-${this.state.adBreak}`} className="fake-video-ad">
          <video className={"video-js vjs-default-skin video-content-" + this.state.contents[this.state.index].identifier} id="fake-video" preload="10" onEnded={(index) => this.setNextFakeVideo(this.state.index, true)} disablePictureInPicture />
          {
            isSmartTv()
            ? (<span className="icon-hollow_arrow" style={{display:'none'}}><a id="overlay_video_back_btn" href="" onClick={(event) => this.backButton(event)} className="backBtn" style={{zIndex: 100}}></a></span>
            )
            : (!navbar_present && !isSectionLevel && 
              <span className="icon-hollow_arrow">
                <a href="" onClick={(event) => this.backButton(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100}}>
                  {runningOnMobile && <img src={backMobile} alt="Back"/>}
                </a>
              </span>
            )
          }
        </div>
        {this.state.showPlayButton && <div id="video-play-extra-button" className="video-play-extra-button">
          <img alt="Play" className="focusable" tabIndex={getTabIndex()} src={playButton} onClick={() => this.startVideo()}/>
        </div>}
        {!configuration.isAppTvodType && !this.state.showPlayButton && this.props.section.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall) &&
          <Metering
            content_id={this.props.section.identifier}
            parent_id={this.props.section.identifier} 
            contentAccess={this.props.section.access}
            feeds={this.props.feeds}
            onFreeContentOver={() => this.onFreeContentOver()}
            playing_Ad={this.state.playing_Ad}
            showMetering={this.state.showMetering}
            content={this.props.section}
            isLive={true}
            interstitialModalDetails={this.props.modal && this.props.modal.interstitialProps}
            video_start={this.state.video_start}
          />

        }
        {!runningOnMobile && this.state.showTitle && this.state.video_title && !this.state.playing_Ad && <div className="prevThumbnail video-title ellipsis" style={ margin_style}>
          <p className=""> <b>{this.state.video_title}</b></p>
        </div>}
        {!runningOnMobile && !this.state.playing_Ad && <InlineMenu
          all_contents={this.props.section.contents}
          feeds={this.props.feeds}
          next={(index) => this.setNextFakeVideo(index - 1)}
          playing={this.state.index}
          inlineMenu={inlineVal}
          fake={true}
        />}
        {/* Uncomment below line if want to show play, pause, rewind and forward icon on action */}
        {/* {isSmartTv() && !this.state.showPlayButton  && this.state.afterMount && <PlayerIcon  videoId = {'fake-video_html5_api'}/>} */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  user: state.user.user,
  userEngaged: state.user.userEngaged,
  ads: state.ads,
  subscription: state.user.subscription,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model,
  showSubtitle: state.feeds.showSubtitle,
  isUserOnline: state.user.isUserOnline,
  allStreamObject: state.streams.allStreamObject
})

const mapDispatchToProps = {
  rememberSpot: localRememberSpot,
  closeModal: closeModal,
  openModal: openModal,
  updateVideoCount: updateVideoCount,
  pushTvodStreamData: pushTvodStreamData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FakeLive));
