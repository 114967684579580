import React from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom'

import { localRememberSpotBatch } from '../actions/rememberSpotActions'
import { closeModal, modalProgress,openModal } from '../actions/modalActions'
import { httpsUrl, getSpotxIframe, removeSpotxIframe, getSpotXId, DEEPLINK, INLINE_MENU_IDLE_TIMEOUT, EPG_THEME, PLATFORM_HTML_SIMULATOR, MAZ_REPORTING_INTERVAL, PLATFORM_LG, PLATFORM_VIZIO, PLATFORM_SAMSUNG, PLATFORM_XBOX } from "../constants/common"
import { updateVideoCount } from "../actions/adsActions"
import { environment } from "../constants/initializer"
import isAvailable from '../helperfunctions/is_available'
import InlineMenu from "./InlineMenu";
import * as analytics  from '../constants/analytics';
import Metering from './Metering';
import { getMeteringTypes } from '../constants/common';
import { checkIfSafari, getImageUrlBaseOnSize, onVideoError, getTabIndex, onCTVVideoError, getItemCuePoints, checkCorrectPlatform, getSecondsFromDuration, getVttCaption } from '../helperfunctions/common';
import { backButtonObj, getParentFeed } from '../helperfunctions'
import { isSmartTv } from '../helperfunctions/common';
import { showAdsAsPerFrequency, runAdInVideo, constructSpotxParams } from '../helperfunctions/adHelper';
import backMobile from "../images/icons/back-mobile.png";
import configuration from '../constants/config';
import { sendVideoMinutes } from '../helperfunctions/mazAnalytics';
import playButton from "../images/icons/play-button.png";
import { pushTvodStreamData } from '../actions/streamActions';
import PlayerIcon from './icons/PlayerIcon';

import 'videojs-contrib-ads';
import 'videojs-ima';
import { addRemoveAllNavigation, keyMappings, retainFocusOnBackPress, toggleSubtitlesVisibility } from '../helperfunctions/ctvHelper';
import PlayerProgressBar from './icons/PlayerProgressBar';
var videojs = require('video.js');

let hideMenuTimeout = null;
let current_player,current_src,current_type;

function videoType(url){
  url = url.split('.')
  if (url[url.length - 1].split('?')[0] == "m3u8"){
    return "application/x-mpegURL"
  } else {
    return "video/mp4"
  }
}

function NextVideoThumbnail(){
  var spotxIframe = getSpotxIframe();
  if(!spotxIframe){
    if(document.getElementById("thumb-video_html5_api")){
      var video = document.getElementById("thumb-video_html5_api");
    } else {
      var video = document.getElementById("thumb-video");
    }
    if(video && (Math.ceil(video.duration - video.currentTime) < 11)){
      if (document.getElementById("next-video")) {
        document.getElementById("next-video").style.display = "inline"
        // upnext and title does not appear together, when upNext ui is coming
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "none";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "none";
      }
      if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}    
      if (document.getElementsByClassName("nextVideoSeconds")[0]) document.getElementsByClassName("nextVideoSeconds")[0].innerHTML = Math.ceil(video.duration - video.currentTime)
    } else {
      if (document.getElementById("next-video") && document.getElementById("next-video").style.display === "inline" ){
        document.getElementById("next-video").style.display = "none";
        if (document.querySelector(".prevThumbnail.video-title")) document.querySelector(".prevThumbnail.video-title").style.display = "block";
        if (document.getElementById("MeteringCounter")) document.getElementById("MeteringCounter").style.display = "block";
        if (document.getElementById("video-inlineMenu")) {
          document.getElementById("video-inlineMenu").style.display = "inherit";
          hideMenuTimeout = setTimeout(() => {
            if (document.getElementById("video-inlineMenu")){
              document.getElementById("video-inlineMenu").style.display = "none" ;
            }
          }, INLINE_MENU_IDLE_TIMEOUT)
        }    
      }
    }
  }
}

function get_parent_section(section_id,contents){
  var sections = this.props.feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  if(isNaN(pathLocation[0])){return "settings"}
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        sections = sections[j].contents
      }
    }
  }
  if(content.regularLayout === "detail" || pathLocation.length < 3){
    pathLocation = pathLocation.slice(0,-1)
  } else {
    pathLocation = pathLocation.slice(0,-2)
  }
  pathLocation = pathLocation.join("/")
  return pathLocation
}

function getEPGRememberTime(modal, identifier) {
  if(!modal || !modal.epgPayload || !identifier) return null;
  const epg = modal.epgPayload;
  if(epg && epg.forwardDuration && 
    epg.identifier === identifier && // same show
    (new Date().getTime() <= (epg.time + (20 * 1000))) // with in 20 seconds considernig ads
  ) {
    return epg.forwardDuration;
  }
  return null;
}

function showVideoAd(state, props){
  var adToShow = props.ads.spotxid.length > 0;
  const feedAd = isAdForFeed(props.modal, state.section);
  adToShow = adToShow && feedAd && window.SpotX
  var showAd = false
  if(adToShow){
    showAd = showAdsAsPerFrequency(props.ads, state.video_count);
  }

  if(showAd){
    analytics.setEvent('Ads', 'Video Ad Play', 'SpotX')
  }
  // return false;
  return showAd
}

function isAdForFeed(modal, section) {
  if(section.mazContentType === "saved") return false; // saved section in mazId feed
  if(modal.head === "saved_items") return false; // saved section
  return (modal.show_ad || section.showAd);
}

function PlayVideo(url, type, next_section, props, state, showAd, _this){
  const isEpg = state.isEPG;
  if(showAd && !isSmartTv()){
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var adContainer = document.getElementsByClassName('videoFull')[0];
    const cust_params = constructSpotxParams(props.feeds, state.section);
    if(document.getElementById("thumb-video_html5_api")){
      adContainer = document.getElementById("thumb-video");
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video_html5_api"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    } else {
      var directAdOS = new window.SpotX.DirectAdOS({
        channel_id: getSpotXId(props.ads),
        slot: adContainer,
        video_slot: document.getElementById("thumb-video"),
        hide_skin: false,
        autoplay: true,
        custom: {...cust_params}
      });
      directAdOS.loadAd();
    }
  } else {
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    const vTime = props.remember[state.section.identifier];
    let videoParams = { loadingSpinner: false,  html5: {
      nativeTextTracks: false
    }};
    if(props.runningOnMobile && !isEpg) {
      videoParams.controls = true; //this line necessary for mobile to show videojs control bar on screen click 
    }
    if(isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = false;
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = true;
    }
    const player = videojs('thumb-video', videoParams);
    current_player = player;
    current_src = url;
    current_type = type;
    player.on('error', function(e) {
      if(isSmartTv()) {
        onCTVVideoError(e, player);
      } else {
        onVideoError(type, player);
      }
    });
    player.on('pause', () => {
      setTimeout(() => {
        const vid = document.getElementById("thumb-video_html5_api");
        if(_this && _this.state && !_this.state.playing_Ad && vid && vid.paused) {
          prepareMazEventData(_this, "video_paused");
          _this.videoPausedByUser = true;
        }
        // pause event trigger for everything even if video ended so handling it by delaying method call
      }, 1000)
    })
    player.on("play", () => {
      if(_this.videoPausedByUser) {
        resetMazEventData(_this);
        _this.videoPausedByUser = false;
      }
    })
    player.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(props.runningOnMobile) {
        _this.setNextVideo(state.next_index, true)
      }
    });
    const epgTime = getEPGRememberTime(props.modal, state.section.identifier);
    const streamProgress = (configuration.isAppTvodType && props.allStreamObject[state.section.cid] && props.allStreamObject[state.section.cid].progress) || null;
    player.ready(() => {
      player.src({src: url, type: type})
      const feedAd = isAdForFeed(props.modal, state.section);
      runAdInVideo(player, props, state, _this, feedAd, "thumb-video_html5_api", state.section);
      player.play();
      var newVideo = document.getElementById("thumb-video_html5_api")
      if(isSmartTv()) {
        newVideo.removeAttribute('tabIndex');
      }
      newVideo.muted = false
      newVideo.controlsList = "nodownload"
      newVideo.addEventListener("loadedmetadata", function() {
        if(document.getElementById("loader")) {
          document.getElementById("loader").style.display = "none";
        }
        if(epgTime) {
          this.currentTime = epgTime;
        } else if(streamProgress && newVideo.duration) {
          if(streamProgress !== 100) {//to restart the video
            this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
          }
        } else if (vTime && (vTime["p"] != vTime["t"])) {
          this.currentTime = vTime["p"];
        }
      }, {once: true})
      const showVideoAd = props.ads && feedAd && props.ads.videoAdtype && props.ads.videoAdtype !== "spotX" && showAdsAsPerFrequency(props.ads, state.video_count)
      const nonVmapAdBreak = (showVideoAd && (props.ads.nonVmapAdBreakFrequency || props.ads.nonVmapCuePointKey));
      const adBreakData =  nonVmapAdBreak ? {
        state,
        _this, 
        player, 
        nonVmapAdBreakFrequency: props.ads.nonVmapAdBreakFrequency,
        nonVmapCuePointKey: props.ads.nonVmapCuePointKey
      } : null;
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(next_section, adBreakData, _this), false)
      const vttCaption = getVttCaption(state.section);
      vttCaption && player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle}, false);
    })
    
  }
}

function handleVideoTimeUpdate(next_section, adBreakData, _this) {
  if(next_section) {
    NextVideoThumbnail()
  }

  if(isSmartTv()) {
    if(document.getElementById("next-video") && document.getElementById("next-video").style.display == 'inline') {
      if(_this.state.ctv_focus_on != '#next-video') {
        _this.setState({ ctv_focus_on: '#next-video' })
      }
    } else {
      if(_this.state.ctv_focus_on != '#inline_arrow') {
        _this.setState({ ctv_focus_on: '#inline_arrow' })
      }
    }
  }

  if(document.getElementById("thumb-video_html5_api")){
    var video = document.getElementById("thumb-video_html5_api");
  } else {
    var video = document.getElementById("thumb-video");
  }

  if(video) {
    handleVideoMinutes(video, _this)
  }

  if(adBreakData && video) {
    const currentTime = Math.floor(video.currentTime);
    const {state, _this, player, nonVmapAdBreakFrequency, nonVmapCuePointKey} = adBreakData;
    let timeMatched = false;
    if(nonVmapAdBreakFrequency && (currentTime % (nonVmapAdBreakFrequency * 60) === 0)) {
      timeMatched = true;
    } else if(nonVmapCuePointKey && state.section && state.section.cuePoints) {
      const cuePoints = getItemCuePoints(state.section.cuePoints);
      if(cuePoints.indexOf(currentTime) > -1) {
        timeMatched = true;
      }
    }
    if(!state.playing_Ad && currentTime !== 0 && currentTime !== state.adBreak && timeMatched) {
      _this.pushTvodStreams();
      _this.saveVideoTimeInRemeberSpot(video);
      if(player) player.dispose()
      _this.setState({adBreak: currentTime});
    }
  }

  if(_this.state && _this.state.isEPG && _this.state.section && !_this.state.playing_Ad) {
    const { epgEndTimeSec, epgStartTimeSec, epgContainerEndTimeSec, epgContainerStartTimeSec} = _this.state.section;
    const nowTime = getSecondsFromDuration(new Date().toLocaleTimeString('en-GB'));
    if(nowTime > (epgEndTimeSec % 86400) || nowTime > (epgContainerEndTimeSec % 86400)) {
      // Time ended start next video
      if(_this.state.adWrapperExist) _this.setState({adWrapperExist: false});
      _this.setNextVideo(_this.state.next_index);
    } else if(nowTime < (epgStartTimeSec % 86400) || nowTime < (epgContainerStartTimeSec % 86400)) {
      // Stop player
      _this.setNextVideo();
    }
  }
}

function handleVideoMinutes(videoEl, _this) {
  const time = Math.floor(videoEl.currentTime);
  const tier = videoEl.videoHeight;
  if(time !== _this.streamedVideoTime) {
    _this.streamedVideoTime = time;
    _this.streamedVideoSeconds++;
  }
  if(tier && _this.streamedVideoTier && tier !== _this.streamedVideoTier) {
    prepareMazEventData(_this, "tier_changed");
  } else if(_this.streamedVideoSeconds === MAZ_REPORTING_INTERVAL) {
    prepareMazEventData(_this, "beacon");
  }

  if(tier) {
    _this.streamedVideoTier = tier;
  }
}

function prepareMazEventData(_this, type) {
  if(_this.props.modal && _this.props.modal.videoTrailer) return;

  const {streamedVideoSeconds, streamedVideoTier, videoStartTime, state, props} = _this;
  if(streamedVideoSeconds == null || !streamedVideoTier || !state) {
    return;
  }
  const {section: { identifier, contentUrl }, video_path, video_title} = state;
  const data = {
    eventValue: streamedVideoSeconds.toString(),
    eventStartTime: videoStartTime,
    tierStreamed: `${streamedVideoTier}px`,
    contentId: identifier,
    contentUrl: contentUrl,
    eventAction: type
  }

  resetMazEventData(_this);
  sendVideoMinutes(data, props.feeds, video_path, video_title)
}

function resetMazEventData(_this) {
  if(_this) {
    _this.videoStartTime = new Date().toISOString();
    _this.streamedVideoSeconds = 0;
  }
}

function NextVideo(all_contents, index){
  var content;
  var nextIndex;
  for(var i=index+1; i<all_contents.length; i++){
    if(all_contents[i].type === "vid") {
      content = all_contents[i]
      nextIndex = i
      break;
    }
  }
  return [content, nextIndex]
}

function ChangeRoute(identifier, attachMode){
  var currentLocation = window.location.pathname.split('/')
  currentLocation = currentLocation.slice(0,-1)
  currentLocation.push(identifier)
  currentLocation = currentLocation.join("/")
  if(configuration.isAppTvodType && attachMode) {
    return `${currentLocation}?mode=watch`
  }
  return currentLocation
}

function FindVideo(identifier, all_contents){
  var videoIndex = 0;
  for(var i=0; i<all_contents.length; i++){
    if(identifier === all_contents[i].identifier){
      videoIndex = i;
      break;
    }
  }
  return videoIndex;
}

//Function to toggle fullscreen on dbl click (Safari only, Not implemented yet), Just call this function on video double click
function toggleFullscreen(video) {
  if (checkIfSafari()) {
    if (document.webkitIsFullScreen) {
      document.webkitExitFullscreen();
    } else {
      video.webkitRequestFullscreen && video.webkitRequestFullscreen();
    }
  }
}

function onVideoPauseCheck() {
  const videoObj = document.getElementsByTagName('video')[0];
  if (videoObj && !videoObj.controls) {
    if(videoObj.play) videoObj.play();
    videoObj.removeEventListener('pause', onVideoPauseCheck)
  }
}
class OverlayVideo extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      all_contents: "",
      index: 0,
      type: "",
      section: '',
      next_section: '',
      next_index: 0,
      next_video: false,
      currentView: '',
      rememberSpotBatch: {},
      adBreak: 0,
      lineage: "",
      video_count: 0,
      video_title: this.props.modal.content.title,
      purchased: false,
      playing_Ad: false,
      adWrapperExist: false,
      videoInQueue: undefined,
      showTitle: false,
      showMetering: false,
      isEPG: this.props.modal.head === EPG_THEME,
      showPlayButton: !this.props.userEngaged,
      video_start: false,
      afterMount: false,
      ctv_focus_on: '#inline_arrow'
    }
    this.maintainTitle = this.maintainTitle.bind(this)
    this.updateState = this.updateState.bind(this)
    this.onAdComplete = this.onAdComplete.bind(this)
    this.onAdStart = this.onAdStart.bind(this)
    this.onAdReady = this.onAdReady.bind(this)
    this.onAllAdComplete = this.onAllAdComplete.bind(this)
    this.simulatorInterval = null;
    this.simulatorCount = 0;
    this.videoStartedAt = new Date();
    this.streamedVideoTier = null;
    this.streamedVideoSeconds = 0;
    this.streamedVideoTime = 0;
    this.videoStartTime = new Date().toISOString();
    this.videoPausedByUser= false;
    this.video_stream_buffered = 0;
  }

  updateState(){
    if (this.props.modal.deepLink !== DEEPLINK && !isSmartTv()) {
      const sectionItem = document.getElementsByClassName("item-" + this.state.section.identifier)[0]
      sectionItem && sectionItem.classList.add("slideHover")
    }
    document.body.classList.remove("noNav")
    // var video = document.getElementById("thumb-video");

    // var spotxIframe = getSpotxIframe();

    // if(spotxIframe) {
    //   removeSpotxIframe();
    // } else {
    //   this.saveVideoTimeInRemeberSpot(video);
    //   if(video) videojs(video).dispose();
    // }
  }

  saveVideoTimeInRemeberSpot = (videoEle) => {
    if(configuration.isAppTvodType) return;

    var rememberSpotBatch = {...this.state.rememberSpotBatch}
      rememberSpotBatch[this.state.section.identifier] = {
        p: videojs(videoEle).currentTime(),
        t: videojs(videoEle).duration(),
        saved: false,
        type: this.state.section.type
      }
      const {t, p} = rememberSpotBatch[this.state.section.identifier];
      if (!isNaN(t) && !isNaN(p)) {
        this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
          this.props.user.user_id)
      }
  }

  onLinkCLick(e) {
    this.updateState()
    this.props.closeModal()
    this.props.updateVideoCount(this.state.video_count + 1)

    if (this.props.modal.deepLink === DEEPLINK) {
      const backUrlObj = backButtonObj(this.props.feeds, window.location.pathname.split('/').slice(3).join('/'));
      this.props.history.replace(backUrlObj.backPath);
      this.props.modal.callbackParent(this.state.section.identifier);
    } else {
      window.history.replaceState("view", {}, this.props.modal.pathname)
    }

    e && e.preventDefault()
  }

  componentWillMount(){
    document.body.classList.add("noNav")
    var videoIndex = FindVideo(this.props.section.identifier, this.props.all_contents);
    let section = this.props.section;
    if(this.props.modal.head === "search"){
      section = this.addParentFeedDataForSearch(section);
    }
    if (section.mazContentType && section.lineage) {
      section = this.getSectionParentFromLineage(section)
    }
    this.setState({all_contents: this.props.all_contents})
    var type = videoType(section.contentUrl)
    var nextVideoData = NextVideo(this.props.all_contents, videoIndex)
    this.setState({
      type: type,
      section: section,
      index: videoIndex,
      video_path: this.props.modal.firstpath,
      next_section: nextVideoData[0],
      next_index: nextVideoData[1]
    })
  }

  handleRemoteClick = (e) => {
    if(parseInt(e.keyCode) != 10009 && document.getElementById("video-inlineMenu") && !document.getElementById('video-inlineMenu').classList.contains('closedMenu')) {
      return;
    }

    const isFocusOnControls = document.querySelector('#custom-control-bar') && document.querySelector('#custom-control-bar').contains(document.activeElement);

    const player = document.getElementById("thumb-video_html5_api");
    let current_time;
    if(this.state.playing_Ad || this.state.isEPG) return;

    switch(keyMappings(e.keyCode)) {
      case 'OK':
        if (isFocusOnControls) break;
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;
      
      case 'PLAY': //media play
        if(player && player.paused) {
          player.play();
        }
      break;

      case 'PAUSE': //media pause
        if(player && !player.paused) {
          player.pause();
        }
      break;

      case 'PLAY-PAUSE': //media playpause
        if(player && player.paused) {
          player.play();
        } else {
          player && player.pause();
        }
      break;

      case 'LEFT': // left
        if (isFocusOnControls) break;
        current_time = player.currentTime;
        player.currentTime = current_time - 5;
      break;
      
      case 'RIGHT': // right
        if (isFocusOnControls) break;
        current_time = player.currentTime;
        player.currentTime = current_time + 5;
      break;

      case 'FORWARD': //forward
        current_time = player.currentTime;
        player.currentTime = current_time + 5;
      break;

      case 'RIGHT': // backward
        current_time = player.currentTime;
        player.currentTime = current_time - 5;
      break;

      case 'STOP': // stop video
        if(checkCorrectPlatform([PLATFORM_LG]) && document.getElementById("overlay_video_back_btn")) {
          document.getElementById("overlay_video_back_btn").click();
          retainFocusOnBackPress(window.last_focus, null, window.location.pathname);
        } else {
          if(player && !player.paused) {
            player.pause();
            player.currentTime = 0;
          }
        }
      break;
    }
  }

  componentDidMount(){
    this.setState({afterMount: true})
    this.setVideoAnalytics();
    if(!configuration.isAppTvodType) {
      window.history.pushState("new video", {}, this.props.modal.firstpath)
    }
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: true})
    if(!this.state.showPlayButton) {
      this.startVideo()
    } 
    document.body.addEventListener("mousemove", this.cancelHideMenuTimeout, true);

    if(document.getElementById("thumb-video")) {
      document.getElementById("thumb-video").focus();
    }
    if(isSmartTv()) {
      if(document.getElementById('video-inlineMenu')) {
        document.getElementById('video-inlineMenu').focus();
      }

      if(document.getElementById('thumb-video_html5_api')) {
        document.getElementById('thumb-video_html5_api').focus();

        document.getElementById('thumb-video_html5_api').addEventListener('loadedmetadata',(e) => {
          this.setState({ video_start: true });
        },{once: true});
      }
      document.addEventListener('keyup', this.handleRemoteClick);
      
      if(configuration.use_light_sliders) {
        this.video_id = addRemoveAllNavigation({ selector: '.videoFull .focusable' });
      }
    }

    if(configuration.is_simulator && checkCorrectPlatform(PLATFORM_HTML_SIMULATOR) && !checkIfSafari()) {
      // For Bug simulator Bug https://github.com/MagAppZine/MagAppZine-MagControl/issues/8267
      this.simulatorInterval = setInterval(() => {
        this.checkVideoStatusForSimulator()
      },1000)
    }
  }

  startVideo() {
    const video = document.getElementById("thumb-video");
    if(video) {
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      }
      this.setState({
        playing_Ad: showAd,
        showPlayButton: false
      })
    }
  }

  clearSimulatorInterval() {
    if(this.simulatorInterval) {
      clearInterval(this.simulatorInterval);
      this.simulatorInterval = null;
    }
  }
 
  checkVideoStatusForSimulator() {
    if(!this.state.playing_Ad) {
      this.simulatorCount++;
      if(this.simulatorCount > 5) {
        const player = videojs('thumb-video');
        if(player && !player.paused() && player.readyState() == 0) {
          player.trigger("error");
          player.pause();
          if(document.getElementById('thumb-video')) document.getElementById('thumb-video').classList.add("vjs-error")
        }
      }
    }
  }

  onAdReady() {
    const adCont = document.getElementById("thumb-video_ima-ad-container");
    if(adCont) {
      if(!adCont.classList.contains("bumpable-ima-ad-container")) {
        this.setState({adWrapperExist: true})
      }
    }
  }

  onAdStart(ev) {
    this.current_ad = ev.getAd();
    this.setState({playing_Ad: true})
  }

  onAdError() {
    this.setState({
      adWrapperExist: false,
      playing_Ad: false
    })
  }

  updateSrcInSmartTv(_type) {
    let ad_url = this.props.ads.adURL;
    let isVapidAd = ad_url.includes('vpaid');
    if((_type === 'ad_com' && isVapidAd) || (_type === 'ad_all_com' && !isVapidAd)) return;

    let adPod = this.current_ad.getAdPodInfo()
    let podInfo = {
      podPosition: adPod.getAdPosition(),
      podLength: adPod.getTotalAds()
    }
    const _this = this;
    if (podInfo.podPosition == podInfo.podLength) { 
      current_player.src({ src: current_src, type: current_type });
      current_player.ready(() => {
        current_player.play()
        var newVideo = document.getElementById("thumb-video_html5_api")
        newVideo.addEventListener('play', () => {
          // set the video playback time
          const vTime = _this.props.remember[_this.state.section.identifier];
          const epgTime = getEPGRememberTime(_this.props.modal, _this.state.section.identifier)
          const streamProgress = (configuration.isAppTvodType && _this.props.allStreamObject[_this.state.section.cid] && _this.props.allStreamObject[_this.state.section.cid].progress) || null;
          if(current_player && current_player.remoteTextTracks() && current_player.remoteTextTracks()[0] && current_player.remoteTextTracks()[0].src) {
            current_player.remoteTextTracks()[0].mode='showing';
          }
          if(epgTime) {
            current_player.currentTime(epgTime);
          } else if(streamProgress && newVideo.duration) {
            current_player.currentTime(Math.floor((streamProgress * newVideo.duration) / 100));
          } else if (vTime && (vTime["p"] != vTime["t"])) {
            current_player.currentTime(vTime["p"]);
          }
        });
        const vttCaption = getVttCaption(_this.state.section);
        vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: _this.props.showSubtitle});
      });
    }
  }

  onAdComplete() {
    if(isSmartTv()) {
      setTimeout(() => {
        this.updateSrcInSmartTv('ad_com');
      },0);
    }
    this.setState({playing_Ad: false})
    this.maintainTitle();
  }
  
  onAllAdComplete() {    
    if(isSmartTv()) {
      this.updateSrcInSmartTv('ad_all_com');
    }
    this.setState({adWrapperExist: false});
    if(this.state.videoInQueue >= 0) {
      this.setNextVideo(this.state.videoInQueue);
    }
  }

  cancelHideMenuTimeout() {
    if (hideMenuTimeout) {
      clearTimeout(hideMenuTimeout);
      hideMenuTimeout = null;
    }
  }

  getSectionParentFromLineage = (section) => {
    const url = `${section.lineage}/${section.identifier}`;
    let parent = getParentFeed(this.props.feeds, url)
    const {locked, access, special, displayTitle, registerWall, identifier, showVideoAd} = parent;
    return {
      ...section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      showAd: showVideoAd
    }
  }


  maintainTitle(){
    let displayTitle = this.props.modal.content.displayTitle;
    if (["saved_items", "search"].includes(this.props.modal.head)){
      displayTitle = this.props.feeds.displayTitle;
    }
    let metering_type = null;
    const METERING_TYPE = getMeteringTypes();
    if ((!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) && 
    this.state.section.locked)) {
      if (this.props.feeds.ctaMetadata && this.props.feeds.ctaMetadata.metering) {
        metering_type = METERING_TYPE.VIEWS
      } else if (this.state.section.access && this.state.section.access.timed !== undefined) {
        metering_type = METERING_TYPE.TIME
      }
    }

    this.handleMetering(false);
    if (displayTitle && displayTitle.type === "duration"){
      this.handleTitle(true)
      var dTime = displayTitle.value*1000
      setTimeout(() => {
        this.handleTitle(false);
        this.handleMetering(true);
        if (metering_type === METERING_TYPE.VIEWS) {
          setTimeout(() => {
            this.handleMetering(false);
          }, dTime)
        }
      }, dTime)
    } else if(displayTitle && displayTitle.type == "full"){
      this.handleTitle(true)
      if(metering_type) {
        // if metering hide title after 5 seconds
        setTimeout(() => {
          this.handleTitle(false);
          this.handleMetering(true);
          if (metering_type === METERING_TYPE.VIEWS) {
            // if view metering hide metering after 5 seconds
            setTimeout(() => {
              this.handleMetering(false);
              this.handleTitle(true);
            }, 5000)
          }
        }, 5000)
      }
    } else {
      this.handleTitle(false)
      this.handleMetering(true);
      if (metering_type === METERING_TYPE.VIEWS) {
        setTimeout(() => {
          this.handleMetering(false);
        }, 5000)
      }
    }
  }

  handleMetering(show) {
   this.setState({showMetering: show})
  }

  handleTitle(show){
    this.setState({showTitle: show})
  }


  setVideoAnalytics(videoAutoplay, section=null, videoTitle=null, path=null){
    if(this.props.modal && this.props.modal.videoTrailer) return;

    if (section === null) {
      section = this.state.section
    }
    if (videoTitle === null) {
      videoTitle = this.state.video_title
    }
    if (path === null) {
      path = this.props.modal.firstpath
    }
    const contentUrl =  section.contentUrl;
    if(videoAutoplay){
      if(path.includes("search") || path.includes("saved_items")){
        if(this.props.modal.head === "search"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Autoplay (search - locked)', videoTitle, contentUrl)   
          }else{
            analytics.setEvent('Video', 'Video Autoplay (search - unlocked)', videoTitle, contentUrl)   
          }
        }
        else if(this.props.modal.head === "saved_items"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Autoplay (saved - locked)', videoTitle, contentUrl)   
          }else{
            analytics.setEvent('Video', 'Video Autoplay (saved - unlocked)', videoTitle, contentUrl)   
          }
        }
      }
      else{
        analytics.setEventForContent(this.props.feeds, path, 'Video', 'Video Autoplay', videoTitle, contentUrl)
      }
    }else{
      if(path.includes("search") || path.includes("saved_items")){
        if(this.props.modal.head === "search"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Play (search - locked)', videoTitle, contentUrl)   
          }else{
            analytics.setEvent('Video', 'Video Play (search - unlocked)', videoTitle, contentUrl)   
          }
        }
        else if(this.props.modal.head === "saved_items"){
          if(section.locked){
            analytics.setEvent('Video', 'Video Play (saved - locked)', videoTitle, contentUrl)   
          }else{
            analytics.setEvent('Video', 'Video Play (saved - unlocked)', videoTitle, contentUrl)   
          }
        }
      }
      else{
        analytics.setEventForContent(this.props.feeds, path, 'Video', 'Video Play', videoTitle, contentUrl)
      }

    }
  }

  sendWatchedVideoAnalytics() {
    if(this.props.modal && this.props.modal.videoTrailer) return;

    const { section, video_title, video_path } = this.state;
    const time = Math.floor((new Date() - this.videoStartedAt) / 1000);
    analytics.setEventForWatchedVideo(section.contentUrl, this.props.feeds, video_path, video_title, time);
  }

  componentDidUpdate(prevProps, prevState){
    if(isSmartTv() && (prevProps.isUserOnline != this.props.isUserOnline)) {
      if(this.props.isUserOnline) {
        // restore video
        current_player.src({ src: current_src, type: current_type });
        current_player.ready(() => {
          current_player.play()
          const newVideo = document.getElementById("thumb-video_html5_api")
          if(newVideo) {
            newVideo.addEventListener('play', () => {
              if(this.video_stream_buffered) {
                current_player.currentTime(this.video_stream_buffered);
              }
            }, { once: true });

            const vttCaption = getVttCaption(this.state.section);
            vttCaption && current_player.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle});
          }
        });
      } else {
        this.video_stream_buffered = current_player.bufferedEnd();
      }
    }

    //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
    this.props.modalProgress({head: this.props.modal.head, index: this.state.index, item: this.state.section.identifier, first: false})
    const video = document.getElementById("thumb-video");
    if(prevState.index !== this.state.index && video) {
      // checking if video changed and video tag hasn't been disposed
      //analytics.setEventForContent(this.props.feeds, window.location.pathname, 'Video', 'Video Play')
      //this.setVideoAnalytics();
      let showAd = false
      if(this.props.ads.spotxid !== undefined){
        showAd = showVideoAd(this.state, this.props)
      }
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, showAd, this)
      if(!showAd) {
        this.maintainTitle()
      } else {
        this.setState({playing_Ad: true, showTitle: false, showMetering: false})
      }
      this.simulatorCount = 0;
      this.videoStartedAt = new Date();
      //for tvod check if next video is locked
      if(configuration.isAppTvodType && this.props.validateNextVideo) {
        this.props.validateNextVideo(this.state.section);
      }
    } else if(prevState.adBreak !== this.state.adBreak && video) {
      this.simulatorCount = 0;
      PlayVideo(httpsUrl(this.state.section.contentUrl), this.state.type, this.state.next_section, this.props, this.state, false, this)
    }
    if(prevState.playing_Ad !== this.state.playing_Ad) {
      if(this.state.playing_Ad) {
        prepareMazEventData(this, "ad_started");
      } else {
        resetMazEventData(this);
      }
    }
    if(prevProps.streamCounterUpdate !== this.props.streamCounterUpdate && this.props.streamCounterUpdate) {
      this.pushTvodStreams();
    }
    if(isSmartTv() && prevProps.showSubtitle != this.props.showSubtitle) {
      toggleSubtitlesVisibility(current_player, this.props.showSubtitle);    
    }
  }

  playOriginalVideo(){
    const isEpg = this.state.isEPG
    this.setState({playing_Ad: false})
    this.maintainTitle()
    if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "inherit"}    
    if (document.getElementById("next-video")) document.getElementById("next-video").style.display = "none"
    var rememberSpotId = this.props.remember[this.state.section.identifier]
    var next_section = this.state.section
    var url = httpsUrl(this.state.section.contentUrl)
    let videoParams = { loadingSpinner: false, html5: {
      nativeTextTracks: false
    }};
    if(this.props.runningOnMobile && !isEpg) {
      videoParams.controls = true;
      videoParams.muted = false;
    }
    if(isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX])) {
      videoParams.loadingSpinner = true;
      videoParams.controls = false;
    } else if(checkCorrectPlatform([PLATFORM_XBOX])) {
      // videoParams.html5 = {
      //   nativeAudioTracks: true,
      //   nativeVideoTracks: true,
      //   nativeTextTracks: false,
      //   hls: {
      //     overrideNative: false
      //   }
      // };
      videoParams.loadingSpinner = true;
      videoParams.controls = true;
    }
    this.video = videojs("thumb-video", videoParams);
    const _video = this.video;
    const _videoType = videoType(this.state.section.contentUrl);
    _video.on('error', function(e) {
      if(isSmartTv()) {
        onCTVVideoError(e, _video);
      } else {
        onVideoError(_videoType, _video);
      }
    });
    _video.on('pause', () => {
      setTimeout(() => {
        const vid = document.getElementById("thumb-video_html5_api");
        if(this && this.state && !this.state.playing_Ad && vid && vid.paused) {
          prepareMazEventData(this, "video_paused");
          this.videoPausedByUser = true;
        }
        // pause event trigger for everything even if video ended so handling it by delaying method call
      }, 1000)
    })
    _video.on("play", () => {
      if(this.videoPausedByUser) {
        resetMazEventData(this);
        this.videoPausedByUser = false;
      }
    })
    _video.on('ended', () => {
      // <Video> onEnded event not triggering for mobile devices so handling that here
      if(this.props.runningOnMobile) {
        this.setNextVideo(this.state.next_index, true)
      }
    });
    const epgTime = getEPGRememberTime(this.props.modal, this.state.section.identifier)
    const streamProgress = (configuration.isAppTvodType && this.props.allStreamObject[this.state.section.cid] && this.props.allStreamObject[this.state.section.cid].progress) || null;
    this.video.ready(() => {
      this.video.src({src: url, type: videoType(this.state.section.contentUrl)});
      this.video.play()
      var newVideo = document.getElementById("thumb-video_html5_api")
      newVideo.muted = false
      if(!this.props.runningOnMobile && !isEpg) {
      //   // Below line is for firefox bug (https://github.com/MagAppZine/MagAppZine-MagControl/issues/7107)
      //   // Video is getting paused and controls off, because of spotx ADOS control the video player.
        newVideo.addEventListener('pause', onVideoPauseCheck)
      }
      newVideo.addEventListener("loadedmetadata", () => {
        if(document.getElementById("loader")) document.getElementById("loader").style.display = "none";
        if(epgTime) {
          this.currentTime = epgTime;
        } else if(streamProgress && newVideo.duration) {
          if(streamProgress !== 100) {//to restart the video
            this.currentTime = Math.floor((streamProgress * newVideo.duration) / 100);
          }
        } else if(rememberSpotId &&
          (rememberSpotId["p"] !== rememberSpotId["t"])){
          newVideo.currentTime = rememberSpotId["p"]
        }
      }, {once: true})
      newVideo.addEventListener("timeupdate", () => handleVideoTimeUpdate(next_section, null, this), false)
      const vttCaption = getVttCaption(this.state.section);
      vttCaption && _video.addRemoteTextTrack({src: vttCaption, default: this.props.showSubtitle}, false);
    });
  }

  componentWillUnmount(){
    this.pushTvodStreams();
    prepareMazEventData(this, "video_exited");
    this.sendWatchedVideoAnalytics();
    document.body.removeEventListener("mousemove", this.cancelHideMenuTimeout, true);
    var video = document.getElementById("thumb-video");
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      removeSpotxIframe();
    } else if(video) {
      this.saveVideoTimeInRemeberSpot(video);
      videojs(video).dispose();
    }

    if(isSmartTv()) {
      document.removeEventListener('keyup', this.handleRemoteClick);

      if(configuration.use_light_sliders) {
        addRemoveAllNavigation({ id: this.video_id });
      }
    }
    this.clearSimulatorInterval();
  }

  addParentFeedDataForSearch(section) {
    const {locked, access, special, displayTitle, registerWall, identifier} = section.parentMenu;
    let newSection = {
      ...section,
      locked: locked,
      access: access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      inlineMenu: this.props.feeds.inlineMenu || "" // inline menu in search as set at app level
    }
    let moreData = getParentFeed(this.props.feeds, (section.lineage + "/" + section.identifier));
    newSection.showAd = moreData && moreData.showVideoAd;
    return newSection;
  }

  setNextVideo(index, prevVideoEnded){
    if(this.state.adWrapperExist && prevVideoEnded && isSmartTv()) return;

    if(document.getElementById("loader")) document.getElementById("loader").style.display = "block";
    if(prevVideoEnded && this.state.adWrapperExist) {
      //ad will play, don't run next video for now
      this.setState({videoInQueue: index});
      return;
    }
    var spotxIframe = getSpotxIframe();
    if(spotxIframe) {
      setTimeout(() => {
        removeSpotxIframe();
        if(document.getElementById("thumb-video")) {
          this.playOriginalVideo()
        };
      }, 100)
    } else {
      if (index >= 0) {
        this.pushTvodStreams();
        prepareMazEventData(this, prevVideoEnded ? "video_ended" : "video_exited");
        this.sendWatchedVideoAnalytics(); // Send analytics of last video seen before any state update
        this.video = document.getElementById("thumb-video");
        var rememberSpotBatch = {...this.state.rememberSpotBatch}
        rememberSpotBatch[this.state.section.identifier] = {
          p: videojs(this.video).currentTime(),
          t: videojs(this.video).duration(),
          saved: false,
          type: this.state.section.type
        }
        // dispose previous one before playing next
        var video = document.getElementById("thumb-video");
        if (video)
          videojs(video).dispose();
        this.setState({rememberSpotBatch: rememberSpotBatch, adWrapperExist: false, videoInQueue: undefined})
        this.setState({all_contents: this.props.all_contents})
        let newSection = this.props.all_contents[index];
        if(this.props.modal.head === "search"){
          newSection = this.addParentFeedDataForSearch(newSection);
        }
        if (newSection.mazContentType && newSection.lineage) {
          newSection = this.getSectionParentFromLineage(newSection)
        }
        var type = videoType(newSection.contentUrl)
        var nextVideoData = NextVideo(this.props.all_contents, index)
        this.setState({
          type: type,
          section: newSection,
          index: index,
          lineage: newSection.lineage,
          next_section: nextVideoData[0],
          next_index: nextVideoData[1],
          next_video: true,
          video_path: ChangeRoute(newSection.identifier),
          video_title: newSection.title,
          adBreak: 0,
          video_count: this.state.video_count + 1
        })
        if(prevVideoEnded){
          this.setVideoAnalytics(true, newSection, newSection.title, ChangeRoute(newSection.identifier));
        }else{
          this.setVideoAnalytics(false, newSection, newSection.title, ChangeRoute(newSection.identifier));
        }
        if(!configuration.isAppTvodType) {
          this.props.rememberSpot(this.props.app_id, rememberSpotBatch, this.props.logged_in, this.props.user.auth_token,
            this.props.user.user_id)
        }
      } else {
        prepareMazEventData(this, "video_ended");
        this.onLinkCLick()
      }
    }
  }

  pushTvodStreams() {
    if(this.props.modal && this.props.modal.videoTrailer) return;

    const video = document.getElementById("thumb-video_html5_api");
    if(video && configuration.isAppTvodType && video.duration) {
      const progress = Math.floor((video.currentTime / video.duration) * 100) || 0;
      this.props.pushTvodStreamData({"cid": this.state.section.cid, "progress": progress})
    }
  }

  onFreeContentOver() {
    try {
      const video = document.getElementById("thumb-video");
      var spotxIframe = getSpotxIframe();
      if (isSmartTv()) {
        this.saveVideoTimeInRemeberSpot(video);
      }
      if(video) videojs(video).dispose();
      if(spotxIframe) {
        removeSpotxIframe();
      }
      this.handleMetering(false)
      this.handleTitle(false)
      if (document.getElementById("next-video")) {(document.getElementById("next-video").style.display = "none")}
      if (document.getElementById("video-inlineMenu")) {document.getElementById("video-inlineMenu").style.display = "none"}  
      this.clearSimulatorInterval();
    } catch(err) {
      console.log("error occured in free content over!");
    }
  }


  renderInlineMenu() {
    let val = this.state.section.inlineMenu || "";
    if(val && this.state.showPlayButton) {
      val = "limited";
    }
    return (
      <InlineMenu
        all_contents={this.props.all_contents}
        feeds={this.props.feeds}
        next={(index) => this.setNextVideo(index)}
        playing={this.state.index}
        inlineMenu={val}
      />
    )
  }

  render(){
    const runningOnMobile = this.props.runningOnMobile;
    if(this.props.section.identifier != this.state.section.identifier){
      window.history.replaceState("new video", {}, ChangeRoute(this.state.section.identifier, true))
    }

    const tv_style = {};
    if(isSmartTv()) {
      tv_style.display = "none";
    }
    const epgClassName = this.state.isEPG ? "epg-video" : "";

    return (
      <div className={`videoFull ${epgClassName}`} style={{background: "#000000"}} id="overlay-video">
        <div key={`${this.state.section.identifier}-${this.state.adBreak}`}>
          {/* different key for each video to dispose previous because of ads wrapper, else next click won't work*/}
          <div id="loader" className="loader"><img src={"https://s3.amazonaws.com/resources.magappzine.com/assets/core/" + environment() + "/loader.gif"}/></div>
          <video
            className={"video-js vjs-default-skin video-content-" + this.state.section.identifier} 
            id="thumb-video" 
            preload="10" 
            onEnded={(index) => this.setNextVideo(this.state.next_index, true)} // 2nd argument for post roll ad handling
            disablePictureInPicture
          /> 
          <span className="icon-hollow_arrow">
            <a id="overlay_video_back_btn" href="" onClick={(event) => this.onLinkCLick(event)} className={(runningOnMobile ? "backBtn-mobile" : "backBtn")} style={{zIndex: 100, ...tv_style}}>
              {runningOnMobile && <img src={backMobile} alt="Back"/>}
            </a>
          </span>
          {this.state.showPlayButton && <div id="video-play-extra-button" className="video-play-extra-button">
            <img alt="Play" src={playButton} onClick={() => this.startVideo()}/>
          </div>}
          {
            (!configuration.isAppTvodType && !this.state.showPlayButton && (!isAvailable(this.state.section.parent_id,this.state.section.special,this.state.section.registerWall) &&
              this.state.section.locked)) &&
              <Metering
                content_id={this.state.section.identifier}
                parent_id={this.state.section.parent_id}
                title={this.state.section.title}
                contentAccess={this.state.section.access}
                feeds={this.props.feeds}
                onFreeContentOver={() => this.onFreeContentOver()}
                playing_Ad={this.state.playing_Ad}
                showMetering={this.state.showMetering}
                content={this.state.section}
                modalHead={this.props.modal.deepLink === DEEPLINK ? DEEPLINK : this.props.modal.head}
                interstitialModalDetails={this.props.modal.interstitialProps}
                video_start={this.state.video_start}
              />
          }
          {!runningOnMobile && !this.state.playing_Ad && this.state.showTitle && this.state.video_title && <div className="prevThumbnail video-title ellipsis" >
            <p className=""> <b>{this.state.video_title}</b></p>
          </div>}
          {/* To check if it is last video do not show up next. (this.state.next_index will be undefined for last video) */}
          {!runningOnMobile && !this.state.playing_Ad && !this.state.isEPG && this.state.next_index && <div className="prevThumbnail focusable" tabIndex={getTabIndex()} id="next-video" style={{display: "none", backgroundColor: (this.props.feeds.primaryColor && this.props.feeds.primaryColor.replace('#FF','#'))}} onClick={(index) => this.setNextVideo(this.state.next_index)}>
            <p>Up Next in <span className="nextVideoSeconds"></span> Seconds</p>
            <img src={this.state.next_section && this.state.next_section.cover && httpsUrl(this.state.next_section.cover.url) && getImageUrlBaseOnSize(httpsUrl(this.state.next_section.cover.url), 384)} 
              style={{visibility:"hidden"}}
              onLoad={(e) => {e.target.style.visibility='visible'}}
            />
            <p className="prevText">{this.state.next_section && this.state.next_section.title}</p>
          </div>}
        </div>
        {!runningOnMobile && !this.state.playing_Ad && !this.state.isEPG && this.renderInlineMenu()}
        {isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX]) && document.getElementById('thumb-video_html5_api') && !this.state.playing_Ad && !this.state.showPlayButton  && this.state.afterMount && !this.state.isEpg && <PlayerIcon videoId={'thumb-video_html5_api'} ctv_focus_on={this.state.ctv_focus_on} />}
        {isSmartTv() && !checkCorrectPlatform([PLATFORM_XBOX]) && document.getElementById('thumb-video_html5_api') && !this.state.playing_Ad && !this.state.showPlayButton  && this.state.afterMount && !this.state.isEpg && <PlayerProgressBar videoId={'thumb-video_html5_api'} key={this.state.index} ctv_focus_on={this.state.ctv_focus_on}/>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  remember: state.remember.remember,
  logged_in: state.user.logged_in,
  user: state.user.user,
  ads: state.ads,
  items_purchased: state.user.items_purchased,
  subscription: state.user.subscription,
  userEngaged: state.user.userEngaged,
  modal: state.modal.modal,
  user_ip: state.user.ip,
  user_concent: state.user.userConcent,
  device_uuid: state.user.device_uuid,
  user_coords: state.user.user_coords,
  device_model: state.user.device_model,
  showSubtitle: state.feeds.showSubtitle,
  isUserOnline: state.user.isUserOnline,
  allStreamObject: state.streams.allStreamObject
})

const mapDispatchToProps = {
  rememberSpot: localRememberSpotBatch,
  closeModal: closeModal,
  openModal: openModal,
  modalProgress: modalProgress,
  updateVideoCount: updateVideoCount,
  pushTvodStreamData: pushTvodStreamData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverlayVideo));
