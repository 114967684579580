import React from 'react';
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';

import { closeModal,openModal } from '../actions/modalActions'
import { DEEPLINK } from '../constants/common';
import configuration from '../constants/config';
import { backButtonObj } from '../helperfunctions';
import { getRedirectUrlAfterGeofence } from '../helperfunctions/common';


function BackButton(props, section_id){
  return backButtonObj(props, section_id).pathLocation;
}

function ParentSection(feeds,section_id){
  var sections = feeds.sections
  var content;
  var pathLocation = section_id.split("/")
  for(var i=0; i<pathLocation.length - 1; i++){
    for(var j=0; j<sections.length; j++){
      if(sections[j].identifier === pathLocation[i]){
        content = sections[j]
        if(sections[j].contents) {
          sections = sections[j].contents;
        }
      }
    }
  }
  return content
}

class Video extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount(){
    const parentSection = ParentSection(this.props.feeds,this.props.section_id);
    const {identifier, showVideoAd, locked, access, special, displayTitle, registerWall, inlineMenu} = parentSection;
    let section = {
      ...this.props.section,
      locked: locked,
      access: configuration.isAppTvodType ? this.props.section.access : access,
      special: special,
      displayTitle: displayTitle || null,
      registerWall: registerWall,
      parent_id: identifier,
      inlineMenu: inlineMenu || ""
    }
    let all_contents = this.props.all_contents.map((item) => {
      return {
        ...item,
        locked: locked,
        access: configuration.isAppTvodType ? item.access : access,
        special: special,
        displayTitle: displayTitle || null,
        registerWall: registerWall,
        parent_id: identifier,
        inlineMenu: inlineMenu || ""
      }
    })
    if (this.props.isBlockedGeofence(parentSection)) {
      const redirectUrl = getRedirectUrlAfterGeofence(this.props.feeds);
      this.props.history.replace(redirectUrl);
    } else {
      this.props.openModal({head: identifier, type: "video", identifier: section.identifier,
        content: section, show_ad: showVideoAd, all_contents: all_contents,
        callbackParent: (route) => this.props.callbackParent(route),
        deepLink: DEEPLINK,
        pathname: "/apps/" + this.props.app_id + "/" + BackButton(this.props.feeds, this.props.section_id),
        firstpath: window.location.pathname
      })
    }

  }

  componentWillUnmount(){
    this.props.closeModal()
  }

  render(){
    return null;
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  closeModal: closeModal,
  openModal: openModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));
