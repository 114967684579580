import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { getTabIndex } from '../helperfunctions/common';
import Content from './Content';
import { SERIES_THEME } from '../constants/common';
import { addRemoveAllNavigation } from '../helperfunctions/ctvHelper';
import { openModal } from "../actions/modalActions";
import configuration from '../constants/config';
import { getThumbnailType } from '../helperfunctions';
import Trailer from './icons/Trailer';

import '../css/series.css';

const SeriesSection = (props) => {
  const [seasonIndex, setSeasonIndex] = useState(0);

  useEffect(() => {
    const focusId = addRemoveAllNavigation({ selector: '.series .focusable' });
    return () => {
      addRemoveAllNavigation({ id: focusId });
    }
  }, [])

  const { app_id, feeds, layout, section, callbackParent, pathLocation, runningOnMobile, background} = props;

  const manageClick = () => {
    const firstSeason = section.contents[0];
    if(!firstSeason || !firstSeason.contents) return;
    const firstEpisode = firstSeason.contents[0];
    if(!firstEpisode) return;

    const allContents = firstSeason.contents.map(content => {
      const newContent = {
        ...content,
        locked: firstSeason.locked,
        special: firstSeason.special,
        displayTitle: firstSeason.displayTitle,
        registerWall: firstSeason.registerWall,
        parent_id: firstSeason.identifier,
        inlineMenu: firstSeason.inlineMenu || ""
      }
      if(!configuration.isAppTvodType) {
        newContent["access"]= firstSeason.access
      }
      return newContent;
    })

    const propsObj = {
      head: firstSeason.identifier, type: "video", identifier: firstEpisode.identifier,
      content: firstEpisode, show_ad: firstSeason.showVideoAd, all_contents: allContents,
      pathname: window.location.pathname, firstpath: getThumbnailType(firstEpisode, firstSeason.identifier)
    }

    if (configuration.isAppTvodType || !firstSeason.showInterstitial) {
      props.openModal(propsObj)
    } else {
      props.openModal({
        type: 'interstitialScreen',
        modalProps: propsObj,
        thumbnailProps: {
          background: background
        }
      });
    }
  }

  const playTrailer = () => {
    const trailer = section.trailer;
    if(!trailer) return;

    const propsObj = {
      type: "video", identifier: trailer.identifier,
      content: trailer, show_ad: false, all_contents: [trailer], videoTrailer: true,
      pathname: window.location.pathname, firstpath: getThumbnailType(trailer, section.identifier)
    }
    props.openModal(propsObj)
  }

  const renderButtons = () => {
    const trailer = section.trailer;
    if(!runningOnMobile) {
      const btnStyle = feeds.primaryColor ? {backgroundColor: feeds.primaryColor.replace('#FF','#')} : {};
      return (
        <div className="item-play">
          <span className="focusable" tabIndex={getTabIndex()} onClick={() => manageClick()} style={btnStyle}>
            <span className="arrow-right"></span>
            <span>{`Watch S1E1`}</span>
          </span>
          {trailer &&<span className="focusable" tabIndex={getTabIndex()} onClick={() => playTrailer()} style={btnStyle}>
            <span>Trailer</span>
          </span>}
        </div>
      )
    }
    return (
      <div className="item-play">
        <div onClick={() => manageClick()}>
          <span className="arrow-right"></span>
          <span className="mt-10">Watch</span>
        </div>
        {trailer && <div onClick={() => playTrailer()}>
          <Trailer />
          <span className="mt-10">Trailer</span>
        </div>}
      </div>
    )
  }

  const renderDescription = () => {
    const {summary, title, background, subTitle, cover} = section;
    const imageUrl = (background && background.imageUrl) ? background.imageUrl : (cover && cover.url);
    console.log("section", section)
    return (
      <div className="description">
        <div className="image-wrapper">
          {imageUrl && <img src={imageUrl}/>}
          {!runningOnMobile && <div className="shodow"></div>}
        </div>
        <div className="details">
          <p className="title">{title}</p>
          {subTitle && <p className="meta-data">{subTitle}</p>}
          {summary && <p className="summary">{summary}</p>}
          {renderButtons()}
        </div>
      </div>
    )
  }

  const renderSeasonList = () => {
    const seasonList = section.contents || [];

    return (
      <div className="season-list">
        {seasonList.map((season, index) => {
          if(season.type !== "menu" || season.mazIDSectionType) return null; // saved, progress, history, item at season level
          const borderStyle = (index === seasonIndex && feeds.primaryColor) ? {borderColor: feeds.primaryColor.replace('#FF','#')} : {}
          return (
            <div key={season.identifier} onClick={() => setSeasonIndex(index)} className="season-wrapper">
              <span className={`season focusable ${index === seasonIndex ? "active" : ""}`} style={borderStyle} tabIndex={getTabIndex()}>{season.title}</span>
            </div>
          )
        })}
      </div>
    )
  }

  const renderEpisodes = () => {
    const seasonData = (section.contents && section.contents[seasonIndex]) || null;
    if(!seasonData || seasonData.type !== "menu") return null;

    return (
      <Content 
        background={background}
        app_id={app_id} feeds={feeds}
        content={seasonData} 
        callbackParent={(route) => callbackParent(route)} 
        layout={SERIES_THEME}
        pathLocation={pathLocation} 
        runningOnMobile={runningOnMobile}
      />
    )
  }

  return (
    <div className="content-box series" id="content-box-1">
      {renderDescription()}
      <div className="below-section">
        {renderSeasonList()}
        {renderEpisodes()}
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  openModal
}

export default connect(null, mapDispatchToProps)(SeriesSection);
