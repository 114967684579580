import React from 'react';
import { connect } from "react-redux";
import Cta from "../components/Cta";
import { JUMBO_THEME } from '../constants/common';
import configuration from '../constants/config';
import config from '../constants/config';
import { getFontStyleFromFeed, getSwiperParamsByTheme } from "../helperfunctions";
import { appendVirtualSwiperParams, checkIfEmptyMazIdFeedsInContents, getTabIndex, focusVideoItemWhichWerePlayed, isSmartTv, setLeftStyleToSlideItems, tvFocus } from '../helperfunctions/common';
import { makeContentFocusableSection, removeContentFromFocusableSection, convertSwiperParamsToSlick } from '../helperfunctions/ctvHelper';
import CustomSlider from './CustomSlider';

class JumboContent extends React.Component {
  constructor(props){
    super(props);
    this.swiper = null
    this.state = {
      currentView: '',
      virtualData: {
        slides: [],
        offset: 0
      }
    }
    this.swiperParams = null;
    this.generateSwiperParam();
  }

  componentDidMount() {
    const swiperCont = document.querySelector(`.content-${this.props.content.identifier} .swiper-container`);
    this.swiper = swiperCont && swiperCont.swiper;
    if(isSmartTv() && config.use_light_sliders) {
      makeContentFocusableSection({
        disableDefaultSection: true,
        content_identifier: this.props.content.identifier
      }).then(val => this.sn_id = val)
      .catch(err => console.log("error",err))
    }
  }
  
  componentWillUnmount() {
    if(isSmartTv() && config.use_light_sliders) {
      removeContentFromFocusableSection({
        enableDefaultSection: true,
        content_identifier: this.props.content.identifier,
        id: this.sn_id
      });
    }    
  }

  componentDidUpdate(){
    if(Object.keys(this.props.modal_progress).length > 0 && this.props.modal_progress.head === this.props.content.identifier){
      // this.swiper.slideTo(this.props.modal_progress.index)
      focusVideoItemWhichWerePlayed(this.props.content.identifier, this.props.modal_progress.item)
    }
    if (!isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)) {
      setLeftStyleToSlideItems(this.props.content.identifier, this.state.virtualData.offset);
    }
  }

  onRenderExternal = (virtualData) => {
    this.setState({
      virtualData
    }, () => {
      tvFocus(this.props.layout, this.props.content.identifier);
    })
  }

  nextSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slideNext();
  }

  prevSlide = () => {
    const _self = this;
    _self.swiper && _self.swiper.slidePrev();
  }

  generateSwiperParam = () => {
    const _self = this;
    let params = getSwiperParamsByTheme(_self, this.props.content, JUMBO_THEME);
    if (!isSmartTv()) {
      this.swiperParams = appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal)
    } else {
      if (config.enableCtvVirtualSlides) {
        this.swiperParams = {
          ...appendVirtualSwiperParams(params, this.props.contents, this.onRenderExternal, {
            addSlidesAfter: 1,
            addSlidesBefore: 1
          }),
        };
      } else {
        this.swiperParams = params;
      }
    }
  }

  render(){
    const fontStyle = getFontStyleFromFeed(this.props.feeds);
    let jumboClassName;
    // let params = getSwiperParamsByTheme(this, this.props.content, JUMBO_THEME)
    if(this.props.content.thumbOrientation === "portrait"){
      jumboClassName = "slider-box jumbo-view portrait-view content-" + this.props.content.identifier
    } else {
      jumboClassName = "slider-box jumbo-view content-" + this.props.content.identifier
    }

    let slick_params = null;
    if(isSmartTv() &&  configuration.use_light_sliders) {
      slick_params = convertSwiperParamsToSlick(this.swiperParams, this);
    }
    if(checkIfEmptyMazIdFeedsInContents(this.props.content.contents))
      return null;

    return (
      <div className={jumboClassName}>
        {this.props.content.locked && <Cta layout={"jumbo"} time={Date.now()} content={this.props.content} feeds={this.props.feeds}/>}
        {!this.props.showingSavedSection && <h2 id={`content-head-${this.props.content.identifier}`} style={fontStyle.primaryFont}>{this.props.content.feedTitle || this.props.title}</h2>}
        {
          isSmartTv() && config.enableCtvVirtualSlides && (
          <>
            <div data-sn-right="" className={`swiper-button-next t${this.props.content.identifier}-nextEl focusable`} tabIndex={getTabIndex()} onClick={this.nextSlide}></div>
            <div data-sn-left="" className={`swiper-button-prev t${this.props.content.identifier}-prevEl focusable`} tabIndex={getTabIndex()} onClick={this.prevSlide}></div>
          </>
          )
        }
        <CustomSlider
          params = { slick_params || this.swiperParams }
          content_identifier = { this.props.content.identifier }
        >
          { this.props.contents }
        </CustomSlider>
        {/* <Swiper {...this.swiperParams}>
          {
            !isSmartTv() || (isSmartTv() && config.enableCtvVirtualSlides)
            ? (this.state.virtualData.slides)
            : (this.props.contents)
          }
        </Swiper> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal_progress: state.modal.progress,
})

export default connect(mapStateToProps, null)(JumboContent);
