import React from 'react';
import Content from '../components/Content';
import Swiper from 'react-id-swiper';
import Preview from '../components/Preview';
import { setPreviewVideo, setLockedContent } from "../actions/modalActions"
import { connect } from "react-redux";
import isAvailable from '../helperfunctions/is_available';
import { getFontStyleFromFeed } from "../helperfunctions";
import configuration from '../constants/config';
import { PLATFORM_WEB, PLATFORM_WEB_PREVIEW } from '../constants/common';
import { checkCorrectPlatform, isSmartTv } from '../helperfunctions/common';
import JsSpatialNavigation from '../ctvnavigation/js-spatial-navigation';


function checkSingleMenu(contents){
  var singleMenu = true
  for(var i=0; i < contents.length; i++){
    if(contents[i].type === "menu" || contents[i].type === "fake" || contents[i].type === "live"){
      singleMenu = false;
      break;
    }
  }
  return singleMenu;
}

class FullScreenSection extends React.Component {
	constructor(props){
		super(props)
		this.swiper = null;
		this.swiperTranslate = {
			initial: null
		}
		this.state = {muted: !this.props.section.previewAudio, randomKey: 123, slideOffsetAfter: 100}
	}

	onMouseEventHandler = (event) => {
		if (!checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
			if(event === "in"){
				this.interval && clearInterval(this.interval);
				if(this.swiper){
					this.interval = setInterval(() => this.moveSwiperToInitial(), 5000)
				}
				this.mutePreviewVIdeo("out")
			}else if(event === "move"){
				clearInterval(this.interval);
				this.interval = setInterval(() => this.moveSwiperToInitial(), 5000)
			}
			else{
				clearInterval(this.interval);
			}
		}
		else {
			if(event === "in") {
				this.mutePreviewVIdeo("out")
			}
		}
	}

	moveSwiperToInitial = () => {
		if (isSmartTv()) {
			document.querySelector('#fullscreen-grid > .swiper-container.vert-container').scrollTop = 0;
		} else {
			this.swiper.setTranslate(this.swiperTranslate.initial)
		}
	}

	mutePreviewVIdeo = (event) => {
		if(event === "in"){
			this.setState({muted: true})
		}else if(event === "out"){
			this.setState({muted: !this.props.section.previewAudio})
		}
	}

	

	doOnResize = () => {
		window.onresize = () => {
			if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
			this.resizeTimeout = setTimeout(() => {
				this.setState({randomKey: Math.ceil(Math.random()*1000)})
			}, 500)
		}
	}

	handleRemoteClick = (e) => {
		let fullscreenParent = document.querySelector('#fullscreen-grid');
		if(document.getElementById('nav-bar') && document.getElementById('nav-bar').contains(document.activeElement)) {
			document.querySelector('#fullscreen-grid > .swiper-container.vert-container').scrollTop = 0;
		}
		if (e.keyCode === 38 || e.keyCode === 40) {
			if (fullscreenParent) {
				const allHorizontolRows = document.querySelectorAll(".swiper-slide.fullscreen-parent-row");
				allHorizontolRows.forEach(e => {
					if (e.contains(document.activeElement)) {
						e.classList.add('activeInside')
					} else {
						e.classList.remove('activeInside');
					}
				})
			}
		}

		if (!configuration.enableCtvVirtualSlides && (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40)) {
			if (fullscreenParent && fullscreenParent.contains(document.activeElement)) {
				const nearestContainer = document.activeElement.closest(".swiper-container");
				let currIndex;
				nearestContainer && nearestContainer.querySelectorAll(".swiper-wrapper > div").forEach((ele, index) => {
					if (ele.contains(document.activeElement)) {
						currIndex = index;
						nearestContainer.swiper && nearestContainer.swiper.slideTo(currIndex)
					}
				})
			}
		}
	}

	componentWillMount(){
		// this.doOnResize();
		var _content = {...this.props.section.contents[0]};
		if(_content.contents && _content.type === "menu" && _content.contents[0]){
			_content = {..._content.contents[0]}
		}
		_content["locked"] = this.props.section.contents[0].locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall)
		this.props.dispatch(setPreviewVideo(_content))
		this.props.dispatch(setLockedContent(_content.locked && !isAvailable(this.props.section.identifier,this.props.section.special,this.props.section.registerWall)))
	}

	componentDidMount() {
		this.swiperTranslate.initial = this.swiper.translate;
		if (checkCorrectPlatform([PLATFORM_WEB, PLATFORM_WEB_PREVIEW])) {
			this.setWindowHover();
		}
		if (isSmartTv()) {
			document.addEventListener('keyup',this.handleRemoteClick);
			JsSpatialNavigation.set('navbar', {
				leaveFor: {
					'down': '#fullscreen .focusable'
				}
			})
		}
		this.updateSwiperAfterSetTime(0);
	}

	updateSwiperAfterSetTime = (timeInms) => {
		const _self = this;
		setTimeout(function() {
			_self.swiper.update();
			_self.calculateSlideOffsetAfter();
		}, timeInms)
	}

	calculateSlideOffsetAfter = () => {
		const totalHeightOfSectionScroll = this.swiper.slidesSizesGrid.reduce((a, b) => a + b, 0);
		const offsetAfter = -this.swiperTranslate.initial + totalHeightOfSectionScroll;
		if (offsetAfter < 100) {
			this.setState({
				slideOffsetAfter: offsetAfter
			})
		}
	}

	changeSwiperPosition = (y) => {
		this.swiper.setTransition(150)
		this.swiper.setTranslate(y)
	}
	/**
	 * Peek Functionality - (https://app.asana.com/0/1199123221292070/1199113311301949) When user hover on bottom 200px, The first row of fullscreen will fully visible.
	 */
	setWindowHover = () => {
		const _self = this;
		window.addEventListener('mousemove', _self.onWindowMouseMove)
	}

	getFirstItemRowHeight = () => {
		if (document.querySelector('#first-row-fullscreen .swiper-wrapper a.thumbLink')) {
			return document.querySelector('#first-row-fullscreen .swiper-wrapper a.thumbLink').offsetHeight;
		} else if (document.querySelector('.swiper-wrapper a.thumbLink')) {
			return document.querySelector('.swiper-wrapper a.thumbLink').offsetHeight;
		}
		else {
			return 200;
		}
	}

	onWindowMouseMove = (e) => {
		const initialSwiperPos = this.swiperTranslate.initial;
		const currSwiperPos = this.swiper.translate;
		const windowHeight = window.innerHeight;
		const firstRowEleHeight = this.getFirstItemRowHeight();
		const translateYValue = initialSwiperPos - firstRowEleHeight - 30;
		const _self = this;
		if (translateYValue <= currSwiperPos) {
			if (e.clientY > windowHeight - 200) {
				_self.changeSwiperPosition(translateYValue);
			} else {
				_self.changeSwiperPosition(initialSwiperPos);
			}
		}
	}

	onDivClick = (e, isFirstRow) => {
		const _self = this;
		e.preventDefault();
		if (isSmartTv()) {
			if (isFirstRow) {
				_self.onMouseEventHandler("in");
			} else {
				_self.onMouseEventHandler("out");
				_self.mutePreviewVIdeo("in");
			}
		}
	}

	contentComponent = (content, i) => {
		if(i === 0){
			return (<span id="first-row-fullscreen" 
						className="fullscreen-parent-row"
						onMouseEnter = {() => this.onMouseEventHandler("in")} 
						onMouseLeave = {() => this.onMouseEventHandler("out")} 
						onMouseMove = {() => this.onMouseEventHandler("move")}key = {i}
						onClick={(e) => this.onDivClick(e, true)}
					>
							<Content app_id={this.props.app_id}
								feeds={this.props.feeds}
								layout={this.props.layout}
								content={content}
								key={i}
								index = {i}
								section = {this.props.section}
								pathLocation={this.props.pathLocation}
								callbackParent = {this.props.callbackParent}
								sourceClass = {"image-video-"+this.props.section.contents[0].identifier}
								hover = {true}
								background={this.props.background}
							>
							</Content>
			</span>)

		}
		else {
			return (<span key = {i} onMouseEnter = {() => this.mutePreviewVIdeo("in")} className="fullscreen-parent-row" onClick={(e) => this.onDivClick(e, false)}>
							<Content app_id={this.props.app_id}
								feeds={this.props.feeds}
								layout={this.props.layout}
								content={content}
								key={i}
								index = {i}
								section = {this.props.section}
								pathLocation={this.props.pathLocation}
								callbackParent = {this.props.callbackParent}
								sourceClass = {"image-video-"+this.props.section.contents[0].identifier}
								hover = {true}
								background={this.props.background}
							>
							</Content>
						</span>)
		}

	}

	componentWillUnmount() {
		clearInterval(this.interval);
		window.removeEventListener('mousemove', this.onWindowMouseMove)
		if(isSmartTv()) {
			JsSpatialNavigation.set('navbar', {
				leaveFor: {
					'down': '.content-box .focusable'
				}
			})
			document.removeEventListener('keyup',this.handleRemoteClick)
		}
	}

	render(){
		const fontStyle = getFontStyleFromFeed(this.props.feeds);
		const self = this;
		const params = {
			direction: "vertical",
			spaceBetween: 20,
			slidesPerView: 'auto',
			setWrapperSize: true,
			containerClass: "swiper-container vert-container",
			freeMode: true,
			on: {
				init: (swiper) => {
					self.swiper = document.querySelector('.swiper-container-vertical').swiper
				}
			},
			mousewheel: {
				invert: true,
				forceToAxis: true,
			},
			slidesOffsetBefore: window.innerHeight - 175,
			slidesOffsetAfter: self.state.slideOffsetAfter,
			breakpoints: {
				1600: {
					slidesOffsetBefore: window.innerHeight - 145,
				}
			},
		}
		let contents;
		if (checkSingleMenu(this.props.section.contents)){
			contents = [this.props.section].map(this.contentComponent)
		}else{
			contents = this.props.section.contents.map(this.contentComponent)
		}
		return (
			<div id = "fullscreen">
				<Preview muted = {
					this.state.muted}
				previewType = {this.props.section.previewType}
				section = {this.props.section}
				previewInfo={this.props.section.previewInfo}
				fontStyle = {fontStyle}
			/>
				<div id="fullscreen-grid">
					<Swiper key={this.state.slideOffsetAfter} {...params} ref={(node) => {if(node){this.swiper = node.swiper}}}>
						{contents}
					</Swiper>
				</div>
			</div>
			)
	}
}

const mapStateToProps = (state) => ({
  subscription: state.user.subscription,
})


export default connect(mapStateToProps)(FullScreenSection)
