import React, {Component} from "react";
import { connect } from 'react-redux';
import PreviewVideo from './PreviewVideo';
import PreviewImage from './PreviewImage';
import PreviewVideoFeed from './PreviewVideoFeed';
import PreviewDescription from './PreviewDescription';
import {previewContent} from './../helperfunctions';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    const { description, video_content, image_content, preview_feed } = previewContent(props);
    this.state = {
      description: description, 
      video_content: video_content, 
      image_content: image_content, 
      preview_feed: preview_feed
    }
  }

  stopTimer = (timer) => {
    if (timer) {
      clearTimeout(timer);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const _self = this;
    if (prevProps.item.identifier !== this.props.item.identifier) {
      const { description, video_content, image_content, preview_feed } = previewContent(this.props);
      this.stopTimer(this.timer);
      this.timer = setTimeout(function() {
          _self.setState({
            description,
            video_content,
            image_content,
            preview_feed
          })
        }, 700);
    }
  }

  componentWillUnmount() {
    this.stopTimer(this.timer);
  }

  render(){
    if (Object.keys(this.props.item).length === 0) return null;
    const {description, video_content, image_content, preview_feed} = this.state;
    
    var PreviewRight = null
    if(preview_feed){
      PreviewRight = (<PreviewVideoFeed key = {preview_feed.id} preview_feed = {preview_feed}/>)
    }else if(video_content){
      PreviewRight = (<PreviewVideo key = {video_content.id} video_content = {video_content}/>)
    }else if(image_content){
      PreviewRight = (<PreviewImage key = {image_content.id} image_content = {image_content}/>)
    }

    return (
      <div
        id = "previewSection"
        >
        <PreviewDescription description={description} fontStyle={this.props.fontStyle} item={this.props.item}/>
        {PreviewRight}
      </div>
    );
  }
}

function mapStateToProps(state){
  return {item: state.modal.content, locked: state.modal.locked}
}

export default connect(mapStateToProps)(Preview); 