import React from 'react';
import { hexToRgba, httpsUrl, PLATFORM_VIZIO } from '../constants/common'
import * as analytics  from '../constants/analytics';
import configuration from "../constants/config"
import { checkCorrectPlatform } from '../helperfunctions/common';


class SponsorScreen extends React.Component {

  componentWillMount(){
    let duration = this.props.sponsor.duration;
    if(checkCorrectPlatform([PLATFORM_VIZIO])) {
      duration = 2; // 2 seconds
    }
    setTimeout(function() {
      this.props.onTimeout();
    }.bind(this), duration*1000);
  }

  componentDidMount(){
    analytics.setActiveUsersData();
    analytics.setEvent('Ads', 'Sponsor Screen View', this.props.sponsor.name)
  }

  render(){
    let divClassName = configuration.is_simulator ? "simulator-sponsor" : "web-sponsor";
    if(this.props.runningOnMobile) {
      divClassName = divClassName + " web-sponsor-mobile"
    }
    if(this.props.sponsor.bgColor){
      var colorBg = hexToRgba("#"+this.props.sponsor.bgColor.substring(3), this.props.sponsor.bgColor.substring(1,3));
      return (
        <div className={divClassName}>
          <div className="bckgrnds">
            <div className="colorBg" style={{"background": colorBg}}></div>
          </div>

          <div className="sponsorContent">
            {this.props.sponsor.headerText && <p>Brought to you by</p>}
            <img src={httpsUrl(this.props.sponsor.imageUrl)} />
          </div>
        </div>
      )
    } else if(this.props.sponsor.bgGradient){
      var tempGradTop = hexToRgba("#"+this.props.sponsor.bgGradient.topColor.substring(3), this.props.sponsor.bgGradient.topColor.substring(1,3));
      var tempGradBot = hexToRgba("#"+this.props.sponsor.bgGradient.bottomColor.substring(3), this.props.sponsor.bgGradient.bottomColor.substring(1,3));
      return (
        <div className={divClassName}>
          <div className="bckgrnds">
            <div className="gradientBg" style={{"background": "-webkit-gradient(linear,left top,left bottom,color-stop(0, " + tempGradTop + "),color-stop(1, " + tempGradBot + "))", "background": "-o-linear-gradient(bottom, " + tempGradTop + " 0%, " + tempGradBot + " 100%)", "background": "-moz-linear-gradient(bottom, " + tempGradBot + " 0%, " + tempGradBot + " 100%)", "background": "-webkit-linear-gradient(bottom, " + tempGradTop + " 0%, " + tempGradBot + " 100%)", "background":"-ms-linear-gradient(bottom, " + tempGradTop + " 0%, " + tempGradBot + " 100%)", "background": "linear-gradient(to bottom, " + tempGradTop + " 0%, " + tempGradBot + " 100%)"}}></div>
          </div>

          <div className="sponsorContent">
            {this.props.sponsor.headerText && <p>Brought to you by</p>}
            <img src={httpsUrl(this.props.sponsor.imageUrl)} />
          </div>
        </div>
      )
    } else {
      return (
        <div className={divClassName}>
          <div className="bckgrnds">
            <div className="imageBg">
              <img src={httpsUrl(this.props.sponsor.bgImageUrl)} />
            </div>
          </div>

          <div className="sponsorContent">
            {this.props.sponsor.headerText && <p>Brought to you by</p>}
            <img src={httpsUrl(this.props.sponsor.imageUrl)} />
          </div>
        </div>
      )
    }
  }
}

export default SponsorScreen;
