import * as actionTypes from '../constants/actionTypes'
import configuration from '../constants/config';

export default function reducer(
    state={
        items: {
            content: [],
        }, user_id: 0, success: false, loader: true,
        history: {contents: []},
        progress: {contents: []},
        haveSavedMazIDFeed: false
    }, action) {

    switch (action.type) {
        case "RECEIVE_SAVED_ITEMS": {
            const items = (action.page === 1) ? action.payload : [...state.items.content].concat(action.payload)
            return {
                ...state, items: {content: items}, user_id: action.user_id, success: action.success, loader: action.loader,
            }
        }
        case "RECEIVE_DELETE_ITEMS": {
            var index = 0;
            for(var i=0; i < state.items.content.length; i++){
                if((configuration.isAppTvodType && state.items.content[i].cid === action.payload.cid) || (!configuration.isAppTvodType && state.items.content[i].identifier === action.payload.identifier)){
                    index = i;
                    break;
                }
            }
            return {
                ...state, items: {content: state.items.content.slice(0, index).concat(state.items.content.slice(index+1))}, user_id: action.user_id, success: action.success,
            }
        }
        case "REPLACE_SAVED_ITEMS": {
            var index = null;
            let content = [...state.items.content];
            for(var i=0; i < content.length; i++){
                if(content[i].cid === action.payload.cid){
                    index = i;
                    break;
                }
            }
            if(index != null) {
                let newContent = {...content[index], ...action.payload}
                content[index] = newContent;
            }
            return {
                ...state, items: {content: content},
            }
        }
        case "RECEIVE_All_SAVED_ITEMS": {
            return {
                ...state, items: action.payload, user_id: action.user_id, success: action.success, loader: action.loader,
            }
        }
        case "CLEAR_SAVED_ITEMS": {
            return {
                ...state, items: action.payload, user_id: action.user_id, success: action.success, loader: action.loader,
                history: {contents: []},
                progress: {contents: []}
            }
        }
        case actionTypes.GET_USER_HISTORY_PROGRESS_RESOLVE: {
            return {
                ...state,
                progress: {
                    contents: action.payload.content
                }
            }
        }
        case actionTypes.GET_USER_HISTORY_RESOLVE: {
            return {
                ...state,
                history: {
                    contents: action.payload.content
                }
            }
        }
        case "SAVED_ITEMS_FAILED": {
            return {...state, loader: action.loader, success: false}
        }
        case actionTypes.HAVE_MAZ_ID_SAVE_FEED: {
            return {
                ...state, haveSavedMazIDFeed: true
            }
        }
    }

    return state
}