import { checkCorrectPlatform } from "../helperfunctions/common";
import configuration from "./config";

export function hexToRgba(hex, opacity) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  if (opacity == undefined) {
    opacity = 1;
  }
  else {
    if (opacity == "FF") {
      opacity = 1;
    }
    else if (opacity == "BF") {
      opacity = 0.7;
    }
    else if (opacity == "30") {
      opacity = 0.18;
    }
  }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? "rgba(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + "," + opacity + ")" : null;
}

export function getSaveFeedSectionIdentifier(feeds) {
  let saveSectionIdentifier = '';
  feeds.sections.forEach(section => {
    if (section.type == 'saved') {
      saveSectionIdentifier = section.identifier;
    }
  })
  return saveSectionIdentifier;
}

export function deepLinkUrl(deeplink, app_id, feeds){
  var deeplinkSplit = deeplink.split("/")
  if(deeplinkSplit[2] === "menu"){
    deeplinkSplit = deeplinkSplit.slice(3,deeplinkSplit.length)
    deeplinkSplit = deepLinkState(deeplinkSplit, feeds, "menu")[0]
    if(deeplinkSplit.length > 0) {
      return [("/apps/" + app_id + "/" + deeplinkSplit.join("/"))]
    } else {
      return [("/apps/" + app_id)]
    }
  } else if(deeplinkSplit[2] === "save") {
    return [("/apps/" + app_id + `/${getSaveFeedSectionIdentifier(feeds)}`)]
  } else if(deeplinkSplit[2] === "sub") {
    return [("/apps/" + app_id + "/subscription")]
  } else if(deeplinkSplit[2] === "settings") {
    return [("/apps/" + app_id + "/settings")]
  } else if(deeplinkSplit[2] === "register") {
    return [("/apps/" + app_id + "/queue")]
  } else {
    deeplinkSplit = deeplinkSplit.slice(3, deeplinkSplit.length)
    var lastElements = deeplinkSplit[deeplinkSplit.length - 1]
    lastElements = lastElements.split("?item=")
    deeplinkSplit[deeplinkSplit.length - 1] = lastElements[0]
    deeplinkSplit.push(lastElements[1])
    var newState = deepLinkState(deeplinkSplit, feeds, "item")
    if (newState[1]) {
      deeplinkSplit = newState[0]
    } else if (newState[2]){
      deeplinkSplit.splice(deeplinkSplit.length - 1)
    }
    if(deeplinkSplit.length > 0){
      return [("/apps/" + app_id + "/" + deeplinkSplit.join("/")), newState[2]]
    } else {
      return [("/apps/" + app_id)]
    }
  }
}

export function deepLinkState(deepLinkArray, feeds, type){
  if(deepLinkArray.length < 2){
    return [deepLinkArray]
  } else {
    var locked = false
    var newDeepLinkArray = []
    var sections = feeds.sections
    var contentDictionary = []
    var feeds = feeds
    var foundLast = false
    var itemFound = false
    var missingItem = false
    for(var i=0; i<deepLinkArray.length; i++){
      for(var j=0; j<sections.length; j++){
        if(deepLinkArray[i] === sections[j].identifier){
          itemFound = true
          feeds = sections[j]
          contentDictionary.push(feeds)
          sections = feeds.contents
          if( type !== "menu" && deepLinkArray[i+1] === deepLinkArray[deepLinkArray.length - 1] && feeds.regularLayout != "detail"){
            newDeepLinkArray.push(deepLinkArray[i])
            foundLast = true
            break;
          // } else if (type === "menu" && feeds.label === "feed" && feeds.regularLayout != "detail") {
            //Only ignore last deeplink for menu if its a feed ( it won't be always a feed)
            //Bug: https://github.com/MagAppZine/MagAppZine-MagControl/issues/7717
            // foundLast = true;
            // break;
          // Commenting all of it for https://github.com/MagAppZine/MagAppZine-MagControl/issues/8758
          } else {
            newDeepLinkArray.push(deepLinkArray[i])
          }
          break;
        }
      }
      if(itemFound){
        itemFound = false
      } else{
        missingItem = true
        break
      }
      if(foundLast)
        break
    }
  }
  if(type === "item" && contentDictionary[contentDictionary.length - 2] && contentDictionary[contentDictionary.length - 2].locked){
    locked = true
  }
  return [newDeepLinkArray, missingItem, locked]
}

export function getFetchIdFromUrl(url) {
  if(!url) return null;
  var tempId = url.split("/");
  tempId = tempId[tempId.length - 2];
  return tempId;
}

export function correctPath(feeds, lineage, identifier){
  var lineageArray = lineage.split("/")
  if(lineageArray.length < 2){
    return [lineage, identifier].join("/")
  } else {
    var sections = feeds.sections
    var newLineage = []
    for(var i=0; i<lineageArray.length; i++){
      for(var j=0; j<sections.length; j++){
        if(sections[j].identifier === lineageArray[i]){
          newLineage.push(lineageArray[i])
          var flattenId = getFetchIdFromUrl(sections[j].contentUrl)
          if(flattenId === lineageArray[i+1]){
            i++
            break;
          }
          sections = sections[j].contents
          break;
        }
      }
    }
    newLineage.push(identifier)
    return newLineage.join("/")
  }
}

export function getSpotxIframe(){
  var spotxIframe = false;
  var iframes = document.getElementsByTagName('iframe')
  for (var i=iframes.length;i--;){
    if(iframes[i].id.indexOf("spotx") >= 0){
      spotxIframe = true
    }
  }
  return spotxIframe;
}

export function getSpotXId(ads) {
  if(configuration.is_simulator && checkCorrectPlatform([PLATFORM_HTML_SIMULATOR])) {
    return "85394";
  } else {
    return ads.spotxid;
  }
}

export function removeSpotxIframe(){
  var iframes = document.getElementsByTagName('iframe')
  for (var i=iframes.length;i--;){
    if(iframes[i].id.indexOf("spotx") >= 0){
      iframes[i].parentNode.removeChild(iframes[i])
    }
  }
  document.getElementsByClassName('spotx-fullscreen-parent-element') && document.getElementsByClassName('spotx-fullscreen-parent-element')[0] &&
  (document.getElementsByClassName('spotx-fullscreen-parent-element')[0].classList.remove('spotx-fullscreen-parent-element'))
}

export function httpsUrl(url){
  var newUrl = url
  // if(url.startsWith("http://resources.mazdigital.com")){
  //   newUrl = url.replace("http://resources.mazdigital.com", "https://s3.amazonaws.com/resources.magappzine.com")
  // } else {
  //   if(url.startsWith("http:")){
  //     newUrl = url.replace("http:", "https:")
  //   }
  // }
  return newUrl;
}

export function getMeteringTypes() {
  return {
    TIME: "feed_time",
    VIEWS: "app_views"
  }
}

export function getEmptyFeedMessages(feedType) {
  const msg = {
    history: "Your viewed items will appear here.",
    progress: "Your in-progress items will appear here.",
    saved: "Your saved items will appear here."
  }
  return msg[feedType];
}

export const DEFAULT_LOGIN_UI_METADATA = {
  firstSave: {
    fb: false,
    em: true,
    strings: {
      header: "REGISTER FOR A FREE ACCOUNT TO SAVE AND SYNC ACROSS DEVICES",
      register: "CREATE ACCOUNT",
      login: "LOG IN",
      subscriberHeader: "EXISTING SUBSCRIBERS",
      restore: "RESTORE PURCHASES",
      websiteButton: "Create Account with Website",
      emailButton: "Create Account With Email",
    },
    authType: "OnScreen",
  },
  registrationWall: {
    fb: false,
    em: true,
    strings: {
      header: "REGISTER FOR A FREE ACCOUNT TO UNLOCK ALL ",
      register: "CREATE ACCOUNT",
      login: "LOG IN",
      subscriberHeader: "EXISTING SUBSCRIBERS",
      restore: "RESTORE PURCHASES",
      websiteButton: "Create Account with Website",
      emailButton: "Create Account With Email",
    },
    authType: "OnScreen",
  },
  tvodLogin: {
    fb: false,
    em: true,
    strings: {
      header: "REGISTER FOR A FREE ACCOUNT TO UNLOCK ALL ",
      register: "CREATE ACCOUNT",
      login: "LOG IN",
      subscriberHeader: "EXISTING SUBSCRIBERS",
      restore: "RESTORE PURCHASES",
      websiteButton: "Create Account with Website",
      emailButton: "Create Account With Email",
    },
    authType: "OnScreen",
  }
}

export const DEEPLINK = "DEEPLINK";

export const INLINE_MENU_IDLE_TIMEOUT = 8000; //8s
export const GRID_THEME = 'GRID_THEME'
export const DETAIL_THEME = 'DETAIL_THEME'
export const JUMBO_THEME = 'JUMBO_THEME'
export const VIDEO_GRID_THEME_THEME = 'VIDEO_GRID_THEME_THEME';
export const EPG_THEME = 'epg';
export const SERIES_THEME = 'series';
export const SPOTLIGHT_THEME = 'SPOTLIGHT_THEME'
export const FULLSCREEN_THEME = 'FULLSCREEN_THEME'
export const INLINE_MENU = 'INLINE_MENU'
export const AUTH_WEBSITE = 'website'
export const AUTH_ON_SCREEN = 'OnScreen'
export const VIDEO_ERROR = {
  NON_SAFARI_M3U8_MSG: "This browser is unable to play video, please use the safari browser.",
  MSG: "Video fails to play."
}
export const PLATFORM_WEB = "web_as_output";
export const PLATFORM_WEB_PREVIEW = "preview";
export const UNLOCK_NOTE_SIMULATOR = "Subscriptions unlock any content in the app during the subscription period.";
export const UNLOCK_NOTE_WEB = "Subscriptions unlock any paid content during the subscription period.";
export const PLATFORM_HTML_SIMULATOR = "html5";
export const PLATFORM_SAMSUNG = "samsung_tv";
export const PLATFORM_LG = "lg_tv";
export const PLATFORM_VIZIO = "vizio_tv";
export const PLATFORM_XBOX = "xbox";

/**
 * Interval time in miliseconds
 */
export const FEED_POLL_INTERVAL = {
  production: 15*60*1000,
  staging: 5*60*1000
}

export const SHOW_INTERSTITIAL_AFTER_BACK_LOGIN_SAVE = false;
export const IS_INTERSTITIAL_FOR_IMAGE = false;
export const GEOFENCE_MSG = "Allow this app to access IP Address to fetch device’s location?";
export const DEFAULT_GEO_LOCALTION = "US";


/**
 * Reporting Interval time in miliseconds
 */
export const MAZ_REPORTING_INTERVAL = 5*60;
export const SUBTITLE_OPTION = 'showSubtitle';
export const PLAYER_ICON = {
  rewind: 'rewind',
  forward: 'forward',
  play: 'play',
  pause: 'pause'
}

export const API_STATE = {
  loading: 'loading',
  resolved: 'resolved',
  rejected: 'rejected'
}
